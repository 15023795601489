import { NgModule, Injectable, Input, EventEmitter, Output } from '@angular/core';
import { WebsocketService, HttpService } from '../services';
import { Observable, BehaviorSubject } from 'rxjs';
import { VirtualMachine, InfMenuItem, InfMenuType, InfrastructureMenuItemNavigationData, RouteNames, Host, Cluster } from '../classes';
import { map, retry } from 'rxjs/operators';
import { Repository } from '.';


export class RepositoryVm{   
  public modifiedObserver: Observable<VirtualMachine[]>;
  public deletedObserver: Observable<VirtualMachine[]>;
  private dataSubject: BehaviorSubject<VirtualMachine> = new BehaviorSubject<VirtualMachine>(new VirtualMachine());
  private isloaded: boolean = false;
  private isloading: boolean = false;
  constructor(private http: HttpService, private id: string, private hosts: Repository<Host>, private clusters: Repository<Cluster>) {
    let that = this;
    WebsocketService.Instance().subscribe(ws => {
      that.modifiedObserver = ws.on<VirtualMachine[]>('VirtualMachine.Modified');
      that.deletedObserver = ws.on<VirtualMachine[]>('VirtualMachine.Deleted');
      that.modifiedObserver.subscribe(data => {
        for (var i = 0; i < data.length; i++) {
          let item = data[i];
          if (item.Id == that.id) {
            that.dataSubject.next(item);
          }
        }
      });
    });
  }

  private LoadData() {
    if (!this.isloading) {
      this.isloading = true;
      this.http.get<VirtualMachine>('HyperV/Vm/' + this.id, null).subscribe(vm => { this.isloading = false; this.isloaded = true; this.dataSubject.next(vm); });
    }
  }
  public GetDataSource() {
    let ds = this.dataSubject.asObservable();
    if (!this.isloaded)
      this.LoadData();
    return ds;
  }
  GetMenuData(link: string, parentLink: string) {
    let result = new Promise<InfrastructureMenuItemNavigationData>((resolve, reject) => {
      this.dataSubject.subscribe(vm => {
        if (this.isloaded) {
          let ctx = new InfrastructureMenuItemNavigationData();
          ctx.data = this.dataSubject.pipe(map(v => {
            let res = new Array<InfMenuItem>();
            res[0] = InfMenuItem.NewItem(InfMenuType.vm, v.Name, v.Id, null, link + vm.Id);
            return res;
          }));;
          if (vm.Parent_Id) {
            ctx.up = parentLink + vm.Parent_Id;
            this.hosts.GetById(vm.Parent_Id).then(h => {
              ctx.name = h.Name;
              resolve(ctx);
            }).catch(error => { resolve(ctx);});
          }
          else {
            resolve(ctx);
          }
        }
      });
    });

    if (!this.isloaded)
      this.LoadData();
    return result;
  }
  GetBreadcrumbData(prefix, mainAddress = '', suffix = '', clusterPrefixIsOther = false) {
    let result = new Promise<any>((resolve, reject) => {
      this.dataSubject.subscribe(vm => {
        let breadcrumbs = [
          {
            name: 'Infrastructure',
            address: mainAddress
          },
        ];
        if (this.isloaded) {
          const vmBreadcrumb = {
            name: vm.Name,
            address: `${prefix}/${RouteNames.VirtualMachines}/${vm.Id}/${suffix}`
          }; 
          if (vm.Parent_Id) {
            this.hosts.GetById(vm.Parent_Id).then(host => {
              const hostBreadcrumb = {
                name: host.Name,
                address: `${prefix}/${RouteNames.Host}/${host.Id}/${suffix}`
              };
              if (host.Parent_Id) {
                this.clusters.GetById(host.Parent_Id).then(cluster => {
                  breadcrumbs.push({
                    name: cluster.Name,
                    address: `${clusterPrefixIsOther ? prefix : mainAddress}/${RouteNames.Cluster}/${cluster.Id}/${suffix}`
                  }, hostBreadcrumb, vmBreadcrumb);
                  resolve(breadcrumbs);
                });
              } else {
                breadcrumbs.push(hostBreadcrumb, vmBreadcrumb);
                resolve(breadcrumbs);
              }
            }).catch(error => { resolve(breadcrumbs); });
          } else {
            breadcrumbs.push(vmBreadcrumb);
            resolve(breadcrumbs);
          }
        }
      });
    });

    if (!this.isloaded)
      this.LoadData();
    return result;
  }
} 
