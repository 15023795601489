import { Component,  OnInit,   ElementRef, Input, ChangeDetectorRef } from '@angular/core';
import { PageMenuItem } from '../../CommonComponents';
import { HttpService, DataStore, MenuService } from '../../../../services';
@Component({
  selector: 'pagemenu',
  templateUrl: './PageMenu.component.html'
})

export class PageMenu  {
  
  constructor(private MenuService: MenuService, private HttpService: HttpService, private el: ElementRef, private changeDetectorRef: ChangeDetectorRef) {
    
  }
  isSecondaryMenuOpen() {
    return this.MenuService.isSecondaryMenuOpen();
  }

  @Input() breadcrumbs: Array<any> = [];
  _buttons: PageMenuItem[];

  @Input()
  get buttons(): PageMenuItem[] {
    return this._buttons;
  }
  set buttons(val: PageMenuItem[]) {
    this._buttons = val;
  }
  _items: PageMenuItem[][];
  @Input()
  get items(): PageMenuItem[][] {
    return this._items;
  }
  set items(val: PageMenuItem[][]) {
    this._items = val;
  
  }
  get itemsvisible() {
    return this._items != null && this._items.length > 0;
  }
  public menuitems = new Array<Array<PageMenuItem>>();
 
}
