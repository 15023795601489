import { Component,  OnInit, ElementRef, Input, EventEmitter, Output } from '@angular/core';

import { Observable, BehaviorSubject} from 'rxjs';
import { Helper } from '../../../../classes';
import { ValidatorFn, AsyncValidatorFn, FormGroup, AbstractControl, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';



@Component({
  selector: 'v-input',
  templateUrl: './VInput.component.html'
})

export class VInput{
  @Output()
  public valueChange = new EventEmitter<string>();
  @Input()
  set value(val) {
    if (this._value == val) return;
    this._value = val;
    if (this.model != null && this.model.FormControl.value != val) {
      this.model.FormControl.setValue(val);
    }
    this.valueChange.emit(this._value);
  }
  get value() {
    return this._value;
  }
  private _value: string;

  @Input()
  set model(val) {
    if (val == null) return;
    this._model = val;
    let that = this;
    let value = this.value;
    this._model.FormControl.valueChanges.subscribe(v => {
      that.value = v;
    });
    if (Helper.NotEmpty(value))
      this.model.FormControl.setValue(value);
  }
  get model() {
    return this._model;
  }
  private _model = VInputModel.Defualt();

  public get Label() {
    return  this.model.Label;
  }
  public get errors() {
    return this.model.errors;
  }
 
  public Validate() {
    this.model.Validate();
  }
  public hasError(key) {
    return this.model.FormControl.hasError(key);
  }
  public get Matcher() {
    return this.model.Matcher;
  }
  public get isdefault() {
    return this.model.isdefault;
  }
  public get FormControl() {
    return this.model.FormControl;
  }
}
export class NeedValidateMatcher implements ErrorStateMatcher {
  constructor(private ctx: VInputModel) { }
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if (this.ctx.needvalidate()) {
      const isSubmitted = false;//form && form.submitted;control.dirty ||
      let res = !!(control && control.invalid && (control.touched || isSubmitted));
      return res;
    }
    else {
      return false;
    }
  }
}
export interface VInputiIterface {
  
  errors?: { key: string, value }[];
  Label?: string;
  Validators?: ValidatorFn[];
  AsyncValidators?: AsyncValidatorFn[];
  needvalidate?: () => boolean;
}
export class VInputModel {
 
  errors?: { key: string, value }[]=[];
  Label?: string='';
  Validators?: ValidatorFn[]=[];
  AsyncValidators?: AsyncValidatorFn[] = [];
  FormControl: FormControl;
  public needvalidate: () => boolean;
  inputtype: string='text';
  get novalidate() { return this.errors.length==0};
  Matcher: NeedValidateMatcher;
  isdefault = false;
  constructor(item: VInputiIterface) {
    let that = this;
    this.FormControl= new FormControl('', []);
    Object.keys(item).forEach(function (key) {
      if (item[key] != null)
        that[key] = item[key];
    });
    if (item.Label == '')
      this.Label = null;
    this.Validators.forEach(v => { that.FormControl.setValidators(v); });
    this.AsyncValidators.forEach(av => { that.FormControl.setAsyncValidators(av); });
    if (item.needvalidate == null) {
      this.needvalidate = () => this.errors.length > 0;
    }
    else {
      this.needvalidate = () => item.needvalidate();
    }
    this.Matcher = new NeedValidateMatcher(this);
  }

  public Validate() {
    if (this.needvalidate()) {
      this.FormControl.markAsTouched();
      return this.FormControl.valid;
    }
    else
      return true;
  }

  public Untouched() {
    this.FormControl.markAsUntouched();
  }
  public static Defualt() {
    let res = new VInputModel({
      Label: 'Name'
    });
    res.isdefault = true;
    return res;
  }
  public static RequiredGModel(Label: string, error = 'Name is required') {
    let res = new VInputModel({
      Label: Label,
      errors: [{ key: 'required', value: error }],
      Validators: [Validators.required]
    });
    return res;
  }
}
