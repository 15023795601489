import { Component,  OnInit, ElementRef, Input, EventEmitter, Output } from '@angular/core';

import { Observable, BehaviorSubject} from 'rxjs';
import { Helper, fnode } from '../../../../classes';

@Component({
  selector: 'dynamictree-node',
  templateUrl: './DynamicTreeNode.component.html'
})

export class DynamicTreeNodeComponent{

  @Input()
  get data() {
    return this._data;
  }
  set data(val) {
    this._data = val;
  }
  private _data: fnode = new fnode;
  public isloading = false;
  public openclose() {
    if (!this.data.hasChildren) return;
    this.data.isopen = !this.data.isopen;
    if (!this.data.dataloaded)
      this.loadchildren();
   
  }
  public loadchildren() {
  this.isloading = true;
  this.data.root.getchildren(this.data).subscribe(nodes => {
    this.data.children = nodes;
    this.isloading = false;
    this.data.dataloaded = true;
  });
  }
  public getpadding(node: fnode) {
    return node.lvl * 24 + 'px';
  }
}


