<div class="gm-searchpanel" [ngClass]="!menuvisible && (  selectedrows==null || selectedrows.length==0)? '':'hide'">
  <ng-content></ng-content>
</div>
<div class="gm-actionspanel" [ngClass]="menuvisible || (selectedrows && selectedrows.length>0)? '':'hide'">
  <div class="gm-actionswrapper" [ngClass]="{'full-width' : !visibleclearpanel}">
    <div class="gm-actionslist">
      <p *ngFor="let item of menuitems;" class="gm-actionitem menu-icon {{item.class}}"
         [ngClass]="{'hide': !item.Visible(), 'disabled-with-opacity': item.Enable && !item.Enable()}"
         (click)="item.click()">
        {{item.name}}
      </p>
    </div>
    <dropdown-menu *ngIf="!threepointhidden" [items]="_items" [btype]="'threepoint'" style="position: absolute; right: 0;"></dropdown-menu>
  </div>
  <div class="gm-selectedpanel" *ngIf="visibleclearpanel">
    <p>{{selectedrows.length}} items selected</p>
    <span (click)="clear()"></span>
  </div>
</div>
