import { Observable } from "rxjs";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
export class InfMenuType {
  static host: string = 'menuitem-host';
  static cluster: string = 'menuitem-cluster';
  static vm: string = 'menuitem-vm';
  static vs: string = 'menuitem-vs';
  static ds: string = 'menuitem-ds';
}
export enum ReplicationWizardType { Simple, Extended, Reverse }
export class InfMenuItem {
  type: string;
  name: string;
  id: string;
  parentId: string;
  link: string;
  public static NewItem(type: string, name: string, id: string, parentId: string, link: string) {
    let res = new InfMenuItem();
    res.type = type;
    res.name = name;
    res.id = id;
    res.parentId = parentId;
    res.link = link;
    return res;
  }
}
export class InfrastructureMenuItemNavigationData {
  up: string;
  name: string;
  data: Observable<InfMenuItem[]>;
}

export  enum ComponentState {
  Visible = "Visible",
  Hide = "Hide",
  Collapsed = "Collapsed"
}

export class MenuSettings {
  left = ComponentState.Visible;
  rightVisible = true;
  rootClass: string = '';
  public getMenuState() {
    let res = '';
    if (this.left == ComponentState.Collapsed)
      res = 'left-menu-collapsedperm ';
    if (this.left == ComponentState.Hide)
      res = 'left-menu-hide ';
    res = res + (this.rightVisible ? '' :'right-menu-hide');
    return res;
  }
}

export class DataGridSettings {
  public headerHeight = 48;
  public rowHeight = 48;
  public isLoading: boolean = false;
  public clearselected: boolean = false;
  public ColumnMode = ColumnMode;
  public SelectionType = SelectionType;
}
export class suminfo {
  public Name: string;
  public Value: string;
};
export class chartView {
  name: string;
  periodIndices: Array<any>;
  prefixes: Array<string>;
  postfix?: string;
  labels: Array<any>;
  threshold: string
}
