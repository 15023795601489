export class EnumDecoder {
  Code: number;
  Name: string;
  public static Inst(code: number, name: string) {
    let res = new EnumDecoder();
    res.Code = code;
    res.Name = name;
    return res;
  }
}


export class Job {
  Id: string;
  Name: string;
  Started: Date;
  Finished: Date;
  LastUpdated: Date;
  Progress: number;
  Status: string;
  CustomData: string;
  JobStatus: number;
  UserId: string;
  UserName: string;
  CanCancel: boolean;
  Plugin: string;
  ContextObjectId: string;
  OperationId: string;
  IsFinished: boolean;

  static jobStatusList: Array<EnumDecoder> = null;

  public static GetJobStatusList() {
    if (Job.jobStatusList == null) {
      let res = new Array<EnumDecoder>();
      res[0] = EnumDecoder.Inst(0, 'Initializing');
      res[1] = EnumDecoder.Inst(1, 'Started');
      res[2] = EnumDecoder.Inst(2, 'Running');
      res[3] = EnumDecoder.Inst(3, 'Completed');
      res[4] = EnumDecoder.Inst(4, 'Failed');
      res[5] = EnumDecoder.Inst(5, 'Canceled');
      res[6] = EnumDecoder.Inst(6, 'Warning');
      Job.jobStatusList = res;
    }
    return Job.jobStatusList;
  }

  public static GetJobStatus(code: number) {
    let list = Job.GetJobStatusList();
    if (code < list.length)
      return list[code].Name;
    else
      return '';
  }
}
export enum Operation {
  Unknown = "Unknown",
  Start = "Start",
  TurnOff = "TurnOff",
  Shutdown = "Shutdown",
  Save = "Save",
  Pause = "Pause",
  Resume = "Resume",
  Reset = "Reset",
  Checkpoint = "Checkpoint",
  Remove = "Remove",
  RemoveSavedState = "RemoveSavedState",
  Connect = "Connect",
  Read = "Read",
  Rename = "Rename",
  AttachIso = "AttachIso",
  Export = "Export",
  Move = "Move",
  AddToCluster = "AddToCluster",
  RemoveFromCluster = "RemoveFromCluster",
  Clone = "Clone",
  Backup = "Backup",
  Replicate = "Replicate",
  Template = "Template",
  EditSettings = "EditSettings"
}



