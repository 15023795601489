import { Component,  OnInit, ElementRef, Input, EventEmitter, Output } from '@angular/core';

import { Observable, BehaviorSubject} from 'rxjs';
import { Helper } from '../../../../classes';
import { ValidatorFn, AsyncValidatorFn, FormGroup, AbstractControl, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { VInputModel } from '..';



@Component({
  selector: 'v-datetime-picker',
  templateUrl: './VDateTimePicker.html'
})

export class VDateTimePicker{
  @Output()
  public valueChange = new EventEmitter<any>();
  @Input()
  set value(val) {
    if (this._value == val) return;
    this._value = val;
    if (this.FormControl.value != val) {
      this.FormControl.setValue(val);
    }
    this.valueChange.emit(this._value);
  }
  get value() {
    return this._value;
  }
  private _value: any;

  @Input()
  set model(val) {
    if (val == null) return;
    this._model = val;
    let that = this;
    this._model.FormControl.valueChanges.subscribe(v => {
      that.value = v;
    });
    let value = this.value;
    if (Helper.NotEmpty(value))
      this.model.FormControl.setValue(value);
  }
  get model() {
    return this._model;
  }
  public _model : VInputModel;
  @Input()
  set settings(val) {
    if (val == null) return;
    this.dtsettings = val;
  }
  get settings() {
    return this.dtsettings;
  }
  public dtsettings = new DateTimePickerSettings();

  public get Label() {
    return  this.model.Label;
  }
  public get errors() {
    return this.model.errors;
  }
 
  public Validate() {
    this.model.Validate();
  }
  public hasError(key) {
    return this.model.FormControl.hasError(key);
  }
  public get Matcher() {
    return this.model.Matcher;
  }
  public get isdefault() {
    return this.model.isdefault;
  }
  public get FormControl() {
    return this.model.FormControl;
  }
  constructor() {
    this.model = VInputModel.Defualt();
  }
}
//[color] = "dtsettings.color"
export class DateTimePickerSettings { 
  public minDate: Date = new Date(2000, 1);
  public maxDate: Date = new Date(2050, 1);
  public disabled = false;
  public showSeconds = true;
  public disableMinute = false;
  public hideTime = false;
  public enableMeridian = true;
  public touchUi = true;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public showSpinners=false;
  constructor(settings?: IDateTimePickerSettings) {
    if (settings != null) {
      if (settings.minDate != null) {
        this.minDate = settings.minDate;
      }
      if (settings.maxDate != null) {
        this.maxDate = settings.maxDate;
      }
      if (settings.disabled != null) {
        this.disabled = settings.disabled;
      }
      if (settings.showSeconds != null) {
        this.showSeconds = settings.showSeconds;
      }
      if (settings.disableMinute != null) {
        this.disableMinute = settings.disableMinute;
      }
      if (settings.hideTime != null) {
        this.hideTime = settings.hideTime;
      }
      if (settings.enableMeridian != null) {
        this.enableMeridian = settings.enableMeridian;
      }
      if (settings.touchUi != null) {
        this.touchUi = settings.touchUi;
      }
      if (settings.stepHour != null) {
        this.stepHour = settings.stepHour;
      }
      if (settings.stepHour != null) {
        this.stepHour = settings.stepHour;
      }
      if (settings.stepMinute != null) {
        this.stepMinute = settings.stepMinute;
      }
      if (settings.stepSecond != null) {
        this.stepSecond = settings.stepSecond;
      }
      if (settings.showSpinners != null) {
        this.showSpinners = settings.showSpinners;
      }
    }
  }
}
export interface IDateTimePickerSettings {
   minDate?: Date;
   maxDate?: Date;
   disabled?: boolean;
   showSeconds?: boolean;
   disableMinute?: boolean;
   hideTime?: boolean;
   enableMeridian?: boolean;
   touchUi?: boolean;
   stepHour?: number;
  stepMinute?: number;
  stepSecond?: number;
   showSpinners?: boolean;
}
