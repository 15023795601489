import { Component, Injectable, Input, Output, OnChanges, OnInit, EventEmitter,  ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit, ElementRef  } from '@angular/core';
import { HttpService, DataStore, MenuService } from '../../../services';
import { VirtualMachine, Cluster, Host, EnumDecoder, Job, Helper, MenuSettings } from '../../../classes';
import { IQueryParam, JobQueryParam,DynamicRepository } from '../../../repository';
import { HttpParams } from '@angular/common/http';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ModalWindow, Dialog, PageMenuItem } from '../CommonComponents';
import { Subscription } from 'rxjs';
//import DataSource from 'devextreme/data/data_source';
class jobdetails {
  Id: string;
  Name: string;
  Started: Date;
  Finished: Date;
  LastUpdated: Date;
  Progress: number;
  Status: string;
  JobStatus: number;
  JobId: string;
}


@Component({
  selector: 'app-jobs',
  templateUrl: 'AppJobs.template.html'
})

export class AppJobs implements OnInit {  
  public startDate: Date;
  public pdDate: Date;
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  public isLoading: boolean = false;
  QueryParam: JobQueryParam = new JobQueryParam();
  repository: DynamicRepository<Job>;
  public dialogvisible = false;
  public readonly headerHeight = 50;
  public readonly rowHeight = 50;
  clearselected: boolean = false;
  public dataSource$: Job[];
  public selected = new Array<Job>();
  public jobtype: number;
  public plugin: string;
  public plugins: { Name: string }[] = [
    { Name: "Any" },
    { Name: "Azure" },
    { Name: "HyperV" },
    { Name: "Monitoring" },
  ];
  public jobtypes: EnumDecoder[] = null;
 

  constructor(private http: HttpService, private el: ElementRef, private changeDetectorRef: ChangeDetectorRef, private MenuService: MenuService) {
    let that = this;
    that.pdDate = new Date();
    that.pdDate.setDate(that.pdDate.getDate() - 14);
    that.startDate = new Date();
    that.startDate.setDate(that.startDate.getDate() - 14);
    this.jobtypes = new Array<EnumDecoder>();
    this.jobtypes[0] = EnumDecoder.Inst(-1, "All jobs");
    this.jobtypes = this.jobtypes.concat(Job.GetJobStatusList());
    this.jobtype = -1;
  
    let loadData = (q: JobQueryParam) => {
      this.isLoading = true;
      return this.http.get<Job[]>('Core/Jobs/GetByContext', q.GetParams());
    };
    this.QueryParam.sort = 'Started:desc';
    this.QueryParam.startDateTime = that.startDate.toJSON();
    this.repository = new DynamicRepository<Job>(loadData, this.QueryParam, { TName: 'Job' });
    this.repository.GetDataSorce().subscribe(d => {
      this.dataSource$ = d;
      this.isLoading = false;
      if (this.clearselected) {
        this.selected = new Array<Job>();
        this.clearselected = false;
      }
    }); 
  }
  ngOnInit() {
    let s = new MenuSettings();
    s.rightVisible = false;
    this.MenuService.SetSetings(s);
    this.MenuService.toggleSecondaryMenu(false,true);
  }
  
  public filter(event) {
    this.repository.SetFilter(event);   
  }
  public onScroll(offsetY: number) {
    const p = !this.isLoading && !this.repository.LoadedAll;
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;
    if (p && offsetY + viewHeight >= this.dataSource$.length * this.rowHeight) {
      this.isLoading = true;
      this.repository.LoadMoreData();
    }
  }
  public onSort(event) {
    this.isLoading = true;
    setTimeout(() => {
      this.clearselected = true;
      this.repository.SetTSorting(event.sorts[0]);
    }, 300);
  }
  public getStatus(job: Job) {
    if (job == null)
      return '';
    return Job.GetJobStatus(job.JobStatus);
  }
  public getDateString(d: Date) {
    return Helper.GetDatedme(d,'/');
    }
  public DataChange(event: any) {
    let that = this;
    this.QueryParam.page = 0;
    this.QueryParam.startDateTime = that.startDate.toJSON();
    this.repository.Reload();
  }
  
  public get selectedjobtypestext() {
    if (Helper.NotEmpty(this.jobtypes)) {
      let type = Helper.FirstOrValue(this.jobtypes.filter(c => c.Code == this.selectedjobtypes));
      return type != null ? type.Name : '';
    }
    return '';
  }
  _selectedplugins = this.plugins[0].Name;
  public set selectedplugins(val) {
    if (val == this._selectedplugins) return;
    this.QueryParam.plugin = val;
    this._selectedplugins = val;
    this.QueryParam.page = 0;
    this.repository.Reload();
  }
  public get selectedplugins() {
    return this._selectedplugins;
  }
  public get selectedjobtypes() { return this._selectedjobtypes;}
  _selectedjobtypes: number=-1;
  public set selectedjobtypes(val) {
    this.QueryParam.jobstatus = val > -1 ? val : null;
    this.QueryParam.page = 0;
    this._selectedjobtypes = val;
    console.log(this._selectedjobtypes);
 //   console.log(this.QueryParam);
    this.repository.Reload();
  }
  lastClick: number = 0;
  public onActivate($event) {
    if ($event.type === 'click') {
      if (this.lastClick > 0 && Date.now() - this.lastClick <= 500) {
        //console.log('dblclick');
        this.openDetails();
      } else {
        this.lastClick = Date.now();
      }
    } else {
      this.lastClick = 0;
    }
  }
  public get ShowDetails() {
    return this.jobsdetails != null && this.jobsdetails.length > 0;
  }

  public selectedjob: Job = new Job();
  public jobsdetailsloading: boolean = false;
  public jobsdetails: jobdetails[] = [];
  public settings = { onClose: () => this.close() };
  subs: Subscription;
  private close() {
    if (this.subs)
      this.subs.unsubscribe();
    this.subs = null;
  }
  SummaryInfo: { Name: string, Value: string }[] = [];
  dialog = new Dialog({
    GetTitle: () => {
      let name = this.selectedjob != null ? this.selectedjob.Name : '';
      return ' Details of ' + name + ' job'
    },
    OnOpen: () => {
      this.jobsdetails = [];
      this.SummaryInfo = [];
      this.SummaryInfo[this.SummaryInfo.length] = { Name: 'Name', Value: this.selectedjob.Name };
      this.SummaryInfo[this.SummaryInfo.length] = { Name: 'State', Value: this.getStatus(this.selectedjob) };
      this.SummaryInfo[this.SummaryInfo.length] = { Name: 'Plugin', Value: this.selectedjob.Plugin };
      this.SummaryInfo[this.SummaryInfo.length] = { Name: 'Progress', Value: this.selectedjob.Progress + '%' };
      this.SummaryInfo[this.SummaryInfo.length] = { Name: 'Started', Value: this.getDateString(this.selectedjob.Started) };
      this.SummaryInfo[this.SummaryInfo.length] = { Name: 'Finished', Value: this.getDateString(this.selectedjob.Finished) };
      this.SummaryInfo[this.SummaryInfo.length] = { Name: 'Status', Value:this.selectedjob.Status };
      this.jobsdetailsloading = true;
      this.subs = this.http.get<jobdetails[]>('Core/Jobs/JobDetails/' + this.selectedjob.Id, null).subscribe(data => {
        this.jobsdetails = data;
        console.log(data);
        this.jobsdetailsloading = false;
        this.changeDetectorRef.detectChanges();
      });
    }
  });
  private openDetails() {
    if (this.selected.length == 0)
      return;
    this.selectedjob = this.selected[0];
    this.dialogvisible = true;
  }
}
