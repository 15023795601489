<div class="mw-layout mws-container " [ngClass]="visible? 'mw-show':''">
  <div class="mws-wrapper">
    <div class="mws-content gray-scrollbar">
      <div class="mw-cwrapper">
        <div class="mw-wrapper">
          <div class="mw-container">
            <div class=" mv-content" style="display:block">
              <div class="dialog" *ngIf="data.init">
                <div class="dialog-top">
                  <p class="dialog-title">{{data.GetTitle()}}</p>
                  <div class="dialog-close-btn" (click)="visible=false"></div>
                </div>
                <div class="wizard" >
                  <div class="wizard-menu" [ngClass]="data.isloading? 'disabled-with-opacity':''">
                    <p *ngFor="let step of data.steps; let i = index" class="wizard-menuitem"
                       [ngClass]="data.stepclass(i)" (click)="data.LoadStep(i)">{{step.title}}</p>
                  </div>
                  <div class="dialog-contwrap wizard-content"  >
                    <div class="dialog-preloader" *ngIf="data.isloading">
                      <img src="assets/images/loader.gif" />
                    </div>
                    <div class="dialog-content" [ngClass]="data.contentclass">
                      <p *ngIf="data.steps[data.stepnum].subtitle!=null" class="wizard-step-title">{{data.steps[data.stepnum].subtitle}}</p>
                      <ng-content></ng-content>
                    </div>
                  </div>
                </div>
                <div class="dialog-bottom" [ngClass]="data.isloading? 'disabled-with-opacity':''">
                  <p (click)="visible=false" class="dialog-button button-white">Cancel</p>
                  <!--<p (click)="Prior()" class="dialog-button button-white" [ngClass]="data.CanPrior()? '':'hide'">Back</p>-->
                  <p (click)="Next()" class="dialog-button button-blue" [ngClass]="data.CanNext()? '':'disabled-with-opacity'">Next</p>
                  <p (click)="Finish()" class="dialog-button button-blue" [ngClass]="data.CanFinish()? '':'hide'">Done</p>
                  <modalwindow [(visible)]="data.ErrorMessageVisible">
                    <p class="text1424">{{data.ErrorMessage}}</p>
                    <div class="dialog-bottom">
                      <p (click)="visible=false" class="dialog-button button-blue">Ok</p>
                    </div>
                  </modalwindow>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 
