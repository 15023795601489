import { Observable, BehaviorSubject } from 'rxjs';

import { Helper, RouteNames, Cluster, Host, VirtualMachine, VirtualNetworkSwitch, StorageContentType, StorageData} from '../classes';
import { DataStore, HttpService, WebsocketService } from '.';
import { Injectable } from '@angular/core';
import { Repository } from '../repository';
import { strict } from 'assert';


export interface IRepository<T> {
  addedObserver: BehaviorSubject<T[]>;
  modifiedObserver: BehaviorSubject<T[]>;
  deletedObserver: BehaviorSubject<string[]>;
  GetData: () => Promise<T[]>;
}

export class NavRepositoryAdapter<T> implements IRepository<NavNode>{
  constructor(private repository: Repository<T>, private fmap: (d: T) => NavNode) {
    let that = this;
    this.repository.Observers.subscribe(obs => {
      if (obs != null) {
        obs.addedObserver.subscribe(d => {
          let res: NavNode[] = []; d.forEach(i => { res[res.length] = this.fmap(i) });
          that.addedObserver.next(res);
        });
        obs.modifiedObserver.subscribe(d => {
          let res: NavNode[] = []; d.forEach(i => { res[res.length] = this.fmap(i) });
          that.modifiedObserver.next(res);
        });
        obs.deletedObserver.subscribe(d => { that.deletedObserver.next(d); });
      }
    });
  }
  public GetData() {
    let that = this;
    return new Promise<NavNode[]>((resolve) => {
      this.repository.GetData().then(d => { let res: NavNode[] = []; d.forEach(i => { res[res.length] = that.fmap(i) }); resolve(res); })
    });
  }
  public addedObserver: BehaviorSubject<NavNode[]> = new BehaviorSubject < NavNode[]>([]);
  public modifiedObserver: BehaviorSubject<NavNode[]> = new BehaviorSubject<NavNode[]>([]);
  public deletedObserver: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
}

export interface NavNode {
  type: string;
  id: string;
  name: string;
  parentId?: string;
  status?: string;
  lvl: number;
  parent?: NavNode;
  children: NavNode[];
}
export interface INavTree {
  getnodes(): Promise<{ [id: string]: NavNode }>;
  root: NavNode;
  refresh: () => void;
}
export class NavTree implements INavTree{
  constructor(private repositories: IRepository<NavNode>[]) {
    this.dataSubject = new BehaviorSubject<{ [id: string]: NavNode }>({});

    this.repositories.forEach(r => {
      if (r.addedObserver)
        r.addedObserver.subscribe(a => { this.Add(a); });
      if (r.modifiedObserver)
        r.modifiedObserver.subscribe(a => { this.Update(a); });
      if (r.deletedObserver)
      r.deletedObserver.subscribe(a => { this.Remove(a); });
    });
    WebsocketService.Instance().subscribe(ws => {
      ws.on<string[]>('ContextObject.Deleted').subscribe(d => { this.Remove(d); });
    })
  }
  public root: NavNode = {
    id: 'root',
    name: 'Datacenter',
    type: 'root',
    lvl: 0,
    children: []
  }
  public refresh() {
    this.root.children=[];
    this.loaded = false;
  }
  private dataSubject: BehaviorSubject<{ [id: string]: NavNode }>;
  private load: () => Promise<NavNode[]>;
  private loaded = false;
  private loading = false;
  public getnodes() {
    let root= this.root;
    return new Promise<{ [id: string]: NavNode }>((resolve) => {
      if (this.loaded) {
        resolve(this.dataSubject.value);
      }
      else {
        this.dataSubject.subscribe(o => {
          if (this.loaded) {
            resolve(this.dataSubject.value);
          }
        });
        if (!this.loading) {
          this.loading = true;
          let promises: Promise<NavNode[]>[] = [];
          this.repositories.forEach(r => {
            promises[promises.length] = r.GetData();
          });
          this.load = () => Promise.all(promises).then(observables => {
            let items: NavNode[] = [];
            observables.forEach(d => {
              items = items.concat(d);
            });
            return items;
          });
          this.load().then(d => {
            let data: { [id: string]: NavNode } = { 'root': root };
            d.forEach(e => { data[e.id] = e; });
            d.forEach(e => {
              let parent = data[e.parentId];
              if (parent == null) {
                parent = root;
              }
              parent.children[parent.children.length] = e;
              e.parent = parent;
            });
            this.loaded = true;
            this.dataSubject.next(data);
            this.loading = false;
          });
        }
      }
    });
  }

  private Add(added: NavNode[]) {
    this.getnodes().then(data => {
      let root = data['root'];
      added.forEach(e => {
        if(e.id != null){
          let parent = data[e.parentId];
          if (parent == null) {
            parent = root;
          }
          parent.children[parent.children.length] = e;
          e.parent = parent;
          data[e.id] = e;
        }
       
      });
    }); 
  }

  private Update(updated: NavNode[]) {
    this.getnodes().then(data => {
      let root = data['root'];
      updated.forEach(e => {
        if (e.id != null) {
          let item = data[e.id];
          if (item != null) {
            item.status = e.status;
            item.name = e.name;
            if (e.parentId != item.parentId) {
              let oparent = data[item.parentId];
              if (oparent) {
                const index = oparent.children.indexOf(item);
                if (index > -1) {
                  oparent.children.splice(index, 1);
                }
              }
              let parent = data[e.parentId];
              if (parent != null) {
                item.parent = parent;
                parent.children[parent.children.length] = item;
                item.parentId = e.parent.id;
              }
              else
                item.parentId = e.parentId;
            }
          }
          else {
            this.Add([e]);
          }
        }
      });
    }); 
  }

  private Remove(deleted: string[]) {
    this.getnodes().then(data => {
      const items = data;
      let values = Object.values(data);
      deleted.forEach(delid => {
        let item = items[delid];
        if (item != null) {
          let children = values.filter(e => e.parentId == delid);
          children.forEach(e => { e.parent = null; });
          let oparent = item.parent;
          if (oparent) {
            const index = oparent.children.indexOf(item);
            if (index > -1) {
              oparent.children.splice(index, 1);
            }
          }
        }
        delete items[delid];
      });
    });
  }
}
export class NavTreeNs implements INavTree {
  constructor(private load: () => Promise<NavNode[]>) { this.dataSubject = new BehaviorSubject<{ [id: string]: NavNode }>({}); }
  public root: NavNode = {
    id: 'root',
    name: 'Datacenter',
    type: 'root',
    lvl: 0,
    children: []
  }
  public refresh() {
    this.loaded = false;
  }
  private dataSubject: BehaviorSubject<{ [id: string]: NavNode }>;
  
  private loaded = false;
  private loading = false;
  public getnodes() {
    let root = this.root;
    return new Promise<{ [id: string]: NavNode }>((resolve) => {
      if (this.loaded) {
        resolve(this.dataSubject.value);
      }
      else {
        this.dataSubject.subscribe(o => {
          if (this.loaded) {
            resolve(this.dataSubject.value);
          }
        });
        if (!this.loading) {
          this.loading = true;
          this.load().then(d => {
            let data: { [id: string]: NavNode } = { 'root': root };
            d.forEach(e => { data[e.id] = e; });
            d.forEach(e => {
              let parent = data[e.parentId];
              if (parent == null) {
                parent = root;
              }
              parent.children[parent.children.length] = e;
              e.parent = parent;
            });
            this.loaded = true;
            this.dataSubject.next(data);
            this.loading = false;
          });
        }
      }
    });
  }
}

@Injectable({ providedIn: 'root' })
export class NavTreeFactory {
 
  constructor(private datastore: DataStore, private http: HttpService) {
    let that = this;
    HttpService.authorizedObserver.subscribe(Authorized => {
      if (!HttpService.Authorized) {
        Object.values(that.trees).forEach(tree => { tree.refresh(); });
      }
    });
  }
  public  get routes(): { [routname: string]: { [nodetype: string]: (node: NavNode) => string; } } {
    return {
      'hv/vm': {
        'root': (node: NavNode) => { return '/hv/vm' },
        'cluster': (node: NavNode) => { return `//hv/vm/${RouteNames.Cluster}/${node.id}` },
        'host': (node: NavNode) => { return `/hv/vm/${RouteNames.Host}/${node.id}` },
        'vm': (node: NavNode) => { return `/hv/vm/${node.id}` },
      },
      'hv/vs': {
        'root': (node: NavNode) => { return '/hv/vs' },
        'cluster': (node: NavNode) => { return `/hv/vs/${RouteNames.Cluster}/${node.id}` },
        'host': (node: NavNode) => { return `/hv/vs/${RouteNames.Host}/${node.id}` },
        'vs': (node: NavNode) => { return `/hv/vs/${node.id}` },
      },
      'hv/ds': {
        'root': (node: NavNode) => { return '/hv/ds' },
        'Local': (node: NavNode) => { return `/hv/ds/${node.parentId}/Local` },
        'SMB': (node: NavNode) => { return `/hv/ds/${node.parentId}/SMB` },
        'CSV': (node: NavNode) => { return `/hv/ds/${node.parentId}/CSV`  },
        'BackupStorage': (node: NavNode) => { return '/hv/ds/BackupStorage' },
        'VMStorage': (node: NavNode) => { return '/hv/ds/VMStorage' },
        'ISOLib': (node: NavNode) => { return '/hv/ds/ISOLib' },
        'TemplateLib': (node: NavNode) => { return '/hv/ds/TemplateLib' },
        'ds': (node: NavNode) => { return `/hv/ds/storage/${node.id}` },
      },
      'azure/vm': {
        'root': (node: NavNode) => { return '/azuremanagement/azure' },
        'azuresubs': (node: NavNode) => { return `/azuremanagement/azure/${node.id}` },
        'azurevm': (node: NavNode) => { return `/azuremanagement/azure/vm/${node.id}` },
      },
      'azure/replicate': {
        'root': (node: NavNode) => { return '/azuremanagement/replication' },
        'azuresubs': (node: NavNode) => { return `/azuremanagement/replication/${node.id}` },
        'azurereplicateitem': (node: NavNode) => { return `/azuremanagement/replication/${node.parentId}/${node.name}` },
      },
      'monitoring/sum': {
        'root': (node: NavNode) => { return '/monitoring/summary' },
        'cluster': (node: NavNode) => { return `/monitoring/summary/${RouteNames.Cluster}/${node.id}` },
        'host': (node: NavNode) => { return `/monitoring/summary/${RouteNames.Host}/${node.id}` },
        'vm': (node: NavNode) => { return `/monitoring/summary/vm/${node.id}` },
      },
      'monitoring/cpu': {
        'root': (node: NavNode) => { return '/monitoring/summary' },
        'cluster': (node: NavNode) => { return `/monitoring/summary/${RouteNames.Cluster}/${node.id}` },
        'host': (node: NavNode) => { return `/monitoring/cpu/${RouteNames.Host}/${node.id}` },
        'vm': (node: NavNode) => { return `/monitoring/cpu/vm/${node.id}` },
      },
      'monitoring/mem': {
        'root': (node: NavNode) => { return '/monitoring/summary' },
        'cluster': (node: NavNode) => { return `/monitoring/summary/${RouteNames.Cluster}/${node.id}` },
        'host': (node: NavNode) => { return `/monitoring/memory/${RouteNames.Host}/${node.id}` },
        'vm': (node: NavNode) => { return `/monitoring/memory/vm/${node.id}` },
      },
      'monitoring/disk': {
        'root': (node: NavNode) => { return '/monitoring/summary' },
        'cluster': (node: NavNode) => { return `/monitoring/disk/${RouteNames.Cluster}/${node.id}` },
        'host': (node: NavNode) => { return `/monitoring/disk/${RouteNames.Host}/${node.id}` },
        'vm': (node: NavNode) => { return `/monitoring/disk/vm/${node.id}` },
      },
      'monitoring/net': {
        'root': (node: NavNode) => { return '/monitoring/summary' },
        'cluster': (node: NavNode) => { return `/monitoring/summary/${RouteNames.Cluster}/${node.id}` },
        'host': (node: NavNode) => { return `/monitoring/network/${RouteNames.Host}/${node.id}` },
        'vm': (node: NavNode) => { return `/monitoring/network/vm/${node.id}` },
      },
      'reporting/vmlifecycle': {
        'root': (node: NavNode) => { return '/reporting/vmlifecycle' },
        'cluster': (node: NavNode) => { return `/reporting/vmlifecycle/${RouteNames.Cluster}/${node.id}` },
        'host': (node: NavNode) => { return `/reporting/vmlifecycle/${RouteNames.Host}/${node.id}` },
        'vm': (node: NavNode) => { return `/reporting/vmlifecycle/vm/${node.id}` },
      },
      'reporting/zombievm': {
        'root': (node: NavNode) => { return '/reporting/zombievm' },
        'cluster': (node: NavNode) => { return `/reporting/zombievm/${RouteNames.Cluster}/${node.id}` },
        'host': (node: NavNode) => { return `/reporting/zombievm/${RouteNames.Host}/${node.id}` },
        'vm': (node: NavNode) => { return `/reporting/zombievm/vm/${node.id}` },
      },
      'reporting/systemstaus': { 'root': (node: NavNode) => { return '/reporting/systemstatus' }}
    }
  }
  //icon-replicate
  public get imeges(): { [nodetype: string]: (node: NavNode) => string; }
  {
    let ds = 'menuitem-ds';
    return {
      'root': (node: NavNode) => { return 'menuitem-datacenter' },
      'cluster': (node: NavNode) => { return 'menuitem-cluster' },
      'host': (node: NavNode) => { return 'menuitem-host' },
      'vm': (node: NavNode) => { return 'menuitem-vm' },
      'vs': (node: NavNode) => { return 'icon-switch' },
      'ds': (node: NavNode) => { return ds },
      'Local': (node: NavNode) => { return ds },
      'SMB': (node: NavNode) => { return ds },
      'CSV': (node: NavNode) => { return ds },
      'BackupStorage': (node: NavNode) => { return ds},
      'VMStorage': (node: NavNode) => { return ds },
      'ISOLib': (node: NavNode) => {   return ds },
      'TemplateLib': (node: NavNode) => { return ds },
      'azuresubs': (node: NavNode) => { return 'menuitem-cluster' },
      'azurevm': (node: NavNode) => { return 'menuitem-vm' },
      'azurereplicateitem': (node: NavNode) => { return 'menu-icon icon-replicate' },
    };
  } 
  
  static _emptytree: NavTree = new NavTree([{
    GetData: () => { return new Promise<NavNode[]>((resolve) => { resolve([]) }) },
    addedObserver:  new BehaviorSubject<NavNode[]>([]),
    modifiedObserver: new BehaviorSubject<NavNode[]>([]),
    deletedObserver:  new BehaviorSubject<string[]>([])
  }]);;
  private get emptytree() {
    return NavTreeFactory._emptytree;
  }
  static _hvvmtree: NavTree = null;
  private get hvvmtree() {
    let that = this;
    if (NavTreeFactory._hvvmtree == null)
      NavTreeFactory._hvvmtree = new NavTree([
        new NavRepositoryAdapter(that.datastore.getClusters(), (cluster: Cluster) => { return { type: 'cluster', id: cluster.Id, name: cluster.Name, parentId: cluster.Parent_Id, lvl: 1, children: [] } }),
        new NavRepositoryAdapter(that.datastore.getHosts(), (obj: Host) => { return { type: 'host', id: obj.Id, name: obj.Name, lvl: 2, parentId: obj.Parent_Id, children: [] } }),
        new NavRepositoryAdapter(that.datastore.getVirtualMachines(), (obj: VirtualMachine) => { return { type: 'vm', id: obj.Id, name: obj.Name, lvl: 2, parentId: obj.Parent_Id, children: [] } })
      ]);
    return NavTreeFactory._hvvmtree;
  }
  static _hvvstree: NavTree = null;
  private get hvvstree() {
    let that = this;
    if (NavTreeFactory._hvvstree == null)
      NavTreeFactory._hvvstree = new NavTree([
        new NavRepositoryAdapter(that.datastore.getClusters(), (cluster: Cluster) => { return { type: 'cluster', id: cluster.Id, name: cluster.Name, parentId: cluster.Parent_Id, lvl: 1, children: [] } }),
        new NavRepositoryAdapter(that.datastore.getHosts(), (obj: Host) => { return { type: 'host', id: obj.Id, name: obj.Name, lvl: 2, parentId: obj.Parent_Id, children: [] } }),
        new NavRepositoryAdapter(that.datastore.getVirtualSwitches(), (obj: VirtualNetworkSwitch) => { return { type: 'vs', id: obj.Id, name: obj.Name, lvl: 2, parentId: obj.Parent_Id, children: [] } })
      ]); 
    return NavTreeFactory._hvvstree;
  }

  static _hvdstree: NavTree = null;
  private get hvdstree() {
    let getdata = new Promise<NavNode[]>((resolve) => {
      let res: NavNode[] = [
        { id: StorageContentType.VMStorage, name: 'Virtual machine storage', lvl: 1, type: StorageContentType.VMStorage, children: [] },
        { id: StorageContentType.ISOLib, name: 'ISO library', lvl: 1, type: StorageContentType.ISOLib, children: [] },
        { id: StorageContentType.TemplateLib, name: 'Template library', lvl: 1, type: StorageContentType.TemplateLib, children: [] },
        { id: StorageContentType.BackupStorage, name: 'Backup storage', lvl: 1, type: StorageContentType.BackupStorage, children: [] },
        { id: '0' + StorageContentType.VMStorage, parentId: StorageContentType.VMStorage, name: 'Local', lvl: 2, type: 'Local', children: [] },
        { id: '1' + StorageContentType.VMStorage, parentId: StorageContentType.VMStorage, name: 'SMB', lvl: 2, type: 'SMB', children: [] },
        { id: '2' + StorageContentType.VMStorage, parentId: StorageContentType.VMStorage, name: 'CSV', lvl: 2, type: 'CSV', children: [] },
        { id: '0' + StorageContentType.ISOLib, parentId: StorageContentType.ISOLib, name: 'Local', lvl: 2, type: 'Local', children: [] },
        { id: '1' + StorageContentType.ISOLib, parentId: StorageContentType.ISOLib, name: 'SMB', lvl: 2, type: 'SMB', children: [] },
        { id: '2' + StorageContentType.ISOLib, parentId: StorageContentType.ISOLib, name: 'CSV', lvl: 2, type: 'CSV', children: [] },
        { id: '0' + StorageContentType.TemplateLib, parentId: StorageContentType.TemplateLib, name: 'Local', lvl: 2, type: 'Local', children: [] },
        { id: '1' + StorageContentType.TemplateLib, parentId: StorageContentType.TemplateLib, name: 'SMB', lvl: 2, type: 'SMB', children: [] },
        { id: '2' + StorageContentType.TemplateLib, parentId: StorageContentType.TemplateLib, name: 'CSV', lvl: 2, type: 'CSV', children: [] },
        { id: '0' + StorageContentType.BackupStorage, parentId: StorageContentType.BackupStorage, name: 'Local', lvl: 2, type: 'Local', children: [] },
        { id: '1' + StorageContentType.BackupStorage, parentId: StorageContentType.BackupStorage, name: 'SMB', lvl: 2, type: 'SMB', children: [] },
        { id: '2' + StorageContentType.BackupStorage, parentId: StorageContentType.BackupStorage, name: 'CSV', lvl: 2, type: 'CSV', children: [] },
      ];
      resolve(res);
    });

    let repository: IRepository<NavNode> = {
      GetData: () => { return getdata; },
      addedObserver: new BehaviorSubject<NavNode[]>([]),
      modifiedObserver: new BehaviorSubject<NavNode[]>([]),
      deletedObserver: new BehaviorSubject<string[]>([])
    }
    let that = this;
    if (NavTreeFactory._hvdstree == null) {
      NavTreeFactory._hvdstree = new NavTree([repository,
        new NavRepositoryAdapter(that.datastore.getStoreges(), (obj: StorageData) => { return { type: 'ds', id: obj.Id, name: obj.Name, lvl: 2, parentId: obj.Type + obj.ContentType, children: [] } })
      ]);
      NavTreeFactory._hvdstree.root.name = 'Datastore Objects';
    }
     
    return NavTreeFactory._hvdstree;
  }

  static _azurevmtree: NavTree = null;
  private get azurevmtree() {
    let that = this;
    if (NavTreeFactory._azurevmtree == null) {
      NavTreeFactory._azurevmtree = new NavTree([new NavRepositoryAdapter(that.datastore.getAzureSubscibtions(), that.AzureSubscibtionsMap  ),
        new NavRepositoryAdapter(that.datastore.getAzureVirtualMachines(), (obj: any) => { return { type: 'azurevm', id: obj.Id, name: obj.Name, lvl: 2, parentId: obj.Parent_Id, children: [] } })
      ]);
      NavTreeFactory._azurevmtree.root.name='All virtual machines'
    }
    return NavTreeFactory._azurevmtree;
  }
  AzureSubscibtionsMap= (obj: any) => { return { type: 'azuresubs', id: obj.Id, name: `${obj.Name}${obj.Tenant ? ` (${obj.Tenant})` : ''}`, lvl: 1, children: [] } };
  static _azurereplicatetree: INavTree = null;
  getRecoveryVaults(id) {
    return new Promise<NavNode[]>((resolve) => {
      this.http.get<any[]>(`Azure/${id}/Replication/RecoveryVaults`, null).subscribe(rv => {
        let res: NavNode[] = [];
        rv.forEach(obj => {
          res[res.length] = { type: 'azurereplicateitem', id: obj.SubscriptionId + '/' + obj.Name, name: obj.Name, lvl: 2, parentId: obj.SubscriptionId, children: [] }
        });
        resolve(res);
      });
    });
  }
  private get azurereplicatetree() {
    let that = this;
    if (NavTreeFactory._azurereplicatetree == null) {
      let load = () => {
        return new Promise<NavNode[]>((resolve) => {
          that.datastore.getAzureSubscibtions().GetData().then(subs => {
            let promises: Promise<NavNode[]>[] = [];
            let nodes: NavNode[] = [];
            subs.forEach(r => {
              nodes[nodes.length] = that.AzureSubscibtionsMap(r);
              promises[promises.length] = that.getRecoveryVaults(r.Id);
            });
             Promise.all(promises).then(observables => {
               observables.forEach(d => { nodes = nodes.concat(d) });
               resolve(nodes);
            });
          });
        })
      };
      NavTreeFactory._azurereplicatetree = new NavTreeNs(load);
      NavTreeFactory._azurereplicatetree.root.name = 'All Replicated Items';
    }
    return NavTreeFactory._azurereplicatetree;
  }
  static _systemstaus: NavTree = new NavTree([{
    GetData: () => { return new Promise<NavNode[]>((resolve) => { resolve([]) }) },
    addedObserver: new BehaviorSubject<NavNode[]>([]),
    modifiedObserver: new BehaviorSubject<NavNode[]>([]),
    deletedObserver: new BehaviorSubject<string[]>([])
  }]);;
  private get systemstaus() {
    return NavTreeFactory._systemstaus;
  }
  private get trees(): { [treename: string]: INavTree } {
    let that = this;
    return {
      'hv/vm':that.hvvmtree,
      'hv/vs': that.hvvstree,
      'hv/ds': that.hvdstree,
      'azure/vm': that.azurevmtree,
      'azure/replicate': that.azurereplicatetree,
      'reporting/systemstaus': that.systemstaus
    }; 
  }
  public getTree(routname: string,refresh=false) {
    let routtree: { [routname: string]: string } =
    {
      'hv/vm': 'hv/vm',
      'hv/vs': 'hv/vs',
      'hv/ds': 'hv/ds',
      'azure/vm': 'azure/vm',
      'azure/replicate': 'azure/replicate',
      'monitoring/sum': 'hv/vm',
      'monitoring/cpu': 'hv/vm',
      'monitoring/mem': 'hv/vm',
      'monitoring/disk': 'hv/vm',
      'monitoring/net': 'hv/vm',
      'reporting/vmlifecycle': 'hv/vm',
      'reporting/zombievm': 'hv/vm',
      'reporting/systemstaus': 'reporting/systemstaus',
    }
    let treename = routtree[routname];
    if (treename == null) {
      console.log('No tree for route name:' + routname);
      return this.emptytree;
    }
    else {
      let res = this.trees[treename];
      if (refresh)
        res.refresh();
      return res;
    }
  }

  public GetBreadCrumps(treename: string, nodeid: string) {
    let that = this;
    return new Promise<{ name: string, address: string }[]>((resolve) => {
      let routes = that.routes[treename];
      let tree = that.getTree(treename);
      if (tree == null || routes==null || nodeid == 'root') {
        resolve([]);
      }
      else {
        tree.getnodes().then(nodes => {
          let breadcrumps: { name: string, address: string }[] = [];
          let node = nodes[nodeid];
          let bnodes: NavNode[]=[]
          while (node != null) {
            bnodes[bnodes.length] = node;
            node = node.parent;
          }
          bnodes.sort((a, b) => a.lvl > b.lvl ? 1 : -1).forEach(node => {
            let link = routes[node.type](node);
            breadcrumps[breadcrumps.length] = { name: node.name, address: link  }
          });
          resolve(breadcrumps);
        });
      }
    });
  }
}
