import { Component, Input } from '@angular/core';
import { DynamicTreeModel } from './DynamicTreeModel';


@Component({
  selector: 'dynamictree',
  templateUrl: './DynamicTree.component.html'
})

export class DynamicTree {
  @Input()
  get data() {
    return this._data;
  }
  set data(val) {
    this._data = val;
}
  private _data: DynamicTreeModel;
   
  
}


