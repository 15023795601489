import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppRoutes } from './app.routes';

@NgModule({
    imports: [
      RouterModule.forRoot(AppRoutes.root),
    //  RouterModule.forRoot(AppRoutes.jobs),
      RouterModule.forRoot( AppRoutes.leftMenu ),
      //RouterModule.forRoot(AppRoutes.azureMenu),
      //RouterModule.forRoot(AppRoutes.reportMenu)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
