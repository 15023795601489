import { WebsocketService } from '../services/websocket.service';
import { BehaviorSubject,Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
export interface IQueryParam {
  filter: string;
  sort: string;
  pagesize: number;
  page: number;
}
export class QueryParam implements IQueryParam  {
 
  filter: string = '';
  sort: string = '';
  pagesize: number = 20;
  page: number = 0;
  public GetParams() {
    let httpParams = new HttpParams();
    let data = this;
    Object.keys(data).forEach(function (key) {
      httpParams = httpParams.append(key, data[key]);
    });
    return httpParams;
  }
}
export class JobQueryParam implements IQueryParam {
  //(DateTime startDateTime, PluginType plugin, JobStatus? jobstatus,
  filter: string = '';
  sort: string = '';
  pagesize: number = 20;
  page: number = 0;
  startDateTime: string = '';
  plugin: string = 'Any';
  jobstatus: number=null;
  public GetParams() {
    let httpParams = new HttpParams();
    let data = this;
    Object.keys(data).forEach(function (key) {
      httpParams = httpParams.append(key, data[key]);
    });
    return httpParams;
  }
} 
export class DynamicRepository<T>{    
  private addedObserver: Observable<T[]>;
  private modifiedObserver: Observable<T[]>;
  private deletedObserver: Observable<string[]>;
  private dataSubject: BehaviorSubject<{ [id: string]: T }>;
  private DataSorce: Observable<T[]>;
  private TName: string;
  private id: string;
  public LoadedAll: boolean = false;
  constructor(private load: (q: IQueryParam) => Observable<T[]>, public QueryParam: IQueryParam, prop: { [id: string]: any }) {
    let that = this;
    that.setProperty(prop);
    that.dataSubject = new BehaviorSubject<{ [id: string]: T }>({});
    that.DataSorce = that.dataSubject.pipe(map(v => Object.keys(v).map(itm => v[itm])));
    WebsocketService.Instance().subscribe(ws => {
      that.addedObserver = ws.on<T[]>(that.getEventName('Added'));
      that.modifiedObserver = ws.on<T[]>(that.getEventName('Modified'));
      that.deletedObserver = ws.on<string[]>(that.getEventName('Deleted'));
      that.addedObserver.subscribe(data => {
       // that.loadQ(that.param);
      });
      that.modifiedObserver.subscribe(data => {
        const e = that.dataSubject.value;
        let j = 0;
        for (var i = 0; i < data.length; i++) {
          var item = data[i];
          if (e[item[that.id]] != null) {
            e[item[that.id]] = item;
            j++;
          }
        }
        if (j > 0)
        that.dataSubject.next(e);
      });
      that.deletedObserver.subscribe(data => {
        this.Remove(data);
       
      });
    });
  }
  public Remove(data: Array<string>) {
    let that = this;
    if (data && data.length > 0) {

      const e = Object.values(that.dataSubject.value);
      let d = e.filter(v => {
        return !data.includes(v[that.id])
      });
      that.loadData(d, true);
    }
  }

  private getEventName(eventType): string {
    return this.TName + "." + eventType;
  }
  private setProperty(prop: { [id: string]: any }) {
    this.id = prop['id'] || 'Id';
    this.TName = prop['TName'];
  }
  private loadQ(q: IQueryParam, reload: boolean = false) {
    let that = this;
    that.QueryParam = q;
    this.load(q).subscribe(d => {
      that.loadData(d, reload);
      this.LoadedAll = d.length == 0;
      if (!this.LoadedAll)
        this.QueryParam.page++;
    });//.catch(err => console.log("dynamic repository error", err));
  }
  private loadData(d: T[], reload: boolean = false) {
    let that = this;
    const newdata = reload ? {}: { ...that.dataSubject.value };
    for (var i = 0; i < d.length; i++) {
      var item = d[i];
      newdata[item[that.id]] = item;
    }
    that.dataSubject.next(newdata);
  }
  public LoadMoreData() {
    if (!this.LoadedAll)
      this.loadQ(this.QueryParam);
  }
  public Reload() {
    this.loadQ(this.QueryParam, true);
  }
  public SetTSorting(sort: any) {
    this.QueryParam.sort = sort.prop + ':' + sort.dir;
    this.QueryParam.page = 0;
    this.Reload();
  }
  public SetFilter(filter: any) {
    this.QueryParam.filter = filter;
    this.QueryParam.page = 0;
    this.Reload();
  }

  public GetDataSorce() {
    this.LoadMoreData();
    return this.DataSorce;
  }
} 
