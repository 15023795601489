import { Component,  OnInit,   ElementRef, Input, ChangeDetectorRef, ViewChild } from '@angular/core';

@Component({
  selector: 'grid-settings',
  templateUrl: './GridSettings.component.html'
})

export class GridSettings implements OnInit{
  _columns: { [id: string]: boolean } = {};
  @Input()
  get columns(): { [id: string]: boolean }  {
    return this._columns;
  }
  set columns(val: { [id: string]: boolean } ) {
    this._columns = val;
  }
 
  public menuShow() {
    let p = document.querySelector('.cdk-overlay-pane');
    if (p)
      p.classList.add('rowactions-menu');
  }

  ngOnInit(): void {
   
  }
  stopPropagation(event: any,key) {
    console.log(event);
    event.stopPropagation();
    event.preventDefault();
    this.columns[key] = !this.columns[key];
  }
}
