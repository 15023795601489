import { Component,  OnInit, ElementRef, Input, EventEmitter, Output, OnDestroy } from '@angular/core';

import { Observable, BehaviorSubject} from 'rxjs';
import { Helper } from '../../../../classes';

@Component({
  selector: 'dropdown',
  templateUrl: './DropDown.component.html'
})

export class DropDown implements OnInit, OnDestroy {

  
  @Output()
  public openChange = new EventEmitter<boolean>();
  @Input()
  get open() {
    return this._open;
  }
  set open(val) {
    if (this._open == val) return;
    this._open = val;
    this.openChange.emit(this._open );
  }
  private _open = false;
  @Output()
  public valueChanges = new EventEmitter<string>();
  @Input()
  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
  }
  private _value: string='';

  @Input()
  get label() {
    return this._label;
  }
  set label(val) {
    this._label = val;
}
  private _label: string;

  constructor(private elRef: ElementRef) { }
  closehandler(e) {
    let root = this.elRef.nativeElement;
    if (!root.contains(e.target)) {
      this.open = false;
    }
  }
  ngOnInit(): void {
    window.addEventListener('click', this.closehandler.bind(this));
  }
  ngOnDestroy() {
    window.removeEventListener('click', this.closehandler);
  }  
}


