import { Component,Input } from '@angular/core';


@Component({
  selector: 'tabs-menu',
  templateUrl: 'TabsMenu.template.html'
})

export class TabsMenuComponent  {
  @Input() public tabs: Array<any> = [];
  @Input() public id: string = '';
  @Input() public withoutLink: boolean = false;
  @Input() public backgroundTransparent: boolean = false;

  public selectTab(selectedIndex) {
    this.tabs.forEach(tab => {
      tab.active = false;
    })
    this.tabs[selectedIndex].active = true;
  }
 
}

