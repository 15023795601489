<div class="root  {{menu.currentRoute!=null? menu.currentRoute.data.containerclass || '' : ''}}" id="root" [ngClass]="{'not-authorized':!authorized}">
  <div class="root-body gray-scrollbar" [ngClass]="menuClass">
    <div *ngIf="authorized" class="root-menu-left">
      <div class="logo-wrapper">
        <div class="logo-icon"></div>
      </div>
      <div class="menu-left">
        <p *ngFor="let route of menuitems" class="menu-left-items {{route.data.class}}" [ngClass]="route.data.active? 'menu-active': ''" [routerLink]="route.path">
          {{route.data.title}}
        </p>
          <p style="color:#ddd; position:absolute; bottom:0; padding-left:20px; font-size:12px;">v {{currentApplicationVersion}}</p>
      </div>
    </div>
    <InfrastructureMenu id="InfrastructureMenu" [authorized]="authorized"></InfrastructureMenu>
    <div class="root-content">
      <div class="root-content-body  gray-scrollbar">
        <router-outlet id="outlet"></router-outlet>
      </div>
    </div>
  </div>
</div>

