import { Injectable, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { AppRoutes } from '../app.routes';
import { Observable, BehaviorSubject, of, EMPTY, zip } from 'rxjs';
import { InfrastructureMenuItemNavigationData, InfMenuItem, MenuSettings, WarningTypes, RouteNames, Helper, Job, Guid } from '../classes';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { MenuService, WebsocketService } from '.';
import { catchError, filter, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ReportingService {
  public static zombievm = "Zombie Vm";
  public static vmlifecycle = "Vm life cycle";
  public static systemstatus = "System status";
  public static get months() { return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']; }
  public gettabs(name) {
    var res = [
     
     {
       name: ReportingService.vmlifecycle,
       link: `/${RouteNames.Reporting}/${RouteNames.VmLifeCycle}/`,
        active: false,
      },
     {
       name: ReportingService.systemstatus,
        link: `/${RouteNames.Reporting}/${RouteNames.SystemStatus}/`,
        active: false,
      },
      {
        name: ReportingService.zombievm,
        link: `/${RouteNames.Reporting}/${RouteNames.ZombieVm}/`,
        active: false,
      },
    ];
    res.forEach(i => { if (i.name == name) i.active = true; })
    return res;
  }

  constructor(private HttpService: HttpService, private MenuService: MenuService) {
  
  }
  //deallocateAzureVm(id) {
  //  return this.HttpService.post(`Azure/GenericVMOperation/${id}/Deallocate`, null);
  //}


}

