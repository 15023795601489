import { Injectable, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { AppRoutes } from '../app.routes';
import { Observable, BehaviorSubject, of, EMPTY, zip } from 'rxjs';
import { InfrastructureMenuItemNavigationData, InfMenuItem, MenuSettings, WarningTypes, RouteNames, Helper, Job } from '../classes';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { MenuService, WebsocketService } from '.';
import { catchError, filter } from 'rxjs/operators';
export interface CreateAzureVMSettings {
  Name?: string;
  Location?: string;
  UserName?: string;
  Password?: string;
  ResourceGroup?: any;
  SizeName?: string;
  AvailabilitySet?: any;
  NetworkInterface?: any;
  SelectedVirtualMachine?: any;
  PrivateIp?: string;
  Sku?: any;
  StorageAccount?: any;
  StorageKey?: any;
  AddressSpace: string;
  IsDynamicPrivate: boolean;
  IsWithoutPublic: boolean;
  LeafDNSLabel: string;
  IsWindows: boolean;
  AzureDisks: any[];
}
export interface UnplannedFailoverMessage
{
   ShutDown: boolean;
   RecoveryPointId: string;
   ReplicatedItem: any;// AzureReplicatedItem;
}
export interface TestFailoverMessage {
   RecoveryPointId: string;
   NetworkId: string;
   ReplicatedItem: any;// AzureReplicatedItem;
}
export interface ChangeRecoveryPointMessage {
   RecoveryPointId: string;
   ReplicatedItem: any;// AzureReplicatedItem;
}
@Injectable({ providedIn: 'root' })
export class AzureService {

  nullObjectId: string = '00000000-0000-0000-0000-000000000000';

  constructor(private HttpService: HttpService, private MenuService: MenuService) {
  
  }

  getAzureVmStatus(row) {
    return this.getAzureVmStatusText(row['Status']);
  }

  vmStatusesTexts = {
    0: 'Unknown',
    1: 'Running',
    2: 'Deallocating',
    3: 'Stopped (deallocated)',
    4: 'Starting',
    5: 'Stopped',
    6: 'Stopping'
  }

  vmStatuses = {
    'Unknown': 0,
    'Running': 1,
    'Deallocating': 2,
    'Deallocated': 3,
    'Starting': 4,
    'Stopped': 5,
    'Stopping': 6
  }

 
   
  get popularImages() {
    return [{ "Publisher": "Canonical", "Name": "18.04-LTS", "OfferName": "UbuntuServer", "PrettyName": "Ubuntu Server 18.04 LTS - Gen1" },
    { "Publisher": "Canonical", "Name": "19.04", "OfferName": "UbuntuServer", "PrettyName": "Ubuntu Server 19.04 - Gen1" },
    { "Publisher": "RedHat", "Name": "rhel-lvm82", "OfferName": "rhel-byos", "PrettyName": "Red Hat Enterprise Linux 8.2 (LVM) - Gen1" },
    { "Publisher": "SUSE", "Name": "15-2", "OfferName": "openSUSE-Leap", "PrettyName": "SUSE Enterprise Linux 15 SP2 +Patching - Gen1" },
    { "Publisher": "OpenLogic", "Name": "8_2", "OfferName": "CentOS", "PrettyName": "CentOS 8.2 - Gen1" },
    { "Publisher": "Debian", "Name": "10", "OfferName": "debian-10", "PrettyName": "Debian 10 - Gen1" },
    { "Publisher": "Oracle", "Name": "ol83-lvm", "OfferName": "Oracle-Linux", "PrettyName": "Oracle Linux 8.3 (LVM) - Gen1" },
    { "Publisher": "MicrosoftWindowsServer", "Name": "2019-Datacenter", "OfferName": "WindowsServer", "PrettyName": "Windows Server 2019 Datacenter - Gen1" },
    { "Publisher": "MicrosoftWindowsServer", "Name": "2016-Datacenter", "OfferName": "WindowsServer", "PrettyName": "Windows Server 2016 Datacenter - Gen1" },
    { "Publisher": "MicrosoftWindowsServer", "Name": "2012-R2-Datacenter", "OfferName": "WindowsServer", "PrettyName": "Windows Server 2012R2 Datacenter - Gen1" }];
  }
  locations: any = {
    'australiacentral': 'Australia Central',
    'australiacentral2': 'Australia Central 2',
    'australiaeast': 'Australia East',
    'australiasoutheast': 'Australia Southeast',
    'brazilsouth': 'Brazil South',
    'canadacentral': 'Canada Central',
    'canadaeast': 'Canada East',
    'centralindia': 'Central India',
    'centralus': 'Central US',
    'chinaeast': 'China East',
    'chinanorth': 'China North',
    'eastasia': 'East Asia',
    'eastus': 'East US',
    'eastus2': 'East US 2',
    'francecentral': 'France Central',
    'francesouth': 'France East',
    'germanycentral': 'Germany Central',
    'germanynortheast': 'Germany Northeast',
    'japaneast': 'Japan East',
    'japanwest': 'Japan West',
    'koreacentral': 'Korea Central',
    'koreasouth': 'Korea South',
    'northcentralus': 'North Central US',
    'northeurope': 'North Europe',
    'southcentralus': 'South Central US',
    'southeastasia': 'South East Asia',
    'southindia': 'South India',
    'uksouth': 'UK South',
    'ukwest': 'UK West',
    'usdodcentral': 'US DoD Central',
    'usdodeast': 'US DoD East',
    'usgovarizona': 'US Gov Arizona',
    'usgoviowa': 'US Gov Iowa',
    'usgovtexas': 'US Gov Texas',
    'usgovvirginia': 'US Gov Virginia',
    'westcentralus': 'West Central US',
    'westeurope': 'West Europe',
    'westindia': 'West India',
    'westus': 'West US',
    'westus2': 'West US 2',
  }

  getLocationKeys() {
    return Object.keys(this.locations);
  }

  getLocations() {
    return this.getLocationKeys().map(key => ({ key, location: this.locations[key] }));
  }
  getAzureVmStatusText(index) {
    return this.vmStatusesTexts[index] || '';
  }

  getMenuDataContext(data, subPart, upSuffix = '', upName = '', idString = 'Id') {
    let res = [];
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      res[i] = InfMenuItem.NewItem(`icon-azure-${item.ItemClass || ''}`, item.Name, item[idString] || '', '', `/${RouteNames.AzureManagement}/${subPart}/${item[idString] || ''}`);
    }
    let ctx = new InfrastructureMenuItemNavigationData();
    if (upName) {
      ctx.up = `/${RouteNames.AzureManagement}/${upSuffix}`;
      ctx.name = upName;
    }
    ctx.data = of(res);
    return ctx;
  }

  getMenuBasicUpLink(suffix, upName = '') {
    let ctx = new InfrastructureMenuItemNavigationData();
    if (upName) {
      ctx.up = `/${RouteNames.AzureManagement}/${suffix}`;
      ctx.name = upName;
    }
    return ctx;
  }

  getAzureVmLink(id) {
    return `/${RouteNames.AzureManagement}/${RouteNames.Azure}/${RouteNames.VirtualMachines}/${id}`;
  }

  getAzureVirtualMachine(id) {
    return this.HttpService.get(`Azure/GetVirtualMachine/${id}`, null);
  }
  getMetrics(subscriptionId, requestObject) {
    return zip(
      this.HttpService.post(`Azure/${subscriptionId}/Monitoring/ExecuteMetricDefinitionQuery/0`, requestObject),
      this.HttpService.post(`Azure/${subscriptionId}/Monitoring/ExecuteMetricDefinitionQuery/1`, requestObject),
      this.HttpService.post(`Azure/${subscriptionId}/Monitoring/ExecuteMetricDefinitionQuery/2`, requestObject),
      this.HttpService.post(`Azure/${subscriptionId}/Monitoring/ExecuteMetricDefinitionQuery/3`, requestObject),
      this.HttpService.post(`Azure/${subscriptionId}/Monitoring/ExecuteMetricDefinitionQuery/4`, requestObject),
      this.HttpService.post(`Azure/${subscriptionId}/Monitoring/ExecuteMetricDefinitionQuery/5`, requestObject),
      this.HttpService.post(`Azure/${subscriptionId}/Monitoring/ExecuteMetricDefinitionQuery/6`, requestObject),

    );
  }

  getAzureSubscription(id) {
    return this.HttpService.get(`Azure/${id}/GetSubscriptionById`, null);
  }

  getAllMetricAlerts(id) {
    return this.HttpService.get(`Azure/${id}/Monitoring/AllMetricAlerts`, null);
  }

  getActivityLogAlerts(id) {
    return this.HttpService.get(`Azure/${id}/Monitoring/ActivityLogAlerts`, null);
  }

  removeAzureSubscription(azureSubscription) {
    return this.HttpService.delete(`Azure/Subscriptions`, null, { body: [azureSubscription]});
  }

  checkVmLicense(vmId, successFunction, errorFunction) {
    return new (Promise as any)((resolve, reject) => {
      this.getLicenseInstances().subscribe(
        (licenseInstances: Array<any>) => {
          console.log('license Instances = ', licenseInstances);
          if (licenseInstances.indexOf(vmId) > -1) {
            resolve();
            successFunction();
          } else {
            errorFunction();
          }
        }
      );
    });
  }

  checkAzureLicense(successFunction, errorFunction) {
    return new (Promise as any)((resolve, reject) => {
      this.getLicense().subscribe(
        (license: any) => {
          // check here
        }
      );
    });
  }

  getAzureSubscriptionResourceGroups(id) {
    return this.HttpService.get(`Azure/${id}/ResourceGroups`, null);
  }
  getAzureSubscriptionNetworkInterfaces(id, group) {
    return this.HttpService.get<any[]>(`Azure/${id}/NetworkInterfaces/${group}`, null);
  }
  getAzureVmSettings(id, group, rid) {
    var args =
    {
      subscriptionId: id,
      resourceGroupName: group,
      resourceId: rid
    };
    return this.HttpService.post<CreateAzureVMSettings>(`Azure/VmNetworkSettings`, args);
  }
  getAvailabilitySets(id, group) {
    return this.HttpService.get<any[]>(`Azure/${id}/AvailabilitySets/${group}`, null);
  }
  getAzureSubscriptionServices(id) {
    return this.HttpService.get(`Azure/${id}/AzureServices`, null);
  }
  gettAzureSubscriptionResources(id) {
    return this.HttpService.get(`Azure/${id}/Resources`, null);
  }

  getsAzureSubscriptionOperationsMetadata(id) {
    return this.HttpService.get(`Azure/${id}/OperationsMetadata`, null);
  }
  getActionGroups(id) {
    return this.HttpService.get(`Azure/${id}/Monitoring/ActionGroups`, null);
  }
  getAzureSubscriptionResourceGroupResources(id, rgName) {
    return this.HttpService.get(`Azure/${id}/Resources/${rgName}`, null);
  }

  getResourceMetrics(id, resourceId) {
    return this.HttpService.get(`Azure/${id}/Monitoring/MetricDefinitions/${encodeURIComponent(resourceId)}`, null);
  }
  getAzureSubscriptionResourceGroupAlert(id, resourceGroup) {
    return this.HttpService.get(`Azure/${id}/Monitoring/AlertRules/${resourceGroup}`, null).pipe(
      catchError((error: any) => {
        console.log('getAzureSubscriptionResourceGroupAlert error = ', error)
        return EMPTY;
      })
    );
  }

  collectTagsPairs(vms) {
    let vmTags: Array<string> = vms.map(vm => vm.TagItems);
    let pairs: any = vmTags.map(tag => tag ? tag.split(',').map(a => a.split('=')) : null);
    pairs.forEach((pair, index) => {
      if (!pair) return;
      vms[index].tagValues = pair.reduce((acc, current) => {
        acc[current[0]] = current[1];
        return acc;
      }, {});
      vms[index].tagValuesArray = pair.reduce((acc, current) => {
        acc.push({ key: current[0], value: current[1] });
        return acc;
      }, []);
    });
    console.log('pairs = ', pairs);
    return pairs.filter(a => a).flat();
  }

  editTags(vmId, data) {
    return this.HttpService.post(`Azure/EditTags/${vmId}`, data);
  }

  createResourceGroup(data, subscriptionId, name) {
    return this.HttpService.post(`Azure/${subscriptionId}/CreateResourceGroup/${name}`, data);
  }

  getSizesByRegion(subscriptionId, region) {
    return this.HttpService.get(`Azure/${subscriptionId}/VirtualMachineSizesByRegion/${region}`, null);
  }

  getResourceGroupStores(subscriptionId, rg) {
    return this.HttpService.get(`Azure/${subscriptionId}/Stores/${rg}/`, null);
  }

  getStoreStorageKeys(subscriptionId, storeId) {
    return this.HttpService.get(`Azure/${subscriptionId}/StorageKeys/${encodeURIComponent(storeId)}/`, null);
  }

  checkAzureSubscription(data) {
    return this.HttpService.post(`Azure/Subscriptions/List`, data);
  }

  getPublishers(subscriptionId, location) {
    return this.HttpService.get(`Azure/${subscriptionId}/Publishers/${location}`, null);
  }

  getPublisherOffers(subscriptionId, location, publisher) {
    return this.HttpService.get(`Azure/${subscriptionId}/PublisherOffers/${location}/${publisher}`, null);
  }

  createAzureVm(data, subscriptionId) {
    return this.HttpService.post(`Azure/${subscriptionId}/CreateVM`, data);
  }
  editAzureVm(data, vmId) {
    return this.HttpService.post(`Azure/EditVM/${vmId}`, data);
  }

 //  /Azure/EditVM / de79892d - eabc - 432b - a34c - c6ddc9895e91
  importHyperVVm(data) {
    return this.HttpService.post(`Azure/Convert`, data);
  }

  removeAzureVm(id) {
    return this.HttpService.post(`Azure/RemoveVM/${id}`, null);
  }

  getAzureVmDisks(id) {
    return this.HttpService.get(`Azure/GetAllDisksForVirtualMachine/${id}`, null);
  }
  GetPopularImages() {
    return this.HttpService.get(`Azure/GetPopularImages`, null);
  }
  createAzureSubscription(data) {
    return this.HttpService.patch(`Azure/Subscriptions`, data);
  }

  getLicense() {
    return this.HttpService.get(`core/license/Azure`, null);
  }

  getLicenseInstances() {
    return this.HttpService.get(`core/license/Azure/instances`, null);
  }

  setLicenseInstances(data) {
    return this.HttpService.put(`core/license/Azure`, data);
  }

  startAzureVm(id) {
    return this.HttpService.post(`Azure/GenericVMOperation/${id}/Start`, null);
  }

  stopAzureVm(id) {
    return this.HttpService.post(`Azure/GenericVMOperation/${id}/Stop`, null);
  }

  restartAzureVm(id) {
    return this.HttpService.post(`Azure/GenericVMOperation/${id}/Restart`, null);
  }

  deallocateAzureVm(id) {
    return this.HttpService.post(`Azure/GenericVMOperation/${id}/Deallocate`, null);
  }

  // replication

  getReplicatedItems(id, data) {
    return this.HttpService.post<any>(`Azure/${id}/Replication/ReplicatedItems`, data);
    //let ds = new Observable<any>(sub => {
    //  this.HttpService.post<any>(`Azure/${id}/Replication/ReplicatedItems`, data).subscribe(items => {
    //    Helper.deserializeJSONRefs(items, 50);
    //    sub.next(items);
    //  });
    //});
    //return ds;
 
  }

  getRecoveryVaults(id) {
    return this.HttpService.get(`Azure/${id}/Replication/RecoveryVaults`, null);
  }

  getRecoveryPoints(id, data) {
    return this.HttpService.post(`Azure/${id}/Replication/RecoveryPoints`, data);
  }

  resynchronizeReplication(id, data) {
    return this.HttpService.post(`Azure/${id}/ResynchronizeReplication`, data);
  }

  plannedFailover(id, data) {
    return this.HttpService.post(`Azure/${id}/PlannedFailover`, data);
  }

  unplannedFailover(id: any, data: UnplannedFailoverMessage) {
    return this.HttpService.post(`Azure/${id}/UnplannedFailover`, data);
  }

  TestFailover(id: any, data: TestFailoverMessage) {
    return this.HttpService.post(`Azure/${id}/TestFailover`, data);
  }

  changeRecoveryPoint(id: any, data: ChangeRecoveryPointMessage) {
    return this.HttpService.post(`Azure/${id}/ChangeRecoveryPoint`, data);
  }
  testFailoverCleanup(id, data) {
    return this.HttpService.post(`Azure/${id}/TestFailoverCleanup`, data);
  }

  getVirtualNetworks(id, resourceGroupName, location = null) {
    // hypervSite.location is usually undefined
    if (location != null)
      return this.HttpService.get(`Azure/${id}/VirtualNetworks/${resourceGroupName}/${location}`, null);
    else
      return this.HttpService.get(`Azure/${id}/VirtualNetworks/${resourceGroupName}`, null);
  }

  disableReplication(id, softRemove, data) {
    return this.HttpService.post(`Azure/${id}/DisableReplication/${softRemove}`, data);
  }

  commitFailover(id, data) {
    return this.HttpService.post(`Azure/${id}/CommitFailover`, data);
  }

  completeMigration(id, data) {
    return this.HttpService.post(`Azure/${id}/CompleteMigration`, data);
  }

  reverseReplication(id, data) {
    return this.HttpService.post(`Azure/${id}/ReverseReplication`, data);
  }

  createOrUpdateActionGroup(subscriptionId, rg, data) {
    return this.HttpService.post(`Azure/${subscriptionId}/Monitoring/CreateOrUpdateActionGroup/${rg}`, data);
  }
  createOrUpdateMetricAlert(subscriptionId, rg, data) {
    return this.HttpService.post(`Azure/${subscriptionId}/Monitoring/CreateOrUpdateMetricAlert/${rg}`, data);
  }

  createOrUpdateActivityLogAlert(subscriptionId, rg, data) {
    return this.HttpService.post(`Azure/${subscriptionId}/Monitoring/CreateOrUpdateActivityLogAlert/${rg}`, data);
  }
  
 

  deleteAlerts(id, data) {
    return this.HttpService.delete(`Azure/${id}/Monitoring/DeleteAlerts`, null, {
      body: data
    });
  }

  disableAlerts(id, data) {
    return this.HttpService.post(`Azure/${id}/Monitoring/DisableMetricAlerts`, data);
  }
  getHyperVSites(id, resourceGroupName, vaultName) {
    return this.HttpService.get(`Azure/${id}/Replication/HyperVSites/${resourceGroupName}/${vaultName}`, null);
  }

  getStores(id, resourceGroupName, regionName = null) {
    // hypervSite.location is usually undefined
    if (regionName != null)
      return this.HttpService.get(`Azure/${id}/Stores/${resourceGroupName}/${regionName}`, null);
    else
      return this.HttpService.get(`Azure/${id}/Stores/${resourceGroupName}`, null);
  }

  getSubnets(id, resourceGroupName, virtualNetworkName) {
    return this.HttpService.get(`Azure/${id}/Subnets/${resourceGroupName}/${virtualNetworkName}`, null);
  }

  getProtectableItems(id, resourceGroupName, vaultName, fabricName) {
    return this.HttpService.get(`Azure/${id}/Replication/ProtectableItems/${resourceGroupName}/${vaultName}/${fabricName}`, null);
  }

  replicateHyperVVMToAzure(id, data) {
    return this.HttpService.post(`Azure/${id}/ReplicateHyperVVMToAzure`, data);
  }
}

@Injectable({ providedIn: 'root' })
export class JobNotificationService{
  constructor() {
    WebsocketService.Instance().subscribe(ws => {
      ws.on<Job[]>("Job.Added").subscribe(job => {
        this.jobsObserver.next(job);
      });
      ws.on<Job[]>("Job.Modified").subscribe(job => {
        this.jobsObserver.next(job);
      });
    });
  }
  private jobsObserver: BehaviorSubject<Job[]> = new BehaviorSubject([]);
  private replicationJobComlited: Observable<Job[]>;
   
  public get ReplicationJobComlited() {
    if (this.replicationJobComlited == null) {
      let that = this;
      this.replicationJobComlited = new Observable<Job[]>(sub => {
        let rjobsname: string[] = ["Resynchronize Replication", "Planned Failover", "Failover", "Test Failover", "Disable Replication", "Cleanup Test Failover", "Commit",
          "Complete Migration","Reverse Replication"];
        this.jobsObserver.subscribe(jobs => {
          let fjobs = jobs.filter(j => { return j.JobStatus > 2 && rjobsname.includes(j.Name) && j.Plugin== "Azure" });
          if (fjobs.length > 0)
            sub.next(fjobs);
        });
      });
    }
    return this.replicationJobComlited;
  }

}
