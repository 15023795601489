import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

//import './index.js';
//export function getBaseUrl() {
//  var el = document.getElementById('web-api-url');
//  return el.getAttribute('data-link');
//}

//const providers = [
//  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
//];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(/*providers*/).bootstrapModule(AppModule)
  .catch(err => console.log(err));
