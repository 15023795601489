import { Injectable } from '@angular/core';
import { CanLoad, CanActivate, Route, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpService } from '../services/http.service';
import { HttpClient } from '@angular/common/http';
import { CustomDate } from '../classes';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private http: HttpService) {
  }
  //canLoad(route: Route): boolean {
  //  let url: string = route.path;
  //  return true;
  //}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (HttpService.activationDone)
      return true;
    try {
      let token = localStorage["auth_token"],
        userName = localStorage["userName"],
        checkDate = CustomDate.parseDate(localStorage["token_date"]);

      if (userName) {
        HttpService.UserName = userName;
      }
      if (token != null && token.length > 0 && checkDate != null /*&& CustomDate.compare(checkDate.add(1, 'days'), CustomDate.Now) > 0*/) {
        HttpService.SetAuthorized(true);
        HttpService.activationDone = true;
        return true;
      }
      this.http.Logout();
      return false;
    }
    catch (error) {
      console.log(error);
      return false;
    }
  }
} 
