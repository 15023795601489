import { Component,  OnInit, ElementRef, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, AsyncValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { Observable, BehaviorSubject} from 'rxjs';
import { Helper } from '../../../../classes';
import { VInputModel } from '..';

@Component({
  selector: 'vselect-box',
  templateUrl: './ValidateionSelectBox.component.html'
})

export class ValidateionSelectBox implements OnInit, OnDestroy {
  @Output()
  public openChange = new EventEmitter<boolean>();
  @Input()
  get open() {
    return this._open;
  }
  @Input() readonly: boolean = false;
  set open(val) {
    if (this._open == val) return;
    this._open = val;
    this.openChange.emit(this._open );
  }
  private _open = false;
  @Output()
  public valueChange = new EventEmitter<any>();
  @Input()
  get value() {
    return this._value;
  }
  set value(val) {
    if (Helper.Compare(this._value, val)) return;
    this._value = val;
    this.valueChange.emit(this._value);
    if (val != null) {
      let name = '';
      if (this.keyExp != null) {
        let values = this.items.filter(i => i[this.keyExp] == val);
        name = values.length > 0 ? values[0][this.displayExp] : '';
      }
      else {
        name = val[this.displayExp];
      }
      this.data.FormControl.setValue(name);
    }
    else
      this.data.FormControl.setValue('');
     
  }
  //public get Text() {
  //  item[displayExp]
  //}
  private _value: object;
  public setvalue(val) {
    if (this.keyExp != null)
      this.value = val[this.keyExp];
    else
      this.value = val;
  }
 

  @Input()
  get data() {
    return this._model;
  }
  set data(val) {
    if (val == null) return;
    this._model = val;
  }
  private _model = VInputModel.Defualt(); 
  @Input()
  get label() {
    return this.data.Label;
  }
  set label(val) {
    this.data.Label = val;
  }

  public get errors() {
    return this.data.errors;
  }

  public Validate() {
    this.data.Validate();
  }
  public hasError(key) {
    return this.data.FormControl.hasError(key);
  }
  public get Matcher() {
    return this.data.Matcher;
  }
  public get isdefault() {
    return this.data.isdefault;
  }
  public get FormControl() {
    return this.data.FormControl;
  }
  getdisplayExp(item: any) {
    if (item != null && item[this.displayExp] != null)
      return item[this.displayExp]
    return '';
  }
  @Input()
  get displayExp() {
    return this._displayExp;
  }
  set displayExp(val) {
    this._displayExp = val;
  }
  private _displayExp: string = 'Name';
  @Input()
  get keyExp() {
    return this._keyExp;
  }
  set keyExp(val) {
    this._keyExp = val;
  }
  private _keyExp: string=null;
  @Input()
  get items() {
    return this._items;
  }
  set items(val) {
    this._items = val;
  }
  private _items: any[]=[];
  constructor(private elRef: ElementRef) { }
    
  closehandler(e) {
    let root = this.elRef.nativeElement;
    if (!root.contains(e.target)) {
      this.open = false;
    }
  }
  ngOnInit(): void {
    window.addEventListener('click', this.closehandler.bind(this));
  }
  ngOnDestroy() {
    window.removeEventListener('click', this.closehandler);
  }  
}
