<pagemenu [breadcrumbs]="['Jobs']">
  <pagemenu-buttons class="right-block" [buttons]="[]"></pagemenu-buttons>
</pagemenu>
<grid-menu>
  <search-input class="gm-searchinput left-block margin-right16" (onsearch)="filter($event)"></search-input>
  <mat-form-field class="mat-filter left-block margin-right16  margin-top16 mat-datepicker-blue">
    <mat-label>Start time</mat-label>
    <input matInput #ref [matDatepicker]="picker" [(ngModel)]="startDate" (dateChange)="DataChange(ref.value)">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker startView="year" [startAt]="pdDate"></mat-datepicker>
  </mat-form-field>

  <div class="monitoring-chart-filter-container align-center margin-top16 left-block">
    <div ngbDropdown #pluginsDropdown="ngbDropdown" class="d-inline-block">
      <div ngbDropdownToggle class="bs-dropdown">
        <div class="monitoring-chart-filter-value ellipsis">
          Plugins: {{selectedplugins}}
        </div> 
        <span class="blue-arrow"
              [ngClass]="pluginsDropdown.isOpen() ? 'blue-arrow-up' : 'blue-arrow-down'">
        </span>
      </div>
      <div ngbDropdownMenu class="dropdown-menu-with-padding">
        <div class="ellipsis" *ngFor="let plugins of plugins;" (click)="$event.stopPropagation();selectedplugins=plugins.Name; pluginsDropdown.close();">
          {{plugins.Name}}
        </div>
      </div>
    </div>
  </div>
  <div class="monitoring-chart-filter-container align-center  margin-top16 left-block">
    <div ngbDropdown #typesDropdown="ngbDropdown" class="d-inline-block">
      <div ngbDropdownToggle class="bs-dropdown">
        <div class="monitoring-chart-filter-value ellipsis">
          Job Types: {{selectedjobtypestext}}
        </div>
        <span class="blue-arrow"
              [ngClass]="typesDropdown.isOpen() ? 'blue-arrow-up' : 'blue-arrow-down'">
        </span>
      </div>
      <div ngbDropdownMenu class="dropdown-menu-with-padding">
        <div class="ellipsis" *ngFor="let type of jobtypes;" (click)="$event.stopPropagation();selectedjobtypes=type.Code; typesDropdown.close();">
          {{type.Name}}
        </div>
      </div>
    </div>
  </div>
 
</grid-menu>
<div style="height: calc(100vh - 141px);">
  <ngx-datatable class="gray-scrollbar-datatable ngx-tablecommon datagrid-pointer"
                 width="100%"
                 [rows]="dataSource$"
                 [headerHeight]="headerHeight"
                 [footerHeight]="0"
                 [rowHeight]="rowHeight"
                 [columnMode]="ColumnMode.force"
                 [scrollbarV]="true"
                 [scrollbarH]="false"
                 [loadingIndicator]="isLoading"
                 (scroll)="onScroll($event.offsetY)"
                 [externalSorting]="true"
                 (sort)="onSort($event)"
                 [sorts]="[{ prop: 'Started', dir: 'desc' }]"
                 [selected]="selected"
                 [selectionType]="SelectionType.single"
                 (activate)="onActivate($event)"
                 [selectAllRowsOnPage]="false">

    <ngx-datatable-column name="Name" prop="Name" sortable="true" width="200"></ngx-datatable-column>
    <ngx-datatable-column name="Description" prop="Description" sortable="true" width="200"></ngx-datatable-column>
    <ngx-datatable-column name="User name" prop="UserName" sortable="true" width="200"></ngx-datatable-column>
    <ngx-datatable-column name="Status" prop="JobStatus" sortable="true">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ getStatus(row) }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Context object" prop="CustomData" sortable="true"></ngx-datatable-column>

    <ngx-datatable-column name="Started" prop="Started" sortable="true">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ getDateString(row.Started) }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Finished" prop="Finished" sortable="true">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ getDateString(row.Finished) }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Progress" prop="Progress" sortable="true">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.Progress}} %
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
 
  <simple-dialog [(dialog)]="dialog" [(visible)]="dialogvisible">
    <summary-component class="ellipsis-initial" [data]="SummaryInfo"></summary-component>
    <ngx-datatable  [ngClass]="ShowDetails? '' : 'hide'"
                   class="gray-scrollbar-datatable ellipsis-datatable striped-datatable border datagrid-pointer margin-top16"
                   width="100%"
                   [rows]="jobsdetails"
                   [headerHeight]="headerHeight"
                   [rowHeight]="rowHeight"
                   [footerHeight]="0"
                   [columnMode]="ColumnMode.force"
                   [scrollbarV]="true"
                   [scrollbarH]="false"
                   [sorts]="[{ prop: 'Started', dir: 'desc' }]"
                   [selectionType]="SelectionType.single"
                   [selectAllRowsOnPage]="false">
      <ngx-datatable-column name="Name" prop="Name"></ngx-datatable-column>
      <ngx-datatable-column name="Started">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ getDateString(row.Started) }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Progress" prop="Progress"></ngx-datatable-column>
      <ngx-datatable-column name="Finished" prop="Finished">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ getDateString(row.Finished) }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Status" prop="Status"></ngx-datatable-column>

    </ngx-datatable>
  </simple-dialog>
