import { Component,  OnInit,   ElementRef, Input, ChangeDetectorRef } from '@angular/core';
import { PageMenuItem } from '../..';

@Component({
  selector: 'rowactions-menu',
  templateUrl: './RowActionsMenu.component.html'
})

export class RowActionsMenu implements OnInit {
  public isShow = false;
  
  _title='';
  @Input()
  get title(): string {
    return this._title;
  }
  set title(val: string) {
    this._title=val;
  }
  
  _items: PageMenuItem[][];
  @Input()
  get items(): PageMenuItem[][] {
    return this._items;
  }
  set items(val: PageMenuItem[][]) {
    this._items = val;
  
  }
  public Id: string;
  index: number=-1;
 
  public munushow() {
    let p = document.querySelector('.cdk-overlay-pane');
    if (p)
      p.classList.add('rowactions-menu');
  }
  constructor(private elRef: ElementRef, private changeDetectorRef: ChangeDetectorRef) {

  }
  
  ngOnInit(): void {
    if (this.index = -1) {
      let siblings = document.querySelectorAll('.row-actionsbtn');
      this.index = siblings.length;
    }
    this.Id = 'row-actionsbtn' + this.index;
  }


  public show() {
    this.isShow = true;
    this.changeDetectorRef.detectChanges();
   
  }
  public close() {
    this.isShow = false; this.changeDetectorRef.detectChanges();
  }
  public repaintScroll() {
   
  }
}
