import { Injectable, Input, EventEmitter, Output } from '@angular/core';
import { AppRoutes } from '../app.routes';
import { Router, Event, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { InfrastructureMenuItemNavigationData, InfMenuItem, MenuSettings, Helper, Guid } from '../classes';
import { NavTreeFactory } from '.';


@Injectable({ providedIn: 'root' })
export class MenuService {
  public leftMenu: any; //left menu items
  public currentRoute: any;
  public id = Guid.NewGuid().ToString();
  private secondaryMenuVisible = true;
  private secondaryMenuVisiblePerm = true;
  public static data = new BehaviorSubject<InfrastructureMenuItemNavigationData>(new InfrastructureMenuItemNavigationData());
  public static treenode = new BehaviorSubject<{ treename: string, nodeid: string, showchildren?: boolean }>({ treename: '', nodeid: '' });
  private static childrenData = new BehaviorSubject<{ [key: string]: any; }>({});
  private static settings = new BehaviorSubject<MenuSettings>(new MenuSettings());
  constructor(router: Router, private treeFactory: NavTreeFactory) {
    this.currentRoute = null;
    this.leftMenu = AppRoutes.leftMenu;
    let ctx = new InfrastructureMenuItemNavigationData();
    ctx.up = '';
    ctx.data = new BehaviorSubject<InfMenuItem[]>(new Array<InfMenuItem>()).asObservable();
    let s = new MenuSettings();
    router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationEnd) {
        this.setActiveRoute();
      }
      if (routerEvent instanceof NavigationStart) {
        this.SetTreeNode({ treename: '', nodeid: '' });
        this.SetData(ctx);
        this.SetSetings(s);
      }
    });
 
  }

  isSecondaryMenuOpen() {
    return this.smVisiblepermanent ? this.secondaryMenuVisiblePerm:this.secondaryMenuVisible;
  }
  smVisiblepermanent = false;
  toggleSecondaryMenu(opened, permanent = false) {
    this.smVisiblepermanent = permanent;
    if (permanent)
      this.secondaryMenuVisiblePerm = opened;
    else
    this.secondaryMenuVisible = opened;
    Helper.ESresize();
  }

  
  public setActiveRoute() {
    let path = window.location.hash.replace("#/", "");
    this.nullifyRoutes(false);
    for (var i in this.leftMenu) {
      if (path.split("/")[0] == this.leftMenu[i].path) {
        this.currentRoute = this.leftMenu[i];
      }
      if (this.leftMenu[i].data["children"] != null) {
        for (var j in this.leftMenu[i].data["children"]) {
          let childPath = this.leftMenu[i].path + "/" + this.leftMenu[i].data["children"][j].path;
          if (path.split("/").slice(0, 2).join("/") == childPath) {
            this.currentRoute = this.leftMenu[i];
            this.leftMenu[i].data["children"][j].data["active"] = true;
          }
          else
            this.leftMenu[i].data["children"][j].data["active"] = false;
        }
      }
    }
    this.currentRoute = this.currentRoute || this.leftMenu[0];
    if (this.currentRoute && this.currentRoute.data) {
      this.currentRoute.data["active"] = true;
    }
    this.smVisiblepermanent = false;
 //   console.log("this.currentRoute = ", this.currentRoute);

  }

  private nullifyRoutes(onlyChilds: Boolean) {
    for (var i in this.leftMenu) {
      if (!onlyChilds) {
        this.leftMenu[i].data["active"] = false;
      }
      if (this.leftMenu[i].data["children"] != null) {
        for (var j in this.leftMenu[i].data["children"]) {
          this.leftMenu[i].data["children"][j].data["active"] = false;
        }
      }
    }
  }

  public SetSetings(s: MenuSettings) {
    MenuService.settings.next(s);
  }
  public Data() {
    return MenuService.data.asObservable();
  }

  public ChildrenData() {
    return MenuService.childrenData.asObservable();
  }
  public Setttings() {
    return MenuService.settings.asObservable();
  }
  public TreeNode(): Observable<{ treename: string, nodeid: string, showchildren?: boolean}> {
    return MenuService.treenode.asObservable();
  }

  public SetData(data: InfrastructureMenuItemNavigationData) {
    MenuService.data.next(data);
  }
  public SetTreeNode(data: { treename: string, nodeid: string, showchildren?: boolean, refresh?:boolean }) {
    MenuService.treenode.next(data);
  }
  public get imeges() { return this.treeFactory.imeges; }
  public get routes() { return this.treeFactory.routes; }
  public getTree(routname: string) { return this.treeFactory.getTree(routname); }
  public GetBreadCrumps(data: { treename: string, nodeid: string }) {
    return this.treeFactory.GetBreadCrumps(data.treename, data.nodeid);
  }

  public SetChildrenData(hiddenElements: Array<string> = [], suffix: string = '') {
    MenuService.childrenData.next({ hiddenElements, suffix });
  }

  public handleChildrenVisibility(hiddenElements: Array<string> = []) {
    for (let i in this.currentRoute.data["children"]) {
      let element = this.currentRoute.data["children"][i];
      element.data["hidden"] = hiddenElements.indexOf(element.path) != -1;
    }
  }

  public handleChildrenLinks(suffix: string = '') {
    for (let i in this.currentRoute.data["children"]) {
      this.currentRoute.data["children"][i].data["suffix"] = suffix || '';
    }
  }

}
