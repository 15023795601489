import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialAllModule } from '../../../material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuUserInfoComponent } from './MenuUserInfo/MenuUserInfo.component';
import { MenuBreadcrumbComponent } from './MenuBreadcrumb/MenuBreadcrumb.component';
import { JobsNotifierComponent } from './JobsNotifier/JobsNotifier.component';
import { RouterModule } from '@angular/router';
import { PageMenu } from './PageMenu/PageMenu.component';
import { PageMenuButtons } from './PageMenu/PageMenuButtons.component';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  imports: [
    CommonModule, FormsModule, RouterModule, ReactiveFormsModule, MaterialAllModule, NgbModule, ClickOutsideModule,
  ],
  declarations: [MenuUserInfoComponent, MenuBreadcrumbComponent, JobsNotifierComponent, PageMenu, PageMenuButtons],
  exports: [MenuUserInfoComponent, MenuBreadcrumbComponent, PageMenu, PageMenuButtons]
})
export class AppRootComponentsModule { }
