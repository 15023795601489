import { NgModule, Component } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MaterialAllModule } from './material.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InfrastructureMenu, AppJobs, LoginComponent } from './views/AppComponents'
//import { DataSourceComponent } from './views/AzureManagment/DataSource/DataSource.component';
import { GuacamoleBoardComponent } from './views/Guacamole/GuacamoleBoard/GuacamoleBoard.component';
//import { SwitchComponent } from './views/AzureManagment/Switch/Switch.component';
import { CommonComponentsModule } from './views/AppComponents/CommonComponents';
import { MenuService, AdministrationService } from './services';
import { AzureService } from './services';
import { AppRootComponentsModule } from './views/AppComponents/AppRootComponents/AppRootComponents.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
@NgModule({
  declarations: [
    LoginComponent,
    AppComponent,
    InfrastructureMenu,
    GuacamoleBoardComponent,
    AppJobs
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxDatatableModule,
    NgbModule,
    MaterialAllModule,
    MatFormFieldModule,
    ClickOutsideModule,
    CommonComponentsModule,
    AppRootComponentsModule
  ],
  providers: [
    MenuService,
    AdministrationService,
    AzureService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
