import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollBar, ModalWindow, WizardComponent, SearchInput, DialogComponent, SearchBox, GroupBox, VInput, TabsMenuComponent} from '.';
import { DropDown } from './DropDown/DropDown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialAllModule } from '../../../material.module';
import { RowActionsMenu } from './Menu/RowActionsMenu/RowActionsMenu.component';
import { GridMenu } from './Menu/GridMenu/GridMenu.component';
import { GridSettings } from './Menu/GridSettings/GridSettings.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinEdit } from './SpinEdit/SpinEdit.component';
import { SummaryComponent } from './Summary/Summary.component';
import { ValidateionSelectBox } from './VInput/ValidateionSelectBox.component';
import { VPassword } from './VInput/VPassword.component';
import { SubMenu } from './Menu/SubMenu.component';
import { DropdownMenu } from './Menu/DropdownMenu/DropdownMenu.component';
import { DynamicTreeNodeComponent } from './DynamicTree/DynamicTreeNode.component';
import { DynamicTree } from './DynamicTree/DynamicTree.component';
import { RouterModule } from '@angular/router';
import { VDateTimePicker } from './VInput/VDateTimePicker';


@NgModule({
  imports: [
    CommonModule, RouterModule, FormsModule, ReactiveFormsModule, MaterialAllModule,  NgbModule
  ],
  declarations: [ScrollBar, ModalWindow, SubMenu, WizardComponent, DialogComponent, DropDown, ValidateionSelectBox, DropdownMenu,
    SearchInput, SearchBox, GroupBox, SearchInput, GridMenu, RowActionsMenu, GridSettings, SpinEdit, SummaryComponent, VInput, VPassword, VDateTimePicker, DynamicTreeNodeComponent, DynamicTree, TabsMenuComponent],
  exports: [
    ScrollBar, ModalWindow, WizardComponent, DialogComponent, DropDown, ValidateionSelectBox, SubMenu, DropdownMenu,
    SearchInput, SearchBox, GroupBox, SearchInput, GridMenu, RowActionsMenu, GridSettings, SpinEdit, SummaryComponent, VInput, VPassword, VDateTimePicker, DynamicTree, TabsMenuComponent
  ]
})
export class CommonComponentsModule { }
