import { Component, Injectable, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'search-input',
  templateUrl: './SearchInput.component.html'
})

export class SearchInput{
  public word: string;
  _placeholder: string = 'Search';
  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(val: string) {
    this._placeholder = val;
  }

  @Input()
  set search(val: string) {
    if (this._search != val) {
      this._search = val;
      this.word = this._search;
      this.onsearch.emit(this._search);
    }
  }
  get search(): string {
    return this._search;
  }
  _search: string = '';

  @Output()
  public onsearch = new EventEmitter<string>();

  constructor() { }

  keypress($event: any) {
    if ($event.keyCode == 13) {
      this.filter();
    }
  }
  public oninput(event: any) {
    this.word = event.target.value;
  }

  public filter() {
    this.search = this.word;
  }
}

