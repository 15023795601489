<div *ngIf="authorized && menu.isSecondaryMenuOpen()" class="root-menu-right">
  <div class="infrastructuremenu">
    <div class="infrastructuremenu-top">
      {{getSubTitle()}} 
      <span class="rmcollaser" (click)="hideSecondaryMenu()"></span>
    </div>
    <div class="secondlevel-menu" *ngIf="menu.currentRoute!=null && menu.currentRoute.data!=null && menu.currentRoute.data['children'] && !menu.currentRoute.data['secondLevelMenuHidden']">
      <ul>
        <li class="infrastructuremenu-item" *ngFor="let route of menu.currentRoute.data['children']"
            [ngClass]="{'smenu-active': route.data.active}">
          <a [ngClass]="route.data.icon" class="ellipsis" id="{{route.path}}" *ngIf="!route.data.hidden" [routerLink]="menu.currentRoute.path + '/' + route.path + (route.data.suffix || '')" data-toggle="tab">
            {{route.data.title}}
          </a>
        </li>
      </ul>
    </div>
    <div class="infrastructure-menu-lists" *ngIf="menu.currentRoute!=null && menu.currentRoute.data!=null &&   isadminplugin">
      <div>
        <!--<span (click)="toggleInfrastructureMenu()"
        class="align-center cursor-pointer blue-arrow infrastructure-menu-name"
        [ngClass]="{'blue-arrow-down infrastructure-menu-opened-name': showInfrastructure, 'blue-arrow-right': !showInfrastructure}">
    Infrastructure
  </span> *ngIf="showInfrastructure"-->
        <div>
          <div class="infrastructure-menu-search infrastructure-menu-search-small-margin">
            <search-input (onsearch)="search($event)"></search-input>
            <a class="link-back ellipsis" *ngIf="uplink" [routerLink]="uplink">{{upname}}</a>
          </div>
          <div [ngStyle]="{'height':'calc(100% - '+ delta + 'px)'}">
            <div class="infrastructuremenu-content gray-scrollbar">
              <table cdk-table [dataSource]="dataSource$" class="infrastructuremenu-list">
                <tr cdk-row *cdkRowDef="let row; columns: displayedColumns$.value"></tr>
                <ng-container cdkColumnDef="common">
                  <td class="infrastructuremenu-item" [ngClass]="{'infrastructuremenu-item-big-padding': uplink}" cdk-cell *cdkCellDef="let row">
                    <a class="{{row.type}} ellipsis" [routerLink]="row.link">{{row.name}}</a>
                  </td>
                </ng-container>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="infrastructure-menu-lists" *ngIf="menu.currentRoute!=null && menu.currentRoute.data!=null  && isadminplugin">
      <div>
        <span (click)="toggleLogicalGroupsMenu()"
              class="align-center cursor-pointer blue-arrow infrastructure-menu-name"
              [ngClass]="{'blue-arrow-down infrastructure-menu-opened-name': showLogicalGroups, 'blue-arrow-right': !showLogicalGroups}">
          Logical Groups
        </span>
        <div *ngIf="showLogicalGroups">
          <div class="infrastructure-menu-search">
            <search-input (onsearch)="search($event)"></search-input>
            <a class="link-back ellipsis" *ngIf="uplink" [routerLink]="adminuplink">{{adminupname}}</a>
          </div>
          <div [ngStyle]="{'height':'calc(100% - '+ delta + 'px)'}">
            <div class="infrastructuremenu-content gray-scrollbar">
              <table cdk-table [dataSource]="dataSource$" class="infrastructuremenu-list">
                <tr cdk-row *cdkRowDef="let row; columns: displayedColumns$.value"></tr>
                <ng-container cdkColumnDef="common">
                  <td class="infrastructuremenu-item" [ngClass]="{'infrastructuremenu-item-big-padding': uplink}" cdk-cell *cdkCellDef="let row">
                    <a class="{{row.type}} ellipsis" [routerLink]="row.link">{{row.name}}</a>
                  </td>
                </ng-container>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>-->
    <div class="infrastructure-menu-lists" *ngIf="menu.currentRoute!=null && menu.currentRoute.data!=null && !isadminplugin">
      <div>
        <div class="infrastructure-menu-search infrastructure-menu-search-small-margin">
          <search-input (onsearch)="search($event)"></search-input>
          <a class="link-back ellipsis" *ngIf="uplink" [routerLink]="uplink">{{upname}}</a>
        </div>
        <div [ngStyle]="{'height':'calc(100% - '+ delta + 'px)'}">
          <div class="infrastructuremenu-content gray-scrollbar">
            <div class="infrastructuremenu-list">
              <p class="infrastructuremenu-item" *ngFor="let node of nodes" [ngClass]="{'infrastructuremenu-item-big-padding': uplink}">
                <a class="{{getimeges(node)}} ellipsis" [routerLink]="getnodelink(node)">{{node.name}}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
