import { ContextObject } from '../responsetype';
import { Guid } from '..';

export class NetworkAdapterWithSwitch{

  public Id:string;
  public Name: string;
  public SwitchName: string;// => NetworkAdapter.SwitchName;
  public NetworkAdapter: VMNetworkAdapter;
  public NewNetworkSwitch: VirtualNetworkSwitch;
}

export class VirtualNetworkSwitch {
  Id: string;
  Name: string;
  Description: string;
  ObjectType: string;
  Parent_Id: string;
  Shared: boolean;
  InternalState: number;
  SwitchId: string;
  SwitchType: number;
  Notes: string;
  AllowManagementOS: boolean;
  NetAdapterInterface: string;
  NetAdapterInterfaceDescription: string;
  ComputerName: string;
  VlanId: number;
  IsSdnSwitch: boolean;
  Selected: boolean = false;
  public static Clone(that: VirtualNetworkSwitch) {
    var res = new VirtualNetworkSwitch();
    res.Id = that.Id;
    res.Name = that.Name;
    res.Description = that.Description;
    res.Parent_Id = that.Parent_Id;
    res.ObjectType = that.ObjectType;
    res.Shared = that.Shared;
    res.InternalState = that.InternalState;
    res.SwitchId = that.SwitchId;
    res.SwitchType = that.SwitchType;
    res.Notes = that.Notes;
    res.AllowManagementOS = that.AllowManagementOS;
    res.NetAdapterInterface = that.NetAdapterInterface;
    res.NetAdapterInterfaceDescription = that.NetAdapterInterfaceDescription;
    res.ComputerName = that.ComputerName;
    res.VlanId = that.VlanId;
    res.IsSdnSwitch = that.IsSdnSwitch;
    return res;
  }
}
export class NetAdapter {
  Name: string;
  DeviceId: string;
  PermanentAddress: string;
  InterfaceDescription: string;
}
export enum OnOffState { On, Off }
export enum MirroringMode {
  None,
  Destination,
  Source
}
export class NetworkAdapterBase 
{
  public Id: string;
  public  SdnInterfaceId: string;
  public NetworkInterface: any;
  public SdnEndpointId: string;
  public SdnSubnetRef: string;
  public DynamicSdnMacAddressEnabled: boolean;
  public SdnMacAddress: string;
  public DynamicSdnIpAddressEnabled: boolean;
  public SdnIpAddress: string;
  public SdnDnsAddress: string;
  public MacAddressPoolId: string;
  public DynamicMacAddressEnabled?: boolean;
  public MacAddress: string;
  public MacAddressSpoofing: OnOffState;
  

  public IsSameSdnSettings(other: NetworkAdapterBase)
  {
    return this.SdnEndpointId == other.SdnEndpointId && this.SdnInterfaceId == other.SdnInterfaceId &&
      this.SdnSubnetRef == other.SdnSubnetRef &&
      this.DynamicSdnMacAddressEnabled == other.DynamicSdnMacAddressEnabled && this.SdnMacAddress == other.SdnMacAddress &&
      this.DynamicSdnIpAddressEnabled == other.DynamicSdnIpAddressEnabled && this.SdnIpAddress == other.SdnIpAddress &&
      this.SdnDnsAddress == other.SdnDnsAddress;
  }

  public IsSameSdnEndpointSettings(other: NetworkAdapterBase)
  {
    return this.SdnEndpointId == other.SdnEndpointId;
  }

  public  ClearSdnSettings()
  {
    this.SdnEndpointId = "00000000-0000-0000-0000-000000000000";
    this.SdnInterfaceId = '';
    this.SdnSubnetRef = '';
    this.DynamicSdnMacAddressEnabled = true;
    this.SdnIpAddress = '';
    this.DynamicSdnIpAddressEnabled = true;
    this.SdnDnsAddress = '';
  }

  
}
export class VMNetworkAdapter implements NetworkAdapterBase{
  public tId: string;
  public Id: string;
  public SdnInterfaceId: string;
  public NetworkInterface: any;
  public SdnEndpointId: string;
  public SdnSubnetRef: string;
  public DynamicSdnMacAddressEnabled: boolean;
  public SdnMacAddress: string;
  public DynamicSdnIpAddressEnabled: boolean;
  public SdnIpAddress: string;
  public SdnDnsAddress: string;
  public MacAddressPoolId: string;
  public DynamicMacAddressEnabled?: boolean;
  public MacAddress: string;
  public MacAddressSpoofing: OnOffState;
  public NetworkConfigurationContext: GuestNetworkAdapterConfigurationContext;

  public IsSameSdnSettings(other: NetworkAdapterBase) {
    return this.SdnEndpointId == other.SdnEndpointId && this.SdnInterfaceId == other.SdnInterfaceId &&
      this.SdnSubnetRef == other.SdnSubnetRef &&
      this.DynamicSdnMacAddressEnabled == other.DynamicSdnMacAddressEnabled && this.SdnMacAddress == other.SdnMacAddress &&
      this.DynamicSdnIpAddressEnabled == other.DynamicSdnIpAddressEnabled && this.SdnIpAddress == other.SdnIpAddress &&
      this.SdnDnsAddress == other.SdnDnsAddress;
  }

  public IsSameSdnEndpointSettings(other: NetworkAdapterBase) {
    return this.SdnEndpointId == other.SdnEndpointId;
  }

  public ClearSdnSettings() {
    this.SdnEndpointId = "00000000-0000-0000-0000-000000000000";
    this.SdnInterfaceId = '';
    this.SdnSubnetRef = '';
    this.DynamicSdnMacAddressEnabled = true;
    this.SdnIpAddress = '';
    this.DynamicSdnIpAddressEnabled = true;
    this.SdnDnsAddress = '';
  }

  public static  NetworkAdapterMaxCount = 8;
  public static NetworkAdapterLegacyMaxCount = 4;
 
  public Name: string;
  public VmId: string;
  public SwitchId: string;
  public SwitchName: string;
  public IsLegacy?: boolean = false;
  public VlanEnabled?: boolean;
  public AccessVlanId?: number;
  public BandwidthEnabled?: boolean;
  public MinimumBandwidth?: number;
  public MaximumBandwidth?: number;
  public VmqWeight?: number;
  public IPsecTaskOffloading?: boolean;
  public IPsecOffloadMaxSA?: number;
  public IovWeight?: number;
  public DhcpGuard?: OnOffState= OnOffState.Off;
  public RouterGuard?: OnOffState= OnOffState.Off;
  public ClusterMonitored: boolean;
  public PortMirroringMode?: MirroringMode = MirroringMode.None;
  public AllowTeaming?: OnOffState= OnOffState.Off;
  public DeviceNaming?: OnOffState= OnOffState.Off;
  public FailoverConfiguration =new VMNetworkAdapterFailoverConfiguration();//VMNetworkAdapterFailoverConfiguration
  public GuestNetworkAdapterConfiguration: GuestNetworkAdapterConfiguration;
}

 //public bool? IPsecTaskOffloading
	 //   {
  //get => _ipsecTaskOffloading;
  //set
  //{
  //  if (value == _ipsecTaskOffloading) return;
  //  _ipsecTaskOffloading = value;
  //  OnPropertyChanged();
  //  if (_ipsecTaskOffloading.HasValue && _ipsecTaskOffloading.Value && IPsecOffloadMaxSA == 0)
  //    IPsecOffloadMaxSA = 512;
  //  if (_ipsecTaskOffloading.HasValue && !_ipsecTaskOffloading.Value && IPsecOffloadMaxSA != 0)
  //    IPsecOffloadMaxSA = 0;
  //}
//public long? IPsecOffloadMaxSA
	 //   {
  //get => _ipsecOffloadMaxSa;
  //set
  //{
  //  if (value == _ipsecOffloadMaxSa) return;
  //  _ipsecOffloadMaxSa = value;
  //  OnPropertyChanged();
  //  IPsecTaskOffloading = _ipsecOffloadMaxSa != 0;
  //}
export enum MacAddressMode {
  Dynamic,
  Static,
  MacPool
}
export class IpSettings {
  public IpAddressOrigins: IpAddressOriginsEnum;
  public IpAddress: string;
  public Subnet: string;
  public DefaultGateway: string;
  public DnsPrimary: string;
  public DnsAlternate: string;
}
export enum IpAddressOriginsEnum {
  Unknown,
  Other,
  Static
}
export enum WmiProtocolIFType {
  None = 0,
  Other = 1,
  IPv4 = 4096,
  IPv6 = 4097,
  IPv4v6 = 4098
}
export class GuestNetworkAdapterConfiguration {
  public InstanceId: string;
  public DHCPEnabled: boolean = false;
  public IPAddressOrigins: IpAddressOriginsEnum[] = [];
  public IPAddresses: string[] = [];
  public Subnets: string[] = [];
  public DefaultGateways: string[] = [];
  public DNSServers: string[] = [];
  public WmiProtocolIFType: WmiProtocolIFType;
  public GuestNetworkAdapterConfigurationString: string;
  public static ConvertToString(obj: GuestNetworkAdapterConfiguration) {
    obj.GuestNetworkAdapterConfigurationString = null;
    obj.GuestNetworkAdapterConfigurationString = JSON.stringify(obj);
    obj.DHCPEnabled = false;
    obj.IPAddressOrigins = [];
    obj.IPAddresses = [];
    obj.Subnets = [];
    obj.DefaultGateways =[];
    obj.DNSServers = [];
  }
}
export class GuestNetworkAdapterConfigurationContext {
  public InstanceId: string;
  public Ipv4: IpSettings;
  public Ipv6: IpSettings;
  public DHCPEnabled: boolean;
  public ProtocolIFType: WmiProtocolIFType;
}

export class MacPoolObject extends ContextObject
{
  constructor() {
    super();
  }
  public StartMacAddress: number;
  public EndMacAddress: number;
  public MacAddressD: string;
  public static MacAddressRemoveSpaces(macAddressText: string) {
    return macAddressText.replace(" ", "").replace("-", "").replace(":", "");
  }
  public static StringToMacAddress(macAddressText: string)
  {
    let str = MacPoolObject.MacAddressRemoveSpaces(macAddressText);
    return macAddressText == null || macAddressText.length == 0
      ? 0 : parseInt(str);//Number.isSafeInteger() 
   //   macAddressText + 0;//: Convert.ToInt64(MacAddressRemoveSpaces(macAddressText).TrimStart('0'), 16);
  }
  //public static MacAddressToString(macAddress: number,  delimiter = ':')
  //{
  //  var macAddressString = $"{macAddress:X12}";
  //  if (delimiter == '\0')
  //    return macAddressString;
  //  var sb = new StringBuilder(macAddressString);
  //  for (var i = sb.Length - 2; i >= 2; i -= 2)
  //    sb.Insert(i, delimiter);
  //  return sb.ToString();
  //}
}

export class VMNetworkAdapterFailoverConfiguration {
  public IPv4Enabled?: boolean;
  public IPv6Enabled?: boolean;
  public IPv4Address: string;
  public IPv4SubnetMask: string;
  public IPv4DefaultGateway: string;
  public IPv4PreferredDNSServer: string;
  public IPv4AlternateDNSServer: string;
  public IPv6Address: string;
  public IPv6SubnetPrefixLength: string;
  public IPv6DefaultGateway: string;
  public IPv6PreferredDNSServer: string;
  public IPv6AlternateDNSServer: string;

}
export class SdnEndpoint {
  public Id: string;
  public Name: string;
  public Available: boolean;
  public AvailableLogicalNetworks: any[];
  public AvailableVirtualNetworks: any[];
  public AvailableNetworkInterfaces: any[];
  public AvailableNetworks: any[];
}

