import { Component,  OnInit,   ElementRef, Input, ChangeDetectorRef } from '@angular/core';
import { PageMenuItem } from '..';

@Component({
  selector: 'submenu',
  templateUrl: './SubMenu.component.html'
})

export class SubMenu {
  get visible() {
    return this.items && Array.isArray(this.items) && this.items.filter(c => c.Visible()).length > 0;
  }

  _items: PageMenuItem[] = new Array<PageMenuItem>();
  @Input()
  get items() {
    return this._items;
  }
  set items(val) {
    this._items = val;
  }
  
}
