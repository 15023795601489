import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { RouteNames, Helper, CustomDate } from '../classes';
import { filter, map, catchError } from 'rxjs/operators';
import { BehaviorSubject, of, throwError, Observable, EMPTY } from 'rxjs';

class AuthorizeWatcher {
  syncauth() {
    HttpService.Token = localStorage["auth_token"] || "";
    var Authorized = Helper.NotEmpty(HttpService.Token);
    if (Authorized != HttpService.Authorized)
      HttpService.SetAuthorized(Authorized);
  }

  constructor() {
    setInterval(this.syncauth, 1000);
    //setInterval(() => { console.log(Date.now()) }, 1000);
  }

}

@Injectable({ providedIn: 'root' })
export class HttpService {
  private static AuthorizeWatcher = new AuthorizeWatcher();
  static Token: string = localStorage["auth_token"] || "";
  static Authorized: boolean = Helper.NotEmpty(HttpService.Token);
  static authorizedObserver = new BehaviorSubject<boolean>(HttpService.Authorized);
  static isAdmin: Boolean;
  static GuacamoleCredentials = {};
  static currentUser = null;
  static GuacamoleToken = "";
  static UserName: string = "";
  static router: Router;
  static activationDone: boolean = false;
  static SetAuthorized(value: boolean) {
    HttpService.Authorized = value;
    HttpService.authorizedObserver.next(HttpService.Authorized);
  }
  constructor(private notificator: NotificationService, private router: Router, private http: HttpClient) {
    HttpService.router = router;
    HttpService.Token = localStorage["auth_token"];
    HttpService.authorizedObserver.subscribe(Authorized => {
      if (!HttpService.Authorized ) {
        this.navigate([RouteNames.Login]);
      }
    });
  }

  navigate(address) {
    this.router.navigate(address);
  }

  public getCurrentUser() {
    if (HttpService.currentUser)
      return Promise.resolve(HttpService.currentUser);
    return this.get('Core/User/Current', null).toPromise().then(res => {
    //  console.log("authserviceAauthservice", res);
      HttpService.currentUser = res || null;
      HttpService.isAdmin = res && res["IsGlobalAdmin"];
      return res;
    })
  }

  public static GetToken() {
    return new Promise<string>((resolve, reject) => {
      HttpService.authorizedObserver.subscribe(Authorized => {
        if (Authorized) {
          resolve(HttpService.Token);
        }
      });
    });
  }

  public Logout() {
    document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    localStorage.removeItem("auth_token");
    localStorage.removeItem("token_date");
    HttpService.Authorized = false;
    HttpService.Token = "";
    HttpService.UserName = "";
    HttpService.currentUser = null;
    HttpService.SetAuthorized(false);
  }

  public login(data: object, remmber: boolean, fail?: (error: any) => void) {
    let that = this;
    var url = HttpService.baseadr + 'Core/Authentication/Token';
    return that.http.post<any>(url, data).subscribe(
      res => {
        let token = res['access_token'];
        if (token != "") {
          HttpService.Token = token;
          HttpService.activationDone = true;
          HttpService.UserName = data["userName"];
          
          localStorage["auth_token"] = token;
          localStorage["userName"] = HttpService.UserName;
          localStorage["token_date"] = CustomDate.Now.getDate().getTime();
          if (token != null) {
            Helper.ClearStorage();
            that.navigate([RouteNames.HyperVManagement]);
            HttpService.SetAuthorized(true);
          }
        }
        else {
          fail('Empty access_token');
        }
      },
      error => { fail(error); }
    );
  }

  static baseadr: string = Helper.webApiUrl();

  public get<T>(path: string, data: HttpParams) {
    let that = this;
    let obs = new Observable<T>(sub => {
      HttpService.GetToken().then(Token => {
        let options = {
          headers: { 'Authorization': 'Bearer ' + Token },
          params: data
        };
        let link = HttpService.baseadr + path;
        let subs = that.http.get<T>(link, options).subscribe(
          data => {
            Helper.deserializeJSON(data);
            sub.next(data);
            //  subs.unsubscribe();
          },
          error => {
            let mes = error.statusText;
            that.notificator.addError(mes, path, error);
            if (error.status == 401) {
              HttpService.SetAuthorized(false);
              that.navigate([RouteNames.Login]);
            }
            sub.error(error);
            //  subs.unsubscribe();
          },
          () => {
            sub.complete();
            //  subs.unsubscribe();
          }
        )
      });
    });
    return obs;
  }

  public getHTTPExactly<T>(path: string, data: HttpParams) {
    let that = this;
    return HttpService.GetToken().then(Token => {
      let options = {
        headers: { 'Authorization': 'Bearer ' + HttpService.Token },
        params: data
      };
      let link = HttpService.baseadr + path;
      return that.http.get<T>(link, options).pipe(
        catchError((error: any) => {
          let mes = error.statusText;
          that.notificator.addError(mes, path, error);
          if (error.status == 401) {
            HttpService.SetAuthorized(false);
            that.navigate([RouteNames.Login]);
          }
          return EMPTY;
        })
      )
    })
  }
 
  public post<T>(path: string, data: any, headersoption: { [id: string]: string } = null) {
    let that = this;
    let obs = new Observable<T>(sub => {
      HttpService.GetToken().then(Token => {
        let options = {
          headers: {
            'Authorization': 'Bearer ' + Token,
          }
        };
        options.headers['Content-Type'] = 'application/json';
        if (headersoption != null) {
          Object.keys(headersoption).forEach(function (key) {
            options.headers[key] = headersoption[key];
          });
        }
        var link = HttpService.baseadr + path;
        let subs = this.http.post<T>(link, data, options).subscribe(
          data => {
            Helper.deserializeJSON(data);
            sub.next(data);
          },
          error => {
            let mes = error.statusText;
            that.notificator.addError(mes, path, error);
            if (error.status == 401) {
              HttpService.SetAuthorized(false);
              that.navigate([RouteNames.Login]);
            }
            sub.error(error);
            //  subs.unsubscribe();
          },
          () => {
            sub.complete();
            //  subs.unsubscribe();
          }
        );
      });
    });
    return obs;
  }
  public postjson<T>(path: string, data: any) {
    return this.post<T>(path, data, { 'Content-Type': 'application/json-patch+json' });
  }
  public put<T>(path: string, data: any, headersoption: { [id: string]: string } = null) {
    let that = this;
    let obs = new Observable<T>(sub => {
      HttpService.GetToken().then(Token => {
        let options = {
          headers: {'Authorization': 'Bearer ' + Token }
        };
        if (headersoption != null) {
          Object.keys(headersoption).forEach(function (key) {
            options.headers[key] = headersoption[key];
          });
        }
        var link = HttpService.baseadr + path;
        return this.http.put<T>(link, data, options).subscribe(
            data => {
              //that.notificator.addSuccess(path);
              sub.next(data);
            },
            error => {
              let mes = error.statusText;
              let dis = error.responseText;
              that.notificator.addError(mes, path, dis);
              if (error.status == 401) {
                HttpService.SetAuthorized(false);
                that.navigate([RouteNames.Login]);
              }
              sub.error(error);
            },
            () => {
              sub.complete();
            }
          )
        })
    })
    return obs;
  }
  public delete<T>(path: string, headersoption: { [id: string]: any } = null, globalOptions: any = null){
    let that = this;
    let obs = new Observable<T>(sub => {
      HttpService.GetToken().then(Token => {
        let options = {
          headers: { 'Authorization': 'Bearer ' + Token }
        };
        
        if (headersoption != null) {
          Object.keys(headersoption).forEach(function (key) {
            options.headers[key] = headersoption[key];
          });
        }
        if (globalOptions) {
          Object.keys(globalOptions).forEach(function (key) {
            options[key] = globalOptions[key];
          });
        }
        var link = HttpService.baseadr + path;
        this.http.delete<T>(link, options).subscribe(
          data => {
            //that.notificator.addSuccess(path);
            sub.next(data);
          },
          error => {
            let mes = error.statusText;
            let dis = error.responseText;
            that.notificator.addError(mes, path, dis);
            if (error.status == 401) {
              HttpService.SetAuthorized(false);
              that.navigate([RouteNames.Login]);
            }
            sub.error(error);
          },
          () => {
            sub.complete();
          }
        )
      })
    });
    return obs;
  }
  public patch<T>(path: string, data: any, headersoption: { [id: string]: string } = null) {
    let that = this;
    let obs = new Observable<T>(sub => {
      HttpService.GetToken().then(Token => {
        let options = {
          headers: { 'Authorization': 'Bearer ' + Token }
        };
        var link = HttpService.baseadr + path;
        this.http.patch<T>(link, data, options).subscribe(
          data => {
            sub.next(data);
          },
          error => {
            let mes = error.statusText;
            let dis = error.responseText;
            that.notificator.addError(mes, path, dis);
            if (error.status == 401) {
              HttpService.SetAuthorized(false);
              that.navigate([RouteNames.Login]);
            }
            sub.error(error);
          },
          () => {
            sub.complete();
          }
        )
      })
    });
    return obs;
  }         

  public getAuthGuacamole(done: (error: any) => void, fail?: (error: any) => void) {
    let that = this;
    let options = {
      headers: { 'Authorization': 'Bearer ' + HttpService.Token }
    };
    return that.http.get<string>(HttpService.baseadr + 'TenantPortal/GetCredential', options).subscribe(
      res => {
        HttpService.GuacamoleToken = res;
        done(res);
      },
      error => { fail(error); }
    );
  }
}
