import { VMStateTexts } from '.';
import { makeStateKey } from '@angular/platform-browser';


export class Helper {
  
  static deleteCookie(name: string) {
    var date = new Date(); // Берём текущую дату
    date.setTime(date.getTime() - 1); // Возвращаемся в "прошлое"
    document.cookie = name += "=; expires=" + date.toLocaleString(); // Устанавливаем cookie пустое значение и срок действия до прошедшего уже времени
  }
  static GetCookie(name: string) {
    var matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }
  static GetFromStorage(name: string) {
    var store = localStorage[name];
    if (store != null) {
      try {
        var pars = JSON.parse(store);
        return pars;
      }
      catch (x) {
        console.log(x);
        localStorage.removeItem(name);
      }
    }
    else
      localStorage.removeItem(name);
    return null;
  }

  static SaveToStorage(name: string, val: any) {
    if (val != null) {
      localStorage[name] = JSON.stringify(val);
    }
    else {
      localStorage.removeItem(name);
    }
  }

  static ClearStorage() {
    var dic: string[] = ["GetSystemStatusReportId", 'VMLifeCycleReportId','vmlcReportParam'];
    dic.forEach(name => {
      localStorage.removeItem(name);
    });
  }

  public static webApiUrl() {
    let host = document.documentElement.getAttribute('data-link');
    return host;
  }
  public static getStatus(status) {
    return VMStateTexts[status] || 'Unknown';
  }
  public static ESresize() {
    setTimeout(() => {
      if (typeof (Event) === 'function') {
        // modern browsers
        window.dispatchEvent(new Event('resize'));
      } else {
        //This will be executed on old browsers and especially IE
        let resizeEvent: UIEvent = window.document.createEvent('UIEvents');
        //   resizeEvent.initUIEvent('resize', true, false, window, 0);
        resizeEvent.initEvent('resize');
        //  initEvent(type: string, bubbles ?: boolean, cancelable ?: boolean): void;
        window.dispatchEvent(resizeEvent);
      }
      console.log('ESresize');
    }, 500);
  }
  public static TrimStart(str: string, seps: string[] = Helper.DirectorySeparatorChar) {
    let res =str;
    seps.forEach(val => {
      if (str.startsWith(val)) {
        res= str.substr(1);
      }
    });
    return res;
  }
  public static TrimEnd(str: string, seps: string[] = Helper.DirectorySeparatorChar) {
    let res = str;
    seps.forEach(val => {
      if (str.endsWith(val)) {
        res= str.substr(0, str.length - val.length);
      }
    });
    return res;
  }
  public static IsString(obj) {
    return (typeof obj === 'string' || obj instanceof String)
  }
  public static NormalizePath(path: string) {
    let res = Helper.TrimEnd(path);
    res = res.replace('/', '\\');
    return res.toLocaleUpperCase();
  }
  public static get DirectorySeparatorChar() { return ['\\', '/']; }
  public static GetFileName(path: string) {
    if (!Helper.NotEmpty(path)) return null;
    var sep = '';
    if (path.includes('\\'))
      sep = '\\';
    else if (path.includes('/'))
      sep = '/';
    else
      return path;
    let arr = path.split(sep);
    return arr[arr.length - 1];
  }
  public static GetRelativePath(parent: string, child: string) {
    let seps = ['\\', '/'];
    var orig = Helper.TrimEnd(parent,seps);
    if (child.toLocaleLowerCase().startsWith(orig.toLocaleLowerCase())) {
      var testPath = child.substring(orig.length);
      if (testPath.length != 0) {
        if (orig.length == 0 || testPath[0] == '\\' || testPath[0] == '/')
          return Helper.TrimStart(testPath, seps);
      }
    }
    return '';
  }

  public static GetFileNameWithoutExtension(path: string) {
    let res = '';
    if (path != null && path.length > 0) {
      let parts = path.split('\\');
      let fname = parts[parts.length - 1];
      let words = fname.split('.');
      let ext = words[words.length - 1];
      res = fname.substr(0, (fname.length - ext.length - 1));
    }
    return res;
  }

  public static isValidFileName(fname) {
    var rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
    var rg2 = /^\./; // cannot start with dot (.)
    var rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
    return rg1.test(fname) && !rg2.test(fname) && !rg3.test(fname);
  }
  public static RangeExeptValues(count: number, values: number[]) {
    let res: number[] = [];
    for (var i = 0; i < count + 1; i++) {
      if (!values.includes(i))
        res[res.length] = i;
    }
    return res;
  }

  public static PathCombine(start: string, end: string) {
    let res: string = null;
    if (start == null || end==null) {
      return res;
    }
    else {
      res = start + '\\' + end;
      return res;
    }
  }
  public static PathFromArr(arr: string[], removeempty = true) {
    let res: string = '';
    if (arr == null || arr.length == 0 ) {
      return res;
    }
    else {
      if (removeempty)
      arr.filter(c => { return Helper.NotEmpty(c) }).forEach((name, i) => { res = res + (i > 0 ? '\\' : '') + name; });
      else {
        if (arr.filter(c => { return !Helper.NotEmpty(c) }).length > 0) return res;
        else
          arr.forEach((name, i) => { res = res + (i > 0 ? '\\' : '') + name; });
      }
      return res;
    }
  }
  public static fireClick(node) {
    if (document.createEvent) {
      var evt = document.createEvent('MouseEvents');
      evt.initEvent('click', true, false);
      node.dispatchEvent(evt);
    }
    //else if (document.createEventObject) {
    //  node.fireEvent('onclick');
    //}

    else if (typeof node.onclick == 'function') {
      node.onclick();
    }
  }
  public static calculateMemory(memory: any) {
    
    if (memory) {
      var sign = '';
      if (memory < 0) {
        sign = '-';
        memory = memory * (-1);
      }
      let m = memory / 1048576;
      let e = " MB";
      if (m > 1023) {
        m = m / 1024;
        e = " GB";
      }
      let num = Number.isInteger(m) ? m : m.toFixed(2);
      return  sign+num + e;
    }
    return 0;
  }

  public static GetDatedme(date: Date, sep = '.') {
    if (!date)
      return '';
    let d = new Date(date);
    let m = d.getMonth() + 1;
    let res = d.getDate() + sep + m + sep + d.getFullYear();
    return res;
  }
  public static EqualById(obj: any, obj2: any, id: string = 'Id') {
    if (obj == null && obj2 == null) return true;
    if (obj == null || obj2 == null) return false;
    return ( obj[id] == obj2[id]);
  }
  public static Compare(a: any, b: any) {
    let c = JSON.stringify(a);
    let d = JSON.stringify(b);
    return c == d;
  }
  public static JsonforCsharp(obj: any) {
    let base = JSON.stringify(obj);
    let res = JSON.stringify(base);
    return res;

  }
  public static NotEmpty(val) {
    return val != null && val.length > 0;
  }
  public static Clone(val) {
    if (val == null)
      return val;
    let str = JSON.stringify(val);
  //  console.log(str);
    return JSON.parse(str);
  }
  public static FirstOrValue(arr: any[],value:any=null) {
    if (arr == null || arr.length==0)
      return value;
    return arr[0];
  }
  public static LastOrValue(arr: any[], value: any = null) {
    if (arr == null || arr.length == 0)
      return value;
    return arr[arr.length-1];
  }
  public static IndexById(arr: any[],idval:any,idkey='Id') {
    if (arr == null || arr.length == 0 || idval == null)
      return -1;
    else {
      let el = Helper.FirstOrValue(arr.filter(a => a[idkey] == idval));
      if (el == null) return -1
      else {
        return arr.indexOf(el);
      }
    }
  }
  public static MoveElUp(arr: any[], idval: any, idkey = 'Id') {
    let ind = Helper.IndexById(arr, idval, idkey);
    if (ind > 0) {
      let t = arr[ind-1];
      arr[ind - 1] = arr[ind];
      arr[ind] = t;
    }
  }
  public static MoveElDown(arr: any[], idval: any, idkey = 'Id') {
    let ind = Helper.IndexById(arr, idval, idkey);
    if (ind < arr.length-1) {
      let t = arr[ind + 1];
      arr[ind + 1] = arr[ind];
      arr[ind] = t;
    }
  }
  public static ReplaceEl(arr: any[],el:any,id:string) {
    let s = Helper.FirstOrValue(arr.filter(c => c[id] == el[id]))
    if (s != null) {
      let ind = arr.indexOf(s);
      arr[ind] = el;
    }
  }
  public static ArrayOfName(arr: any[]) {
    let res: { Name: any }[]=[];
    if (arr == null)
      return res;
    arr.forEach((val, i) => {
      res[i] = { Name: val };
    });
    return res;
  }
  public static RemovePropByName(object, propname: string = '$id', depth =5) {
    try {
      if (depth == 0) return;
      let ndepth = depth - 1;
      Object.keys(object).forEach(key => {
        if (key == propname) {
          //  object[propname] = null;
          delete object[propname];
        }
        else {
          let field = object[key];
          if (field != null && field instanceof Object)
            Helper.RemovePropByName(field, propname, ndepth);
        }
      });
    }
    catch (er) {
      console.log(er);
    }
  }
  public static addStringArr(a: string[], b: string[]) {
    var ta = b.filter(s => a.indexOf(s) == -1);
    return [...a, ...ta];
  }
  public static copyObject(object) {
    return JSON.parse(JSON.stringify(object));
  }

  public static clearSubscriptions(subscriptions) {
    for (let i = 0; i < subscriptions.length; i++) {
      subscriptions[i].unsubscribe();
    }
    return [];
  }

  public static deserializeJSON(obj,depth=10) {
    try {
      var catalog = {};
      this.catalogObject(obj, catalog, depth);
      this.resolveReferencesnotcircle(obj, catalog, depth, []);
      this.RemovePropByName(obj);
      this.RemovePropByName(obj, '$ref')
    }
    catch (er) {
      console.log(er);
    }
  }
  public static deserializeJSONRefs(obj, depth = 10) {
    try {
      var catalog = {};
      this.catalogObject(obj, catalog, depth);
      this.resolveReferences(obj, catalog, depth);
    }
    catch (er) {
      console.log(er);
    }
  }
  static getISODateSimple(date) {
    if (!date) return (new Date).toISOString();
    return date.toISOString();
  }

  static getISODate(date) {
    if (!date) return (new Date).toISOString();
    return (new Date(date.year, date.month - 1, date.day)).toISOString();
  }

  static catalogObject(obj, catalog, depth) {
    if (depth == 0) return;
    var i;
    let ndepth = depth - 1;
    if (obj && typeof obj === 'object') {
      var id = obj.$id;
      if (typeof id === 'string') {
        catalog[id] = obj;
      }
      if (Object.prototype.toString.apply(obj) === '[object Array]') {
        for (i = 0; i < obj.length; i += 1) {
          this.catalogObject(obj[i], catalog, ndepth)
        }
      } else {
        for (let name in obj) {
          if (typeof obj[name] === 'object') {
            this.catalogObject(obj[name], catalog, ndepth);
          }
        }
      }
    }
  }
  static resolveReferencesnotcircle(obj, catalog, depth,parents) {
    if (depth == 0) return;
    let ndepth = depth - 1;
    var i, item, name, id,ref;
    if (obj && typeof obj === 'object') {
      if (Object.prototype.toString.apply(obj) === '[object Array]') {
        for (i = 0; i < obj.length; i += 1) {
          item = obj[i];
        
          if (item && typeof item === 'object') {
            ref = item.$ref;
            if (typeof ref === 'string') {
              if (parents.indexOf(ref) < 0) {
                obj[i] = catalog[ref];
              }
              else {
                obj[i] = null;
              }
            } else {
              var temp = [...parents];
              id = item.$id;
              if (typeof id === 'string') {
                temp[temp.length] = id;
              }
              this.resolveReferencesnotcircle(item, catalog, ndepth, temp);
            }
          }
        }
      } else {
        for (name in obj) {
          if (typeof obj[name] === 'object') {
            item = obj[name];
            if (item) {
              ref = item.$ref;
              if (typeof ref === 'string') {
                if (parents.indexOf(ref) < 0) {
                  obj[name] = catalog[ref];
                }
                else
                  obj[name] = null;
              } else {
                var temp = [...parents];
                id = item.$id;
                if (typeof id === 'string') {
                  temp[temp.length] = id;
                }
                this.resolveReferencesnotcircle(item, catalog, ndepth, temp);
              }
            }
          }
        }
      }
    }
  }
  static resolveReferences(obj, catalog, depth) {
    if (depth == 0) return;
    let ndepth = depth - 1;
    var i, item, name, id;
    if (obj && typeof obj === 'object') {
      if (Object.prototype.toString.apply(obj) === '[object Array]') {
        for (i = 0; i < obj.length; i += 1) {
          item = obj[i];
          if (item && typeof item === 'object') {
            id = item.$ref;
            if (typeof id === 'string') {
              obj[i] = catalog[id];
            } else {
              this.resolveReferences(item, catalog, ndepth);
            }
          }
        }
      } else {
        for (name in obj) {
          if (typeof obj[name] === 'object') {
            item = obj[name];
            if (item) {
              id = item.$ref;
              if (typeof id === 'string') {
                obj[name] = catalog[id];
              } else {
                this.resolveReferences(item, catalog, ndepth);
              }
            }
          }
        }
      }
    }
  }
  public static StringToDate(stringdate: string, format: string) {
    let normalized = stringdate.replace(/[^a-zA-Z0-9]/g, '-');
    let normalizedFormat = format.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-');
    let formatItems = normalizedFormat.split('-');
    let dateItems = normalized.split('-');

    let monthIndex = formatItems.indexOf("mm");
    let dayIndex = formatItems.indexOf("dd");
    let yearIndex = formatItems.indexOf("yyyy");
    let hourIndex = formatItems.indexOf("hh");
    let minutesIndex = formatItems.indexOf("ii");
    let secondsIndex = formatItems.indexOf("ss");

    let today = new Date();

    let year = yearIndex > -1 ? +dateItems[yearIndex] : today.getFullYear();
    let month = monthIndex > -1 ?( +dateItems[monthIndex] )- 1 : today.getMonth() - 1;
    let day = dayIndex > -1 ? +dateItems[dayIndex] : today.getDate();

    let hour = hourIndex > -1 ? +dateItems[hourIndex] : today.getHours();
    let minute = minutesIndex > -1 ? +dateItems[minutesIndex] : today.getMinutes();
    let second = secondsIndex > -1 ? +dateItems[secondsIndex] : today.getSeconds();

    return new Date(year, month, day, hour, minute, second);
  };

  static getGbFromMb(value) {
    const gb = value / 1024;
    return Number.isInteger(gb) ? gb : gb.toFixed(1);
  }

  static validateIP(ip, mask = null) {
    
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip)) {
      return mask != null ? mask >= 0 && mask <= 32 : true;
    }
    return false;
  }

  static toLowerCase(val = '') {
    return val ? val.toLowerCase() : '';
  }
  static lowerCaseComparison(val1 = '', val2 = '') {
    return Helper.toLowerCase(val1) == Helper.toLowerCase(val2);
  }
}
