import { Component, Injectable, Input, Output, EventEmitter } from '@angular/core';
import { PageMenuItem } from '../..';


@Component({
  selector: 'grid-menu',
  templateUrl: './GridMenu.component.html'
})

export class GridMenu{
  @Input()
  public visibleclearpanel = true;
  @Input()
  public menuvisible = false;

  
  @Input()
  public threepointhidden = false;

  @Output()
  public selectedrowsChange = new EventEmitter<any[]>();
  @Input()
  set selectedrows(val: any[]) {
    this._selectedrows = val;
    this.selectedrowsChange.emit(this._selectedrows);
  }
  get selectedrows(): any[] {
    return this._selectedrows;
  }
  _selectedrows: any[]=[];
  constructor() {
  }
  _items: PageMenuItem[][];
  @Input()
  get items(): PageMenuItem[][] {
    return this._items;
  }
  set items(val: PageMenuItem[][]) {
    this._items = val;
    if (val && val.length > 0) {
      let i = 0;
      let res = [];
      this.items.forEach((arr) => {
        arr.forEach((item) => {
          res[i] = item;
          i++;
        });
      });
      this.menuitems = res;
    }
  }
  public menuitems: PageMenuItem[] = [];
  public munushow() {
    let p = document.querySelector('.cdk-overlay-pane');
    if (p)
      p.classList.add('rowactions-menu');
  }
  public clear() {
    this.selectedrows = [];
  }
}

