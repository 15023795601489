import { ContextObject } from '..';

export class StorageData implements ContextObject {
  Id: string;
  Type: number;
  Path: string;
  ContentType: StorageContentType;
  Capacity: number;
  FreeSpace: number;
  ValidContextObjects: any[] = [];
  ValidCategoryGroup: any[] = [];
  Name: string;
  Description: string;
  ObjectType: string;
  Shared: number;
  InternalState: number;
  CategoryGroupLinks: any[]=[];
  Parent_Id: string;
  Selected: boolean = false; 
}

export enum StorageContentType {
  BackupStorage = "BackupStorage",
  VMStorage = "VMStorage",
  ISOLib = "ISOLib",
  TemplateLib = "TemplateLib"
}

export enum StorageType {
  LocalFilesystemStorage,
  NetworkShareStorage,
  ClusterStorage
}
export class AGroup {
  Id: string;
  Name: string;
  Description: string;
  Category_Id: string;
  Category: ACategory;
}
export class ACategory {
  Groups: AGroup[];
  Id: string;
  Name: string;
  SupportedHost: string;
}
export class CategoryValue {
  public Category: ACategory;
  public value: any = null;
  public open = false;
  public  item: AGroup;
}
export class CategoryValues {
  public Category: ACategory;
  public values: { group: AGroup, selected: boolean }[] = [];
  public open = false;
  get SelectedAll() {
    if (this.Category.Groups == null || this.Category.Groups.length == 0) return false;
    return this.Category.Groups.length == this.values.filter(v => { return v.selected; }).length;
  }
  set SelectedAll(val) {
    if (this.Category.Groups == null || this.Category.Groups.length == 0) return;
    this.values.forEach(v => { v.selected = val; });
  }
}
