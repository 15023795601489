import { Component,  OnInit,   ElementRef, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { modalWindow } from '../scrollbar';
import { IModalWindowSettings } from '..';

@Component({
  selector: 'modalwindow',
  templateUrl: './ModalWindow.component.html'
})

export class ModalWindow  {
  public init = false;
  @Output()
  public visibleChange = new EventEmitter<boolean>();
  public _title = '';
  @Input()
  set title(val) {
    if (this._title != val) {
      this._title = val;
    }
  }

  @Input()
  set visible(val: boolean) {
    if (this._visible != val) {
      this._visible = val;
      if (val) {
        this.init = true;
      }
      this.visibleChange.emit(this._visible);
    }
  }
  get visible(): boolean {
    return this._visible;
  }
  public _visible: boolean = false;
  _settings: IModalWindowSettings = { };
  @Input()
  get settings(): IModalWindowSettings {
    return this._settings;
  }
  set settings(val: IModalWindowSettings) {
    let that = this;
    Object.keys(val).forEach(function (key) {
      that._settings[key] = val[key];
    });
  }
  
  _isLoading: boolean =false;
  @Input()
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(val: boolean) {
    this._isLoading = val;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}
 
}
