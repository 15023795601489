import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CollectionViewer, DataSource } from "@angular/cdk/collections";

export class TreeNode<T> {
  public Selected = false;
  public Children: TreeNode<T>[] =[];
  public lvl: number=0;
  public SelectNode= (node: TreeNode<T>) => {};
  constructor(public Data: T, public Expand: boolean=false) { }
}

export class TreeNodeFactory<T> {
  public TakeList(arr: T[], Tid: string, Tparentid: string, selecid = null, expanded = false) {
    let res: TreeNode<T>[] = [];
    let data: { [id: string]: TreeNode<T> } = {};
    arr.forEach(item=> {
      data[item[Tid]] = new TreeNode<T>(item, expanded);      
    })
    arr.forEach(item=>{
      let parentid = item[Tparentid]
      let id = item[Tid];
      let node = data[id];
      let pnode = data[parentid];
      if (selecid == id)
        node.Selected = true;
      if (parentid == null || parentid.length == 0 || pnode == null) {
        node.lvl = 0;
        res[res.length] = node;
      }
      else {
        pnode.Children[pnode.Children.length] = node;
      }
    });
    return {root:data,list:res};
  }
  
}


export class TreeNodeDataSource<T> extends DataSource<TreeNode<T>>{
  private treenodes: Observable<TreeNode<T>[]>;
  constructor(private data: Observable<T[]>, private Tid: string, private Tparentid: string) {
    super();
    this.treenodes = this.data.pipe(map(t => { return this.TakeList(t); }));
  }
  public connect(collectionViewer: CollectionViewer) {
    return this.treenodes;
  }
  private TakeList(arr: T[]) {
    let res = new Array<TreeNode<T>>();
    let data: { [id: string]: TreeNode<T> } = {};
    for (var i = 0; i < arr.length; i++) {
      var item = arr[i];
      data[item[this.Tid]] = new TreeNode<T>(item);
    }
    for (var i = 0; i < arr.length; i++) {
      var item = arr[i];
      let parentid = item[this.Tparentid]
      let id = item[this.Tid];
      let node = data[id];
      let pnode = data[parentid];
      if (parentid == null || parentid.length == 0 || pnode == null) {
        res[res.length] = node;
      }
      else {
        pnode.Children[pnode.Children.length] = node;
      }
    }
    return res;
  }
  //public connect(collectionViewer: CollectionViewer) {
  //  return this.treenodes;
  //}
  /**
   * Disconnects a collection viewer (such as a data-table) from this data source. Can be used
   * to perform any clean-up or tear-down operations when a view is being destroyed.
   *
   * @param collectionViewer The component that exposes a view over the data provided by this
   *     data source.
   */
  public disconnect(collectionViewer: CollectionViewer) { }

}
export interface ITreenode {
  id: string;
  name: string;
  children: ITreenode[];
  isExpanded: boolean;
}

export class CGTreeNode implements ITreenode {
  id: string;
  name: string;
  children: CGTreeNode[] = new Array<CGTreeNode>();
  isExpanded: boolean;
  data: any;
  type: string;
  checked: boolean=false;
  public static NewItem(id: string, name: string, isExpanded: boolean, data: any, type: string) {
    let res = new CGTreeNode();
    res.id = id;
    res.name = name;
    res.isExpanded = isExpanded;
    res.data = data;
    res.type = type;
    return res;
  }
}
export class fileTreenode {
  Name: string;
  FullName: string;
  Size: number;
  DateModified: any;
}
export class folderTreenode {
  Name: string;
  FullName: string;
  ChildsIsLoad?: boolean;
  FolderChildrens?: folderTreenode[];
  FileChildrens?: folderTreenode[];
  DateModified: any;
  hasChildren: boolean = this.FolderChildrens && this.FolderChildrens.length > 0;
  type: string='folder'
}
export interface rootfnode {
  selectednode: fnode;
  selectnode(node: fnode);
  getchildren: (node: fnode) => Observable<fnode[]>;
  refreshnode: (node: fnode) => void;
}
export class fnode {
  name: string;
  children: fnode[];
  hasChildren: boolean;
  fullname: string;
  type: string = 'folder';
  isopen = false;
  lvl = 2;
  parent: fnode;
  root: rootfnode;
  dataloaded = false;
  DateModified: Date;
  icon: string = '';
  public static newItem(data: folderTreenode) {
    let res = fnode.newItemRoot(data);
    res.icon = 'icon-folder';
    res.children = [];
    data.FolderChildrens.forEach(d => {
      if (d != null) {
        let item = fnode.newItemRoot(d);
        item.icon = 'icon-folder';
        item.parent = res;
        res.children[res.children.length] = item;
      }
    });
    if (data.FileChildrens)
      data.FileChildrens.forEach(d => {
        let node = new fnode();
        node.children = [];
        node.name = d.Name;
        node.parent = res;
        node.DateModified = new Date(d.DateModified) ;
        node.icon = 'icon-file';
        node.fullname = d.Name;
        node.type = 'file';
        node.hasChildren = false;
        res.children[res.children.length] = node;
      });
    return res;
  }

  static newItemRoot(data: folderTreenode) {
    let res = new fnode();
    if (data.DateModified!=null)
    res.DateModified = new Date(data.DateModified); ;
    res.name = data.Name;
    res.fullname = data.FullName;
    res.hasChildren = data.FolderChildrens.length > 0 || data.FileChildrens && data.FileChildrens.length>0;
    return res;
  }
}
