import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpService } from '../../../services/http.service';
import { Guid } from '../../../classes/guid';
import { Observable } from 'rxjs';
import { RouteNames } from '../../../classes';
import { VInputModel } from '../CommonComponents';
import { Validators } from '@angular/forms';
//import { CustomDate } from './services/classes/CustomDate.class';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']

})
export class LoginComponent implements OnInit{
  static Path: "Login"
  public loginservererror = false;
  //login
  public buttonCancelText = "CANCEL";
  public buttonLoginText = "LOGIN";
  public rememberMeText = "Remember me";
  public login: string;
  public password: string;
  public remember: boolean;
  public Authorized: boolean;
  ngOnInit() {
   
  }
  public usermodel = new VInputModel({
    Label: 'Login',
    errors: [{ key: 'required', value: 'Login is required' }],
    Validators: [Validators.required],
  });
 public passwordmodel = new VInputModel({
    Label: 'Password',
    errors: [{ key: 'required', value: 'Password is required' }],
    Validators: [Validators.required],
  });
  //login message
  public loginMessage: string = "";

  //login popup
  constructor(private client: HttpService, private router: Router) {
    this.remember = false;
    this.Authorized = HttpService.Authorized;
    HttpService.authorizedObserver.subscribe(Authorized => {
      if (HttpService.Authorized) {
        this.Authorized = HttpService.Authorized;
        this.client.navigate([RouteNames.HyperVManagement]);
      }
    });
  }

  loginEnd() {
    this.loginMessage = "";
 
    //if (!this.login && !this.password) {
    //  this.login = "admin";
    //  this.password = "59password";
    //} 
    this.loginOnServer();
    
  }
  loginOnServer(remember: boolean = false) {
    let that = this;
    this.client.login({ userName: this.login, password: this.password },
      that.remember,
      error => {
        if (error.status != 401) {
          this.loginservererror = true;
        }
         that.loginMessage = "Error";
      }
    );
  }

  clear() {
    this.login = "";
    this.password = "";
  }
 
 
}
