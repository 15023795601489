<div class="mw-layout mws-container " [ngClass]="visible? 'mw-show':''">
  <div class="mws-wrapper">
    <div class="mws-content scrollbar1">
      <div class="mw-cwrapper">
        <div class="mw-wrapper">
          <div class="mw-container">
            <div class="mv-content" style="display:block">
              <div class="dialog" *ngIf="init">
                <div class="dialog-top">
                  <p class="dialog-title">{{_title}}</p>
                  <div class="dialog-close-btn" (click)="visible=false"></div>
                </div>
                <div class="dialog-contwrap">
                  <div class="dialog-preloader" *ngIf="isLoading">
                    <img src="assets/images/loader.gif" />
                  </div>
                  <div class="">
                    <ng-content></ng-content>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

