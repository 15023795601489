import { Helper } from '../helper';
import { Guid } from '..';

export class ValueTypeExtensions {
  public static Kilo = 1024;
  public static  Mega = 1024 * 1024;
  public static  Giga = 1024 * 1024 * 1024;
  public static  Tera = 1024 * 1024 * 1024 * 1024;
}
export enum ControllerType {
  IDE,
  SCSI
}
export function ControllerTypeString(type: ControllerType) {
  if (type == ControllerType.IDE)
    return 'IDE';
  else return 'SCSI';
}
export enum VhdCreationType {
  Existing,
  Physical,
  New
}
export enum VhdType {
  Unknown = 0,
  Fixed = 2,
  Dynamic = 3,
  Differencing = 4
}
export enum VhdFormat {
  Unknown,
  VHD = 2, // 2 because PowerShell has this value
  VHDX,
  VHDSet
}

export interface IDrive {
  Id: string;
  Name: string;
 // ControllerKeyName: string;
  ControllerNumber?: number;
  ControllerLocation?: number;
  ControllerType?: ControllerType;
}

export class VhdDrive implements IDrive {
  public Id: string;
  public mId = Guid.NewGuid().ToString();
  public ControllerKeyName: string;
  public ControllerNumber?: number;
  public ControllerLocation?: number;
  public ControllerType?: ControllerType;
  public Name: string;
  public TemplateFileName: string;
  public Path: string;
  public  FileName: string;
  public StoragePath: string;
  public RelativePath: string;
  public ParentPath: string;
  public VhdCreationType?: VhdCreationType;
  public VhdType?: VhdType;
  public VhdFormat?: VhdFormat;
  public Size: number;
  public MinimumSize?: number;
  public ContainsOs?: boolean;
  public EnableQos = false;
  public MinimumIops = 0;
  public MaximumIops = 0;
  public ExistingVhdStoragePath: string;
  public ExistingVhdRelativePath: string = '';
  public NewStoragePath: string;
  public NewPath: string;
  public DiskNumber?: number;
  public static vhdformatstring(format: VhdFormat,lower=false) {
    if (format == null) return '';
    let res = '';
    switch (format) {
      case VhdFormat.VHD: res = 'VHD'; break;
      case VhdFormat.VHDSet: res = 'VHDSet'; break;
      case VhdFormat.VHDX: res = 'VHDX'; break;
    }
    if (lower)
      res=res.toLocaleLowerCase();
    return res;
  }
  public static vhdtype(type: VhdType, lower = false) {
    if (type == null) return '';
    let res = '';
    switch (type) {
      case VhdType.Differencing: res = 'Differencing'; break;
      case VhdType.Dynamic: res = 'Dynamic'; break;
      case VhdType.Fixed: res = 'Fixed'; break;
    }
    if (lower)
      res = res.toLocaleLowerCase();
    return res;
  }
  public static FileNemeWithExt(drive: VhdDrive) {
    if (!Helper.NotEmpty(drive.FileName)) return '';
    let fname = drive.FileName;
    if (drive.FileName.includes('.')) {
      let ind = drive.FileName.indexOf('.');
      fname = drive.FileName.substr(0, ind);
    }
    fname = fname + VhdDrive.vhdformatstring(drive.VhdFormat, true);
    return fname;
  }

}
export class VhdWithStorage extends VhdDrive {
  constructor(vhd: VhdDrive) {
    super();
    this.Id=vhd.Id;
    this.ControllerNumber = vhd.ControllerNumber;
    this.ControllerLocation = vhd.ControllerLocation;
    this.ControllerType = vhd.ControllerType;
    this.Name = vhd.Name;
    this.TemplateFileName = vhd.TemplateFileName;
    this.Path = vhd.Path;
    this.FileName = vhd.FileName;
    this.StoragePath = vhd.StoragePath;
    this.RelativePath = vhd.RelativePath;
    this.ParentPath = vhd.ParentPath;
    this.VhdCreationType = vhd.VhdCreationType;
    this.VhdType = vhd.VhdType;
    this.VhdFormat = vhd.VhdFormat;
    this.Size = vhd.Size;
    this.MinimumSize = vhd.MinimumSize;
    this.ContainsOs = vhd.ContainsOs;
    this.EnableQos = vhd.EnableQos;
    this.MinimumIops = vhd.MinimumIops;
    this.MaximumIops = vhd.MaximumIops;
    this.ExistingVhdStoragePath = vhd.ExistingVhdStoragePath
    this.ExistingVhdRelativePath = vhd.ExistingVhdRelativePath;
    this.NewStoragePath = vhd.NewStoragePath;
    this.NewPath = vhd.NewPath;
    this.DiskNumber = vhd.DiskNumber;
  }
  public Storage: any;
	public NewPath: string;
	public Name: string;
}


export enum DvdMediaType {
  None,
  PassThrough,
  ISO
}

export class DvdDrive implements IDrive{
  public Id: string;
  public mId = Guid.NewGuid().ToString();
  //public get ControllerKeyName() {
  //  if (this.ControllerType == null || this.ControllerNumber == null)
  //    return '';
  //  return ControllerTypeString(this.ControllerType) + '\\' + this.ControllerNumber;
  //}
  //public set ControllerKeyName(val) { }
  public ControllerNumber?: number;
  public ControllerLocation?: number;
  public ControllerType?: ControllerType;
  public Name: string;
  public Path: string;
  public StoragePath: string;
  public RelativePath: string;
  public PathForPhysicalDrive: string;
  public DvdMediaType?: DvdMediaType;
 
}

export class PhysicalDisk {
  public Number: number;
  public IsOffline: boolean;
  public FriendlyName: string;
}
