import { Observable, BehaviorSubject } from 'rxjs';
import { WebsocketService } from '../services/websocket.service';
import { Helper } from '../classes';
import { HttpService } from '../services';


export class Repository<T> {
  private dataSubject: BehaviorSubject<{ [id: string]: T }>;
  private loaded: boolean = false;
  public wsInit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private TName: string;
  private id: string;
  private addFormWS: boolean = false;
  private deactivateWS: boolean = false;
  private _Observers: {
    addedObserver: Observable<T[]>,
    modifiedObserver: Observable<T[]>,
    deletedObserver: Observable<string[]>,
  } = null;
  public get Observers() {
    let that = this;
    let o = new Observable<{
      addedObserver: Observable<T[]>,
      modifiedObserver: Observable<T[]>,
      deletedObserver: Observable<string[]>,
    }>(sub =>
    {
      if (this._Observers != null)
        sub.next(this._Observers);
      else {
        WebsocketService.Instance().subscribe(ws => {
          let obs = {
            addedObserver : ws.on<T[]>(that.getEventName('Added')),
            modifiedObserver : ws.on<T[]>(that.getEventName('Modified')),
            deletedObserver : ws.on<string[]>(that.getEventName('Deleted')),
          };
          this._Observers = obs;
          sub.next(obs);
        })
      }
    });
    return o;
  }
  public Clear() {
    this.loaded = false;
    this.alldata = [];
    this.dataSubject.next({});
  }
  constructor(private load: () => Observable<T[]>, prop: { [id: string]: any }) {
    let that = this;
    that.setProperty(prop);
    that.dataSubject = new BehaviorSubject<{ [id: string]: T }>({});
    HttpService.authorizedObserver.subscribe(Authorized => {
      if (!Authorized) {
        that.Clear();
      }
    });
    if (!this.deactivateWS) {
      that.Observers.subscribe(o => {
        if (this.addFormWS) {
          o.addedObserver.subscribe(data => {
            Helper.deserializeJSON(data);
          
            that.loadData(data);
          });
        }
        o.modifiedObserver.subscribe(data => {
          const e = that.dataSubject.value;
          let j = 0;
          data.forEach(item => {
            let id = item[that.id];
            if (e[id] != null) {
              e[id] = item; j++;
            }
          });
          if (j > 0) {
            that.dataSubject.next(e);
          }
           
        });
        o.deletedObserver.subscribe(data => {
          that.Remove(data);
        });
        that.wsInit.next(true);
      });
    }
   
  }
 
  private setProperty(prop: { [id: string]: any }) {
    this.id = prop['id'] || 'Id';
    this.TName = prop['TName'] || '';
    this.addFormWS = prop['addFormWS'] || false;
    this.deactivateWS = prop['deactivateWS'] || false;
  }

  public loadData(data: T[], reload: boolean = false) {
   
    const newdata: { [id: string]: T } = reload ? {} : { ...this.dataSubject.value };
    let ind = this.id;
    data.forEach(item => {
      let id = item[ind];
      newdata[id] = item;
    });
    this.dataSubject.next(newdata);
  }

  private isloading = false;
  private LoadData(reload: boolean = false) {
    let that = this;
    if (!this.loaded || reload) {
      if (that.isloading)
        return;
      that.isloading = true;
      this.load().subscribe(
        data => {
          Helper.deserializeJSON(data);
          this.loaded = true;
          this.isloading = false;
          this.loadData(data, reload);
        },
        error => {
          that.isloading = false;
        }
      );
    } 
  }

  private getEventName(eventType): string {
    return this.TName + "." + eventType;
  }

  public Remove(data: Array<string>) {
    let that = this;
    if (data && data.length > 0) {
      
      const e = Object.values(that.dataSubject.value);
      let d = e.filter(v => {
        return !data.includes(v[that.id])
      });
      that.loadData(d, true);
    }
  }

  public GetDataSource() {
    let ds = new Observable<T[]>(sub => {
      if (!this.loaded) {
        this.LoadData();
      }
      this.dataSubject.subscribe(d => {
        let data = Object.values(d);
        if (this.loaded) {
          sub.next(data);
          //if (this.TName == "AzureVirtualMachine") {
          //  console.log("AzureVirtualMachine");
          //  console.log(data);
          //}
        }
      
      });
    });
    return ds;
  }
  
  public GetById(id: string) {
    return new Promise<T>((resolve) => {
      if (this.loaded)
        resolve(this.dataSubject.value[id]);
      else {
        this.dataSubject.subscribe(o => {
          if (this.loaded)
            resolve(o[id]);
        });
        this.LoadData();
      }
    });
  }
  private alldata: T[] = [];
  public GetLoadedData() { return this.alldata;  }
  public GetData() {
    return new Promise<T[]>((resolve) => {
      if (this.loaded) {
        this.alldata = Object.values(this.dataSubject.value);
        resolve(this.alldata);
      }
      else {
        this.dataSubject.subscribe(o => {
          if (this.loaded) {
            this.alldata = Object.values(o);
            resolve(this.alldata );
          }
        });
        this.LoadData();
      }
    });
  }

  public Refresh() {
    this.LoadData(true);
  }
} 
