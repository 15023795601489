import { Component, OnInit, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {  scrollBar } from '../scrollbar';

@Component({
  selector: 'scrollbar',
  templateUrl: './ScrollBar.component.html'
})

export class ScrollBar implements OnInit {
  scrollBar: scrollBar;
  @Output()
  public refreshChange = new EventEmitter<boolean>();
  @Input()
  set refresh(val: boolean) {
    if (this._refresh!= val) {
      this._refresh = val;
      if (this._refresh)
        this.scrollBar.Refresh();
      this.refreshChange.emit(this._refresh);
    }
  }
  get refresh(): boolean {
    return this._refresh;
  }
  _refresh: boolean = false;
  constructor(private elRef: ElementRef) {
  
  }
  ngOnInit(): void {
    let root = this.elRef.nativeElement;
    this.scrollBar = scrollBar.Init(root);
  }
}
