export class DatastoreResponse {
  Name: string;
  Type: number;
  Purpose: string;
  Location: string;
  ContextObjectId: string;
  Path: string;
  Capacity: number;
  FreeSpace: number;
  UsedSpace: number;
}

export const datastoreTypes = ["Local filesystem datastore", "Network datastore", "Cluster datastore"];

export const datastorePurposes = {
  VMStorage: {
    name: "Virtual machine storage",
    longName: "VM storages",
  },
  ISOLib: {
    name: "ISO library",
    longName: "ISO libraries",
  },
  TemplateLib: {
    name: "Template library",
    longName: "Template libraries",
  },
  BackupStorage: {
    name: "Backup storage",
    longName: "Backup storages",
  }
};
