<div class="loginform" *ngIf="!Authorized" >
  <div>
    <div class="login-pnl">
      <p class="login-icon logoblue bg-center"></p>
      <div class="loginform-body">
        <div *ngIf="!loginservererror">
          <h3 class="login-title">Sign in</h3>
          <v-input [model]="usermodel" [(value)]="login"></v-input>
          <v-password [model]="passwordmodel" [(value)]="password"></v-password>
          <p class=" dialog-button  button-blue" (click)="loginEnd()">Sign in</p>
        </div>
        <div class="login-servererror" *ngIf="loginservererror">
          <div style="height:56px;">
            <h3 class="login-title display-block">Error</h3>
          </div>
          <div class="login-servererror-wrap">
            <p class="text1424">No connection could be made because the target machine  actively refused it.</p>
          </div>
          <p class="login-tryagain" (click)="loginservererror=false">Try again</p>
        </div>
      </div>
    </div>
  </div>
</div>
