<div class="menu-breadcrumb-container">
  <div class="align-center">
    <span class="rmcollaser" *ngIf="!isSecondaryMenuOpen()" (click)="showSecondaryMenu()"></span>
    <h4 class="margin-0" *ngIf="breadcrumbs.length === 1">{{breadcrumbs[0]}}</h4>
    <div class="menu-breadcrumbs align-center"
         [ngClass]="{'menu-breadcrumbs-big': breadcrumbs.length > 2 && isSecondaryMenuOpen(), 'menu-breadcrumbs-4': breadcrumbs.length >= 4}"
         *ngIf="breadcrumbs.length > 1">
      <span *ngFor="let breadcrumb of breadcrumbs; last as isLast"
            [title]="breadcrumb.name"
            class="align-center">
        <a [routerLink]="breadcrumb.address" class="ellipsis">
          {{breadcrumb.name}}
        </a>
        <span class="blue-arrow blue-arrow-right" *ngIf="!isLast"></span>
      </span>
    </div>
  </div>
</div>
