import { Component, OnInit,  ViewChild, ChangeDetectorRef } from '@angular/core';
import { WebsocketService, NotificationService } from '../../../../services';
import { Repository } from '../../../../repository';
import { Job } from '../../../../classes';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable } from 'rxjs';

@Component({
  selector: 'jobs-notifier',
  templateUrl: 'JobsNotifier.template.html'
})

export class JobsNotifierComponent implements OnInit {
  jobs: Job[] = [];
  newJobs: Job[] = [];
  newJob: any = null;
  snackBarRef: any;
  menuOpen: boolean = false;
  snackbarOpen: boolean = false;
  snackbarTimeout: any = null;

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  constructor(private _snackBar: MatSnackBar, private NotificationService: NotificationService) {
    this.jobs = this.NotificationService.getJobs() || [];
    this.newJobs = this.NotificationService.getNewJobs() || [];
  }
  durationInSeconds = 3;
  showSnackBar() {
    this.snackbarTimeout && clearTimeout(this.snackbarTimeout);
    this.snackbarOpen = true;
    this.snackbarTimeout = setTimeout(() => {
      this.snackbarOpen = false;
    }, this.durationInSeconds * 1000);
  }
  ngOnInit() {
    WebsocketService.Instance().subscribe(ws => {
      ws.on<Job[]>("Job.Added").subscribe(job => {
        //console.log("New Job's come", job);
        this.newJob = job && job.length ? job[0] : null;
        !this.menuOpen && this.showSnackBar();
        this.jobs = job.concat(this.jobs);
        this.newJobs = this.newJobs.concat(job);
        this.NotificationService.setJobs(this.jobs, this.newJobs);
      });
      ws.on<Job[]>("Job.Modified").subscribe(mjobs => {
        let jobdic: { [id: string]: Job } = {};
        this.jobs.forEach(j => { jobdic[j.Id] = j; });
        mjobs.forEach(j => { if (jobdic[j.Id] != null) { jobdic[j.Id].JobStatus = j.JobStatus; jobdic[j.Id].Status = j.Status; jobdic[j.Id].Finished = j.Finished; } });
        this.jobs = Object.values(jobdic);
        this.NotificationService.setJobs(this.jobs, null);
      });
    });
  }

  onClickedOutside(e: Event) {
    console.log('Clicked outside:', e);
    if (this.menuOpen) {
      this.menuOpen = false;
    }
  }

  nullifyNewJobs() {
    this.newJobs = [];
    this.NotificationService.setJobs(null, this.newJobs);
  }

  showJobsMenu(event) {
    !this.menuOpen && event.stopPropagation();
    this.nullifyNewJobs();
    this.menuOpen = true;
    this.snackbarOpen = false;
    //this._snackBar.dismiss();
  }

  closeJobsMenu() {
    this.nullifyNewJobs();
    this.menuOpen = false;
    //this.menuTrigger.closeMenu();
  }

  getStatus(job: Job) {
    return Job.GetJobStatus(job.JobStatus);
  }

  getDate(job: Job) {
    return new Date(job.Started).toLocaleString();
  }

  removeAll() {
    this.jobs = [];
    this.NotificationService.setJobs(this.jobs, null);
    this.closeJobsMenu();
  }

  //removeJob(event, index: number) {
  //  this.jobs.length > 1 && event.stopPropagation();
  //  this.jobs.splice(index, 1);
  //}

}
