import { AbstractControl, ValidatorFn, ValidationErrors, AsyncValidatorFn, FormGroup } from "@angular/forms";

export class Customvalidators {
  public static validateIp(ipaddress) {
    if (/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(ipaddress)) {
      return (true)
    }
    return (false)
  }


  public static IpValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(control.value))) {
        return { 'Ip': true };
      }
      return null;
    };
  }
  public static VmNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let value = control.value != null ? control.value+"": "";
      if (value.includes("_") || value.includes(".")) {
        return { 'VmName': true };
      }
      return null;
    };
  }
  public static FileNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      var rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
      var rg2 = /^\./; // cannot start with dot (.)
      var rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names  
      var fname = control.value;
      if (!(rg1.test(fname) && !rg2.test(fname) && !rg3.test(fname))) {
        return { 'FileName': true };
      }
      return null;
    };
  }

  public static ageRangeValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {
        return { 'ageRange': true };
      }
      return null;
    };
  }
  public static TouchIvalidControl(fg: FormGroup) {
    Object.keys(fg.controls).forEach(function (key) {
      fg.controls[key].markAsUntouched();
    
    });
    fg.markAllAsTouched();
  }
 }

export interface IUserCred {
  UserName: string;
  Password: string;
  PasswordConform: string;
};
export function PasswordValidator(data: IUserCred): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value == null || control.value.length == 0) {
      return { 'required': true };
    }
    if (data.Password == null || data.PasswordConform == null || data.Password.length == 0 || data.PasswordConform.length == 0)
      return null;
    data.Password = data.Password + '';
    data.PasswordConform = data.PasswordConform + '';
    if (data.Password != data.PasswordConform) {
      return { 'notsame': true };
    }
    return null;
  };
}
