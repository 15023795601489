//inner
import { Component,  OnInit,  ChangeDetectorRef,  EventEmitter, Input, Output } from '@angular/core';
import { IModalWindowSettings } from '..';
import { BehaviorSubject, Subscription } from 'rxjs';
import { VisibleComponent } from '../visiblecomponent';


@Component({
  selector: 'summary-component',
  templateUrl: './Summary.component.html'
})

export class SummaryComponent{
  _data: { Name: any, Value: any, type?: number }[] = [];
  @Input()
  get data() {
    return this._data;
  }
  set data(val) {
    this._data = val;
  }

}
