export class Alarm {
  Id: string;
  Type: number;
  Time: Date;
  Measurement: string;
  Parameter: number;
  Source_Id: string;
  Source: object;
  Info: string;
  RepeatCount: number;
  Resolved: boolean
}

export enum WarningTypes {
  Warning = 1,
  Error,
}
