import { Component,  OnInit, ElementRef, Input, EventEmitter, Output } from '@angular/core';

import { Observable, BehaviorSubject} from 'rxjs';
import { Helper } from '../../../../classes';
import { ValidatorFn, AsyncValidatorFn, FormGroup, AbstractControl, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { VInputModel } from '..';



@Component({
  selector: 'v-password',
  templateUrl: './VPassword.component.html'
})

export class VPassword{
  public showpass = false;
  @Output()
  public valueChange = new EventEmitter<string>();
  @Input()
  set value(val) {
    if (this._value == val) return;
    this._value = val;
    if (this.model != null && this.model.FormControl.value != val) {
      this.model.FormControl.setValue(val);
    }
    this.valueChange.emit(this._value);
  }
  get value() {
    return this._value;
  }
  private _value: string;

  @Input()
  set model(val) {
    if (val == null) return;
    this._model = val;
    let that = this;
    let value = this.value;
    this._model.FormControl.valueChanges.subscribe(v => {
      that.value = v;
    });
    if (Helper.NotEmpty(value))
      this.model.FormControl.setValue(value);
  }
  get model() {
    return this._model;
  }
  private _model = VInputModel.Defualt();

  public get Label() {
    return  this.model.Label;
  }
  public get errors() {
    return this.model.errors;
  }
 
  public Validate() {
    this.model.Validate();
  }
  public hasError(key) {
    return this.model.FormControl.hasError(key);
  }
  public get Matcher() {
    return this.model.Matcher;
  }
  public get isdefault() {
    return this.model.isdefault;
  }
  public get FormControl() {
    return this.model.FormControl;
  }
}
