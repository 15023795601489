import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'group-box',
  templateUrl: './GroupBox.component.html'
})
export class GroupBox implements OnInit {
  constructor() {

  }
  ngOnInit(): void {
  
  }
  @Input()
  set title(val: string) {
    this._title = val;
  }
  get title(): string {
    return this._title;
  }
  private _title: any;
}
