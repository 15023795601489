<mat-form-field class="mat-filter left-block margin-right16  margin-top16 mat-datepicker-blue">
  <input matInput [ngxMatDatetimePicker]="picker" placeholder="{{model.Label}}" [formControl]="FormControl" [errorStateMatcher]="Matcher"
         [min]="dtsettings.minDate" [max]="dtsettings.maxDate" [disabled]="dtsettings.disabled">
  <mat-error *ngFor="let item of errors" [ngClass]="hasError(item.key)?'':'hide'">{{item.value}}</mat-error>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <ngx-mat-datetime-picker #picker [showSpinners]="dtsettings.showSpinners" [showSeconds]="dtsettings.showSeconds"
                           [stepHour]="dtsettings.stepHour" [stepMinute]="dtsettings.stepMinute" [stepSecond]="dtsettings.stepSecond"
                           [touchUi]="dtsettings.touchUi"  [enableMeridian]="dtsettings.enableMeridian"
                           [disableMinute]="dtsettings.disableMinute" [hideTime]="dtsettings.hideTime">
  </ngx-mat-datetime-picker>
</mat-form-field>
