<mat-icon (click)="showJobsMenu($event)"
          [ngClass]="{'disabled': jobs.length == 0}"
          class="jobs-notification-item"
          aria-hidden="false"
          matBadgeSize="small"
          [matBadge]="newJobs.length"
          matBadgeColor="warn"
          [matBadgeHidden]="newJobs.length == 0 || menuOpen"
          matBadgePosition="below">
</mat-icon>
<div class="notificatin-snackbar" *ngIf="snackbarOpen">
  <span>You have a new job</span>
  <span (click)="showJobsMenu($event)" class="cursor-pointer">Show</span>
</div>
<div *ngIf="menuOpen" (clickOutside)="onClickedOutside($event)" class="jobs-notification-menu">
  <div (click)="$event.stopPropagation()">
    <div class="jobs-notification-header align-center">
      <h4>Notifications</h4>
      <span (click)="closeJobsMenu()" class="icon-remove cursor-pointer"></span>
    </div>
    <div class="jobs-notification-sub-header align-center">
      <span>
        <span class="icon-passwordshow"></span>
        <a [routerLink]="'/jobs'">View all jobs </a>
      </span>
      <span (click)="removeAll()">
        <span class="icon-ignore"></span>
        Ignore all jobs
      </span>
    </div>
    <div class="jobs-notification-menu-jobs-container gray-scrollbar">
      <div *ngFor="let job of jobs; index as i" class="jobs-notification-menu-job background-white">
        <div>
          <span class="font-weight-semi-bold">
            {{job.Name}}
          </span>
          <span class="jobs-notification-menu-job-date">
            {{getDate(job)}}
            <!--<mat-icon (click)="removeJob($event, i)">clear</mat-icon>-->
          </span>
        </div>
        <div>
          <span>
            {{job?.Description}}
          </span>
          <span>
            {{getStatus(job)}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
