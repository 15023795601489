import { Component, Injectable, Input, Output, EventEmitter } from '@angular/core';
import { PageMenuItem } from '../..';


@Component({
  selector: 'dropdown-menu',
  templateUrl: './DropdownMenu.component.html'
})

export class DropdownMenu{
  @Input() public btype: string = '';
  _items: PageMenuItem[][];
  @Input()
  get items(): PageMenuItem[][] {
    return this._items;
  }
  set items(val: PageMenuItem[][]) {
    this._items = val;
    let res: PageMenuItem[] = [];
    if (this._items!=null)
    this._items.forEach((arr) => {
      arr.forEach((item) => {
        res[res.length] = item;
       
      });
    });
    this.menuitems = res;
  }

  public menuitems: PageMenuItem[] = [];
  get itemsvisible() {
  return this._items != null && this.menuitems.filter(i=>i.Visible()).length > 0;
  }
}

