import { Component,  OnInit, ElementRef, Input, EventEmitter, Output } from '@angular/core';

import { Observable, BehaviorSubject} from 'rxjs';
import { Helper } from '../../../../classes';

@Component({
  selector: 'spinedit',
  templateUrl: './SpinEdit.component.html'
})

export class SpinEdit implements OnInit {

  @Output()
  public valueChange = new EventEmitter<number>();
  @Input()
  get value() {
    return this._value;
  }
  set value(val) {
    if (val != this._value) {
      this._value = val;
      this.valueChange.emit(val);
    }
  }
  private _value: number;
  @Input()
  get min() {
    return this._min;
  }
  set min(val) {
    this._min = val;
  }
  private _min?: number = 0;
  @Input()
  get max() {
    return this._max;
  }
  set max(val) {
    this._max = val;
  }
  private _max?: number = null;
  @Input()
  get step() {
    return this._step;
  }
  set step(val) {
    this._step = val;
  }
  private _step: number=1;

  constructor(private elRef: ElementRef) { }

  ngOnInit(): void {
    let root = this.elRef.nativeElement;
    let that = this;
   
  }
  public Plus() {
    if (this.value == null)
      this.value = 0
    else
      this.value = this.value + this.step;
  }
  public Minus() {
    if (this.value == null)
      this.value = 0
    else
      this.value = this.value - this.step;
  }
  keypress($event: any) {
    if ($event.keyCode == 13) {
      
    }
  }
  public oninput(event: any) {
    let val = event.target.value;
    console.log(val)
    let value = event.target.value;
    let v = value + '';


    if (v.startsWith('0') && v.length>1) {
      let r = v.substring(1);
      this.value = +r;
    }
  }
  public onBlurMethod() {
    let v = this.value;
    console.log(v);
    if (v < this.min || isNaN(v))
      v = this.min;
    if (this.max != null && v > this.max)
      v = this.max;
    if (v != null) {
      let p = Math.trunc( v / this.step);
      v = this.step * p;
    }
    this.value = v;
  }
}

//
