export class replicaserver {
  public Id: string;
  public Type: string;
  public Name: string;
  public Description: string;
}
export class Certificate {
  Subject?: any;
  Issuer?: any;
  NotAfter?: any;
  IntendedPurpose?: any;
  constructor() { }
}

export enum VmMigrationAuthenticationType {
  CredSSP,
  Kerberos
}
export enum VmMigrationPerformanceOptionType {
  TCPIP,
  Compression,
  SMB
}
export class GeneralVMHostSettings {
  public NumaSpanningEnabled: boolean;
  public EnableEnhancedSessionMode: boolean;
  public VirtualMachinePath: string;
  public VirtualHardDiskPath: string;
  public IsMigrationsEnabled: boolean;
  public MaximumStorageMigrations: number;
  public MaximumVirtualMachineMigrations: number;
  public VirtualMachineMigrationAuthenticationType: VmMigrationAuthenticationType;
  public VirtualMachineMigrationPerformanceOption: VmMigrationPerformanceOptionType;
  public Shared: boolean;
}

export class ReplicationVMSettings {
  ReplicaServerName: any;
  ReplicaServerPort: any;
  AuthenticationType: RecoveryAuthenticationType;
  CompressionEnabled: boolean;
  ReplicationFrequencySec: number;
  RecoveryHistoryEnabled: boolean;
  RecoveryHistory: number;
  VSSSnapshotReplicationEnabled: boolean;
  VSSSnapshotFrequencyHour: number;
  InitialReplicationStartImmediately: boolean;
  InitialReplicationStartTime: Date;
  AutoResynchronizeEnabled: boolean;
  AutoResynchronizeIntervalEnabled: boolean;
  AutoResynchronizeIntervalStart: string;
  AutoResynchronizeIntervalEnd: string;
  InitialReplicationPath: any;
  InitialReplicationUseBackup: boolean;
  ExcludedVhdPath: any[];
  HardDrives: any[];
  ReplicationRelationshipType?: number;
};
export enum InitialReplicationMode { Unknown = 0, Network, ExternalMedia, ExistingVM }
export enum RecoveryAuthenticationType {
  Unknown,
  Kerberos = 1,
  Certificate,
  CertificateAndKerberos = Kerberos | Certificate
}
export class ReplicationVMHostSettings {
  public ReplicationEnabled: boolean;
   public AllowedAuthenticationType: any;
  public ReplicationAllowedFromAnyServer: boolean;
  public KerberosAuthenticationPort: number;
  public CertificateAuthenticationPort: number;
  public CertificateThumbprint: string;
  public DefaultStorageLocation: string;
  public AuthorizationEntries: VMReplicationAuthorizationEntry[];
  //		public List < VMReplicationAuthorizationEntry > AuthorizationEntries
  public Certificate: Certificate;
  public Force: boolean;
}
export class VMReplicationAuthorizationEntry {
  public Id: string;
  public AllowedPrimaryServer: string;
  public ReplicaStorageLocation: string;
  public TrustGroup: string;
}


