import { ContextObject, CategoryGroupLink } from '..';
export class VirtualMachine implements ContextObject{
  Id: string;
  Name: string;
  Description: string;
  ObjectType: string;
  Parent_Id: string;
  Shared: number;
  InternalState: number;
  VmId: string;
  Status: string;
  OSName: string;
  OsVersion: string;
  Heartbeat: number;
  Fqdn: string;
  IpAddresses: string;
  MacAddresses: string;
  JobStatus: string;
  StateText: string;
  State: VMState;
  LicenseState: number;
  Notes: string;
  VisibleNotes: string;
  ProcessorCount: number;
  CpuUsage: number;
  MemoryAssigned: number;
  UptimeTicks: number;
  Uptime: string;
  CreationTime: Date;
  LastUpdated: Date;
  ParentSnapshotId: string;
  MemoryStatus: string;
  MemoryDemand: number;
  ComputerName: string;
  ReplicationHealth: number;
  ReplicationMode: number;
  ReplicationState: number;
  ExtendedReplicationHealth: number;
  ExtendedReplicationState: number;
  TestVmId: string;
  IsClustered: boolean;
  OtherEnabledState: string;
  OperationalStatusText: string;
  Generation: number;
  Version: string;
  IntegrationServicesState: string;
  IntegrationServicesVersion: string;
  ConfigurationLocation: string;
  Path: string;
  DynamicMemoryEnabled: boolean;
  MemoryMinimum: number;
  MemoryMaximum: number;
  IsSaved: boolean;
  IsSaving: boolean;
  IsStopped: boolean;
  IsStopping: boolean;
  IsRunning: boolean;
  IsStarting: boolean;
  IsPaused: boolean;
  IsPausing: boolean;
  IsReplica: boolean;
  IsUnknown: boolean;
  IsStatusCritical: boolean;
  IsReplicaCritical: boolean;
  IsCritical: boolean;
  IsStatusWarning: boolean;
  IsReplicaWarning: boolean;
  IsWarning: boolean;
  CategoryGroupLinks: CategoryGroupLink[];
  Selected: boolean = false;
  public static LicenseStateIsOk(vm) {
    return vm.LicenseState=="OK"
  }
}
export enum VMReplicationMode {
  None,
  Primary,
  Replica,
  TestReplica,
  ExtendedReplica
}
export enum VMReplicationState {
  Disabled = 0,
  ReadyForInitialReplication,
  InitialReplicationInProgress,
  WaitingForInitialReplication,
  Replicating,
  PreparedForFailover,
  FailedOverWaitingCompletion,
  FailedOver,
  Suspended,
  Error,
  WaitingForStartResynchronize,
  Resynchronizing,
  ResynchronizeSuspended,
  RecoveryInProgress,
  FailbackInProgress,
  FailbackComplete,
  WaitingForUpdateCompletion,
  UpdateError,
  WaitingForRepurposeCompletion,
  PreparedForSyncReplication,
  PreparedForGroupReverseReplication,
  FiredrillInProgress
}
export class VMCheckpoint {
  Id: string;
  Name: string;
  ParentCheckpointId: string;
  VirtualMachine_Id: string;
  ChekpointId: string;
}

export enum VMState {
  NotInScope = 0,
  Other = 1,
  Running,
  Off,
  Stopping,
  Saved = 6,
  Paused = 9,
  Starting,
  Reset,
  Saving = 32773,
  Pausing = 32776,
  Resuming,
  FastSaved = 32779,
  FastSaving,
  ForceShutdown,
  ForceReboot,
  RunningCritical,
  OffCritical,
  StoppingCritical,
  SavedCritical,
  PausedCritical,
  StartingCritical,
  ResetCritical,
  SavingCritical,
  PausingCritical,
  ResumingCritical,
  FastSavedCritical,
  FastSavingCritical
}

export const VMStateTexts = {
  0: 'NotInScope',
  1: 'Other',
  2: 'Running',
  3: 'Off',
  4: 'Stopping',
  6: 'Saved',
  9: 'Paused',
  10: 'Starting',
  11: 'Reset',
  32773: 'Saving',
  32776: 'Pausing',
  32777: 'Resuming',
  32779: 'Fast Saved',
  32780: 'Fast Saving',
  32781: 'Force Shutdown',
  32782: 'Force Reboot',
  32783: 'Running Critical',
  32784: 'Off Critical',
  32785: 'Stopping Critical',
  32786: 'Saved Critical',
  32787: 'Paused Critical',
  32788: 'Starting Shutdown',
  32789: 'Reset Reboot',
  32790: 'Saving Critical',
  32791: 'Pausing Critical',
  32792: 'Resuming Critical',
  32793: 'Fast Saved Critical',
  32794: 'Fast Saving Critical',
}
export class DirectoryConstants {
  public static VirtualMachineDirectory = "Virtual Machines";
  public static  SnapshotsDirectory = "Snapshots";
  public static  VhdDirectory = "Virtual Hard Disks";
  public static  SmartPagingDirectory = "Smart paging";
  public static  CheckpointsDirectory = "Checkpoints";
  public static  ConfigurationDirectory = "Configuration";
  public static SystemReserved = "System Reserved";
  public static CreateStorageStubPath(id) {
    return 'Storage:{'+id+'}';
  }
}
