import { Routes, Route, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { GuacamoleBoardComponent } from './views/Guacamole/GuacamoleBoard/GuacamoleBoard.component';
import { LoginComponent, AppJobs } from './views/AppComponents';
import { HttpService } from './services';
import { AdminGuard, AuthGuard } from './guards';
import { RouteNames } from './classes';

export class AppRoutes {

  static VirtualMachinesRoute: Route = {
    path: RouteNames.VirtualMachines,
    data: {
      title: 'Virtual machines',
      topTitle: "Hyper-V",
      active: false,
      children: [],
      inf: RouteNames.VirtualMachines,
      icon: 'menuicon-vm'
    },
    loadChildren: () => import('./views/HyperVManagement/VirtualMachines/VirtualMachines.module').then(m => m.VirtualMachinesModule),
    canActivate: [AuthGuard]
  };

  static VirtualSwitchRoute: Route = {
    path: RouteNames.VirtualSwitches,
    data: {
      title: 'Virtual switches',
      topTitle: "Hyper-V",
      active: false,
      children: [],
      inf: RouteNames.VirtualSwitches,
      icon: 'menuicon-vswitch'
    },
    loadChildren: () => import('./views/HyperVManagement/VirtualSwitch/VirtualSwitch.module').then(m => m.VirtualSwitchModule),
    canActivate: [AuthGuard]
  };
  static DataStoreRoute: Route = {
    path: RouteNames.DataStore,
    data: {
      title: 'Data storages',
      topTitle: "Hyper-V",
      active: false,
      children: [],
      inf: RouteNames.DataStore,
      icon:'menuicon-dstore'
    },
    loadChildren: () => import('./views/HyperVManagement/DataStore/DataStore.module').then(m => m.DataStoreModule),
    canActivate: [AuthGuard]
  };
  static hypperVManagmentMenu: Routes = [
    AppRoutes.VirtualMachinesRoute, AppRoutes.VirtualSwitchRoute, AppRoutes.DataStoreRoute
  ];

  static hyperVManagmentRoute: Route = {
    path: RouteNames.HyperVManagement,
    data: {
      title: 'Hyper-V Managment',
      class: 'hyper-v',
      children: AppRoutes.hypperVManagmentMenu,
      active: false,
      inf: RouteNames.HyperVManagement,
    },
    children: [
      {
        path: '',
        redirectTo: RouteNames.VirtualMachines,
        pathMatch: 'full',
        data: {}
      },
      AppRoutes.VirtualMachinesRoute,
      AppRoutes.VirtualSwitchRoute,
      AppRoutes.DataStoreRoute
    ],
    canActivate: [AuthGuard]
  };
  static ReportManagmentRoute: Route = {
    path: RouteNames.Reporting,
    data: {
      title: 'Reporting',
      class: 'icon-reporting',
      children: [],
      titleInsteadOfSubtitle: true,
      active: false
    },
    loadChildren: () => import('./views/ReportManagment/Reporting.module').then(m => m.ReportingModule),
    canActivate: [AuthGuard]
  };

  /* Administration Routes */

  static AdministrationUsers: Route = {
    path: RouteNames.AdministrationUsers,
    data: {
      title: 'Users',
      class: 'administration-users',
      active: false,
      children: [],
      inf: 'administration-users',
      icon: 'icon-users'
    },
    loadChildren: () => import('./views/Administration/Users/AdministrationUsers.module').then(m => m.AdministrationUsersModule),
    canActivate: [AuthGuard, AdminGuard]
  };
  static AdministrationTenants: Route = {
    path: RouteNames.AdministrationTenants,
    data: {
      title: 'Tenants',
      class: 'administration-tenants',
      active: false,
      children: [],
      inf: 'administration-tenants',
      icon: 'icon-tenants'
    },
    loadChildren: () => import('./views/Administration/Tenants/AdministrationTenants.module').then(m => m.AdministrationTenantsModule),
    canActivate: [AuthGuard, AdminGuard]
  };
  static AdministrationRoles: Route = {
    path: RouteNames.AdministrationRoles,
    data: {
      title: 'Roles',
      class: 'administration-roles',
      active: false,
      children: [],
      inf: 'administration-roles',
      icon: 'icon-roles'
    },
    loadChildren: () => import('./views/Administration/Roles/AdministrationRoles.module').then(m => m.AdministrationRolesModule),
    canActivate: [AuthGuard, AdminGuard]
  };
  static AdministrationCategories: Route = {
    path: RouteNames.AdministrationCategories,
    data: {
      title: 'Categories',
      class: 'administration-categories',
      active: false,
      children: [],
      inf: 'administration-categories',
      icon: 'icon-category'
    },
    loadChildren: () => import('./views/Administration/Categories/AdministrationCategories.module').then(m => m.AdministrationCategoriesModule),
    canActivate: [AuthGuard, AdminGuard]
  };


  static AdministrationRoute: Route = {
    path: "administration",
    data: {
      title: 'Administration',
      class: 'administration',
      active: false,
      inf: 'administration',
      forAdmins: true,
      titleInsteadOfSubtitle: true,
      infrastructureAndLogicalHidden: true,
      children: [
        AppRoutes.AdministrationUsers,
        AppRoutes.AdministrationTenants,
        AppRoutes.AdministrationRoles,
        AppRoutes.AdministrationCategories,
      ],
    },
    children: [
      {
        path: '',
        redirectTo: RouteNames.AdministrationUsers,
        pathMatch: 'full',
        data: {}
      },
      AppRoutes.AdministrationUsers,
      AppRoutes.AdministrationTenants,
      AppRoutes.AdministrationRoles,
      AppRoutes.AdministrationCategories,
    ],
    canActivate: [AuthGuard, AdminGuard]
  };

  /* Administration Routes */

  /* Monitoring Routes */

  static MonitoringSummary: Route = {
    path: RouteNames.MonitoringSummary,
    data: {
      title: 'Summary',
      class: 'monitoring-summary',
      active: false,
      children: [],
      inf: 'monitoring-summary',

    },
    loadChildren: () => import('./views/Monitoring/Summary/MonitoringSummary.module').then(m => m.MonitoringSummaryModule),
    canActivate: [AuthGuard]
  };

  static MonitoringCPU: Route = {
    path: RouteNames.MonitoringCPU,
    data: {
      title: 'CPU',
      class: 'monitoring-cpu',
      active: false,
      children: [],
      inf: 'monitoring-cpu',

    },
    loadChildren: () => import('./views/Monitoring/CPU/MonitoringCPU.module').then(m => m.MonitoringCPUModule),
    canActivate: [AuthGuard]
  };

  static MonitoringMemory: Route = {
    path: RouteNames.MonitoringMemory,
    data: {
      title: 'Memory',
      class: 'monitoring-memory',
      active: false,
      children: [],
      inf: 'monitoring-memory',
    },
    loadChildren: () => import('./views/Monitoring/Memory/MonitoringMemory.module').then(m => m.MonitoringMemoryModule),
    canActivate: [AuthGuard]
  };

  static MonitoringNetwork: Route = {
    path: RouteNames.MonitoringNetwork,
    data: {
      title: 'Network',
      class: 'monitoring-network',
      active: false,
      children: [],
      inf: 'monitoring-network',
    },
    loadChildren: () => import('./views/Monitoring/Network/MonitoringNetwork.module').then(m => m.MonitoringNetworkModule),
    canActivate: [AuthGuard]
  };

  static MonitoringDisk: Route = {
    path: RouteNames.MonitoringDisk,
    data: {
      title: 'Disk',
      class: 'monitoring-disk',
      active: false,
      children: [],
      inf: 'monitoring-disk',
    },
    loadChildren: () => import('./views/Monitoring/Disk/MonitoringDisk.module').then(m => m.MonitoringDiskModule),
    canActivate: [AuthGuard]
  };

  static MonitoringRoute: Route = {
    path: RouteNames.Monitoring,
    data: {
      title: 'Monitoring',
      class: RouteNames.Monitoring,
      active: false,
      dynamicChildren: true,
      children: [
        AppRoutes.MonitoringSummary,
        AppRoutes.MonitoringCPU,
        AppRoutes.MonitoringMemory,
        AppRoutes.MonitoringNetwork,
        AppRoutes.MonitoringDisk
      ],
      secondLevelMenuHidden: true,
      inf: RouteNames.Monitoring
    },
    children: [
      {
        path: '',
        redirectTo: RouteNames.MonitoringSummary,
        pathMatch: 'full',
        data: {}
      },
      AppRoutes.MonitoringSummary,
      AppRoutes.MonitoringCPU,
      AppRoutes.MonitoringMemory,
      AppRoutes.MonitoringNetwork,
      AppRoutes.MonitoringDisk
    ],
    canActivate: [AuthGuard]
  };

  /* Monitoring Routes */

  /* Azure Routes */
  
  static AzureManagement: Route = {
    path: RouteNames.Azure,
    data: {
      title: 'VM Management',
      topTitle: 'Azure',
      class: 'azure-management',
      active: false,
      children: [],
      inf: 'azure-management',
    },
    loadChildren: () => import('./views/AzureManagement/AzureManagement/AzureManagement.module').then(m => m.AzureManagementModule),
    canActivate: [AuthGuard]
  };

  static ReplicationManagement: Route = {
    path: RouteNames.ReplicationManagement,
    data: {
      title: 'Replication Management',
      topTitle: 'Azure',
      class: 'replication-management',
      active: false,
      children: [],
      inf: 'replication-management',

    },
    loadChildren: () => import('./views/AzureManagement/ReplicationManagement/ReplicationManagement.module').then(m => m.ReplicationManagementModule),
    canActivate: [AuthGuard]
  };
 
  static AzureManagementRoute: Route = {
    path: RouteNames.AzureManagement,
    data: {
      title: 'Azure Management',
      class: 'Azure',
      infrastructureAndLogicalHidden: true,
      children: [
        AppRoutes.AzureManagement,
        AppRoutes.ReplicationManagement
      ],
      active: false
    },
    children: [
      {
        path: '',
        redirectTo: RouteNames.Azure,
        pathMatch: 'full',
        data: {}
      },
      AppRoutes.AzureManagement,
      AppRoutes.ReplicationManagement
    ],
    canActivate: [AuthGuard]
  };

  /* Azure Routes */


  //static GuacamoleBorardRoute: Route = {
  //  path: GuacamoleBoardComponent.Path,
  //  data: {
  //    title: GuacamoleBoardComponent.Title,
  //    active: false,
  //    children: [],
  //    parent: '',
  //    class: 'guacamole'
  //  },
  //  component: GuacamoleBoardComponent,
  //  canActivate: [AuthGuard]
  //};
  //static guacamoleMenu: Routes = [
  //  AppRoutes.GuacamoleBorardRoute
  //];
  //static GuacamoleRoute: Route = {
  //  path: 'GuestConnections',
  //  data: {
  //    title: 'Guest Connections',
  //    class: "guacamole",
  //    containerclass: 'guacamoleviewport', children: AppRoutes.guacamoleMenu, active: false
  //  },
  //  component: GuacamoleBoardComponent,
  //  canActivate: [AuthGuard]
  //};
 
  static jobsRoute: Route = {
    path: 'jobs',
    data: {
      title: 'Notifications',
      class: "icon-notification",
      children: [], active: false
    },
    component: AppJobs,
    canActivate: [AuthGuard]
  };
  static leftMenu: Routes = [
    AppRoutes.hyperVManagmentRoute,
    AppRoutes.AzureManagementRoute,
   // AppRoutes.GuacamoleRoute,
    AppRoutes.MonitoringRoute,
    AppRoutes.ReportManagmentRoute,
    AppRoutes.AdministrationRoute,
    AppRoutes.jobsRoute
  ];
 
  static root: Routes = [{
    path: '',
    data: { title: "Login" },
    component: LoginComponent
  }];
 
}
