import { Injectable, Input, EventEmitter, Output} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Guid } from '../classes/guid';
import { Job } from '../classes';

export enum MessageType {
  Success = 1,
  Warning,
  Error,
  Info
}

export class Message {
  public Id: string;
  public Title: string;
  public Description: string;
  public Message: string;
  public Type: MessageType;
  public Date: Date;

  constructor(message: string, title?: string, type?: MessageType, descr?: string) {
    this.Id = Guid.NewGuid().ToString();
    this.Message = message || "";
    this.Type = type || MessageType.Error;
    this.Title = title || "Error";
    this.Description = descr || "";
    this.Date = new Date();
  }

  static Success(message: string, title?: string, descr?: string) {
    return new Message(message, title, MessageType.Success, descr);
  }

  static Warning(message: string, title?: string, descr?: string) {
    return new Message(message, title, MessageType.Warning, descr);
  }

  static Error(message: string, title?: string, descr?: string) {
    return new Message(message, title, MessageType.Error, descr);
  }

  static Info(message: string, title?: string, descr?: string) {
    return new Message(message, title, MessageType.Info, descr);
  }
}

export class MessageList {
  public Messages: Array<Message>;
  public SuccsessCount: number;
  public FailCount: number;

  constructor() {
    this.Messages = new Array<Message>();
    this.SuccsessCount = this.FailCount = 0;
  }

  get length(): number {
    return this.Messages.length;
  }

  Add(message: Message) {
    this.Messages.push(message);
    this._recalc();
  }

  Remove(id: string) {
    var index = -1;
    for (var i = 0; i < this.Messages.length; i++) {
      if (this.Messages[i].Id == id) {
        index = i;
        break;
      }
    }

    if (index > -1) {
      var deleted = this.Messages.splice(index, 1);
      this._recalc();
    }
  }

  Clear() {
    this.Messages.length = 0;
    this.Messages = new Array<Message>();
    this.SuccsessCount = this.FailCount = 0;
  }

  private _recalc() {
    this.SuccsessCount = this.FailCount = 0;
    for (var i = 0; i < this.Messages.length; i++) {
      if (this.Messages[i].Type == MessageType.Success)
        this.SuccsessCount++;
      else if (this.Messages[i].Type == MessageType.Error)
        this.FailCount++;
    }
  }
}


@Injectable({ providedIn: 'root' })
export class NotificationService {
    //show loading spinner?
    private loading: boolean; 
    //show local loading spinner?
    private loadingChild: boolean;
    //all messages
    private messages: MessageList;
         
    @Output()
    public loadingObserver = new EventEmitter();

    @Output()
    public loadingChildObserver = new EventEmitter();

    @Output()
    public messageObserver = new EventEmitter();

    constructor() {
        this.loading = false;
        this.loadingChild = false;
        this.messages = new MessageList();
  }

    jobs: Job[] = [];
    newJobs: Job[] = [];

    setJobs(jobs, newJobs) {
      if (jobs) {
        this.jobs = jobs;
      }
      if (newJobs) {
        this.newJobs = newJobs;
      }
    }

    getJobs() {
      return this.jobs;
    }

    getNewJobs() {
      return this.newJobs;
    }

    
    @Input()
    public setLoading(value: boolean, child?: boolean) {
        if (child == true) {
            this.loadingChild = value;
            this.loadingChildObserver.emit(this.loadingChild);
        }
        else {
            this.loading = value;
            this.loadingObserver.emit(this.loading);
        }
        return this.loadingObserver;
    }

    @Input()
    public addMessage(message?: Message) {
      if (message != null) {
          console.log(message.Title + ' ' + message.Description + ' ' + message.Message);
          this.messages.Add(message);
          this.messageObserver.emit(this.messages);
      }
      return this.messageObserver;
    }

    @Input()
    public addSuccess( methodname: string) {
      var message = Message.Success(methodname + ": success", methodname);
      return this.addMessage(message);
    }

    @Input()
    public addError(mes: string, methodname: string, dis: any) {
      let err= JSON.stringify(dis);
      var message = Message.Error(mes, methodname, err);
      return this.addMessage(message);
    }

    @Input()
    public removeMessage(id?: string) {
        if (id != null) {
            this.messages.Remove(id);
            this.messageObserver.emit(this.messages);
        }
        return this.messageObserver;
    }

    @Input()
    public clearMessages() {
        this.messages.Clear();
        this.messageObserver.emit(this.messages);
        return this.messageObserver;
    }

    public getLoadingState() {
        return this.loadingObserver;
    }

    public getLoadingChildState() {
        return this.loadingChildObserver;
    }

    public getMessageObserver() {
        return this.messageObserver;
    }

    
}
