import { Component,  OnInit,   ElementRef, Input, ChangeDetectorRef } from '@angular/core';
import { PageMenuItem } from '../../CommonComponents';
import { HttpService, DataStore, MenuService } from '../../../../services';
@Component({
  selector: 'pagemenu-buttons',
  templateUrl: './PageMenuButtons.component.html'
})

export class PageMenuButtons  {
  _buttons: PageMenuItem[];

  @Input()
  get buttons(): PageMenuItem[] {
    return this._buttons;
  }
  set buttons(val: PageMenuItem[]) {
    this._buttons = val;
  }
}
