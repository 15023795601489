import { Injectable, Input, EventEmitter, Output } from '@angular/core';
import { AppRoutes } from '../app.routes';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { InfrastructureMenuItemNavigationData, InfMenuItem, MenuSettings, WarningTypes, RouteNames, Helper } from '../classes';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { MenuService } from '.';

@Injectable({ providedIn: 'root' })
export class AdministrationService {

  nullObjectId: string = '00000000-0000-0000-0000-000000000000';

  objectTypesMap = {
    'VirtualMachine': 'Virtual Machine',
    'AzureSubscription': 'Azure Subscription',
    'VirtualNetworkSwitch': 'Virtual Network Switch',
    'AzureVirtualMachine': 'Azure Virtual Machine',
  }

  getObjectType(type) {
    return this.objectTypesMap[type] || type;
  }

  constructor(private HttpService: HttpService, private MenuService: MenuService) {
  
  }

  getMenuDataContext(data, subPart, upSuffix = '', upName = '') {
    let res = [];
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      res[i] = InfMenuItem.NewItem(`icon-admin-${item.ItemClass || item.LinkName || 'user'}`, item.Name, item.Id || '', '', `/${RouteNames.Administration}/${subPart}/${item.LinkName || ''}/${item.Id || ''}`);
    }
    let ctx = new InfrastructureMenuItemNavigationData();
    if (upName) {
      ctx.up = `/${RouteNames.Administration}/${subPart}${upSuffix}`;
      ctx.name = upName;
    }
    ctx.data = of(res);
    return ctx;
  }

  getMenuBasicUpLink(suffix, upName = '') {
    let ctx = new InfrastructureMenuItemNavigationData();
    if (upName) {
      ctx.up = `/${RouteNames.Administration}/${suffix}`;
      ctx.name = upName;
    }
    return ctx;
  }

  getUserLink(id) {
    return `/${RouteNames.Administration}/${RouteNames.AdministrationUsers}/${id}`;
  }

  getObjectTypeLowercase(row) {
    return row && row.ObjectType ? row.ObjectType.toLowerCase() : '';
  }

  createOrEditUser(isEdit, requestObject) {
    return this.HttpService[isEdit ? 'put' : 'post'](`core/user/batch`, requestObject);
  }

  createOrEditRole(isEdit, requestObject) {
    return this.HttpService[isEdit ? 'put' : 'post'](`core/role/${isEdit ? requestObject.Id : ''}`, requestObject);
  }

  createOrEditCategory(isEdit, requestObject) {
    return this.HttpService[isEdit ? 'put' : 'post'](`core/category/${!isEdit ? 'batch' : requestObject.Id}`, requestObject);
  }

  createOrEditCategoryGroup(isEdit, requestObject) {
    return this.HttpService.put(`core/category/${isEdit ? 'group' : requestObject.Category_Id}/${isEdit ? requestObject.Id : 'group'}`, requestObject);
  }

  createOrEditGroup(isEdit, requestObject) {
    return this.HttpService[isEdit ? 'put' : 'post'](`core/usergroup/${isEdit ? requestObject.Id : ''}`, requestObject);
  }

  createOrEditGroupRoles(requestObject) {
    return this.HttpService.post(`core/usergroup/ObjectRoles`, requestObject);
  }

  getUsers() {
    return this.HttpService.get(`core/User`, null);
  }

  getGroups() {
    return this.HttpService.get(`core/UserGroup`, null);
  }

  getTenants() {
    return this.HttpService.get(`core/Tenant`, null);
  }

  getOperations() {
    return this.HttpService.get('Core/Operations', null);
  }

  createOrEditTenant(isEdit, requestObject) {
    return this.HttpService[isEdit ? 'put' : 'post'](`core/tenant/batch`, requestObject);
  }

  removeTenant(id, isRemoveUsers, isRemoveGroups) {
    return this.HttpService.delete(`core/tenant/remove/${id}`, null, {
      params: {
        'removeUsers': isRemoveUsers ? 'True' : 'False',
        'removeGroups': isRemoveGroups ? 'True' : 'False'
      }
    });
  }

  getUserObjectRoles(id) {
    return this.HttpService.get(`core/role/${id}/UserObjectRoles`, null);
  }

  removeRole(id, recursive) {
    return this.HttpService.delete(`core/role/${id}?recursive=${recursive}`, null);
  }

  removeGroupRoles(requestObject) {
    return this.HttpService.delete(`core/usergroup/ObjectRoles`, null, {
      body: requestObject
    });
  }
  
  removeUser(id) {
    return this.HttpService.delete(`core/user/${id}`, null);
  }

  removeGroup(id) {
    return this.HttpService.delete(`core/usergroup/${id}`, null, {
      body: {
        "RemoveUsers": false
      }
    });
  }

  removeCategory(id) {
    return this.HttpService.delete(`core/category/${id}`, null);
  }

  getTenant(id) {
    return this.HttpService.get(`Core/Tenant/${id}`, null);
  }

  getTenantUsers(id) {
    return this.HttpService.get(`Core/Tenant/${id}/Users`, null);
  }

  getTenantObjects(id) {
    return this.HttpService.get(`Core/Tenant/${id}/Objects`, null);
  }

  getTenantAvailableUsers(id) {
    return this.HttpService.get(`Core/Tenant/${id}/AvailableUsers`, null);
  }

  getTenantAvailableObjects(id) {
    return this.HttpService.get(`Core/Tenant/${id}/AvailableObjects`, null);
  }

  removeCategoryGroup(id) {
    return this.HttpService.delete(`core/category/group/${id}`, null);
  }

  setPassword(id, password) {
    return this.HttpService.put(`core/user/${id}/password`, password, {
      'Content-Type': 'application/json'
    });
  }

  clearSubscriptions(subscriptions) {
    for (let i = 0; i < subscriptions.length; i++) {
      subscriptions[i].unsubscribe();
    }
    return [];
  }

  handleUserResources(data) {
    Helper.deserializeJSONRefs(data);
    let resources = data.flat(),
      result = [],
      roles = {};
    resources.forEach(resource => {
      let key = resource.Object ? resource.Object.Id : 'ordinaryResource';
      if (!roles[key]) {
        roles[key] = resource.Roles;
        result.push(resource);
      } else {
        //handle repeating roles from other groups
        let existingRoles = roles[key].map(role => role.Id),
          newDistinctRoles = resource.Roles.filter(role => existingRoles.indexOf(role.Id) === -1);
        roles[key] = [...roles[key], ...newDistinctRoles];
      }
    });
    result.forEach(el => {
      let key = el.Object ? el.Object.Id : 'ordinaryResource';
      if (roles[key]) {
        el.Roles = roles[key];
      }
    })
    console.log("resources user", result);
    return result;
  }

  handleTenantsRedirectOrVisibility(redirect = false) {
    this.HttpService.getCurrentUser().then(user => {
      if (user.IsTenantAdmin) {
        if (redirect) {
          this.HttpService.navigate([RouteNames.Administration, RouteNames.AdministrationUsers, RouteNames.AdministrationTenant, user.Tenant_Id]);
        } else {
          this.MenuService.SetChildrenData([RouteNames.AdministrationTenants]);
        }
      }
    })
  }
}
