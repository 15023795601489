import { Component, Input} from '@angular/core';
import { HttpService,  NotificationService } from '../../../../services';

@Component({
  selector: 'menu-user-info',
  templateUrl: 'MenuUserInfo.template.html'
})

export class MenuUserInfoComponent  {
 

  @Input() sources: Array<object> = [];
  @Input() loading: boolean = false;

  constructor(public NotificationService: NotificationService,  private HttpService: HttpService) {
  }


  logout() {
    this.NotificationService.setLoading(false);
    this.HttpService.Logout();
  }


}

