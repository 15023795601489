import {EventEmitter } from '@angular/core';
import { ControllerType, VMNetworkAdapter, GuestNetworkAdapterConfiguration, OnOffState, ReplicationVMSettings } from '..';
import { VhdDrive, DvdDrive, ControllerTypeString, ValueTypeExtensions } from './vhdrive';
import { HostSettings } from './hostsettings';
import { Guid } from '../guid';
export interface IController {
  Id: string;
  Name: string;
  //KeyName: string;
  ControllerNumber: number;
  ControllerType: ControllerType;
  LocationsCount: number;
  open: boolean;
}

export class VMIdeController implements IController {
  public Id: string;
  public Name: string;
  //public get KeyName() { return ControllerTypeString(this.ControllerType) + '\\' + this.ControllerNumber; }
  //public set KeyName(val) { }
  public ControllerNumber: number;
  public ControllerType = ControllerType.IDE;
  public LocationsCount = 2;
  public open = true;
}
export class VMScsiController implements IController {
  public static MaxCount = 4;
  public Id: string;
  public Name: string;
  //public get KeyName() { return ControllerTypeString(this.ControllerType) + '\\' + this.ControllerNumber; }
  //public set KeyName(val) { }
  public ControllerNumber: number;
  public ControllerType = ControllerType.SCSI;
  public LocationsCount = 64;
  public open = true;
}
export enum VMCreationType {
  NewVM,
  Template,
  ExistingVM
}
export class VirtualMachineSettings {
  public HostSettings: HostSettings;
  public Id: string | null;
  public Name: string;
  public Notes: string
  public Version: string;
  public VisibleNotes: string;
  public Generation: number = 2;
  public Path: string;
  public StoragePath: string
  public RelativePath: string;
  public NumaNodesCount: number;
  public GuestOptions: VMGuestOptions = new VMGuestOptions();
  public Processor: VMProcessor;
  public Memory: VMMemory;
  public ReplicationSettings: ReplicationVMSettings = new ReplicationVMSettings();
  public CategoryGroups: {Id:string}[]=[];
  public VmNetworkAdapters: VMNetworkAdapter[]=[];
  public FibreChannelHostBusAdapters: any[]=[];
  public OsInstallation = VmOsInstallation.Later;
  public OsPhysicalDvdPath: string;
  public OsIsoPath: string;
  public OsPhysicalFloppyPath: string;
  public OsVfdPath: string;
  public AutomaticStartAction = VmAutoStartAction.Nothing;
  public AutomaticStartDelay: number;
  public AutomaticStopAction = VmAutoStopAction.TurnOff;
  public VMIntegrationService: { Id: string, Name: string, Enabled: boolean }[]=[];
  public StartAfterCreation: boolean;
  public ConfigurationLocation: string;
  public SnapshotFileLocation: string;
  public CreationTime: Date | null;
  public IsClustered: boolean=false;
  public CreationType: any | null;
  public Bios: VMBios = new VMBios();
  public Firmware: VMFirmware = new VMFirmware();
  public VmIdeControllers: VMIdeController[]=[];
  public VmScsiControllers: VMScsiController[]=[];
  public VmHardDrives: VhdDrive[]=[];
  public VmDvdDrives: DvdDrive[] = [];

  public static Prepare(settings: VirtualMachineSettings) {
    let type: VMCreationType = settings.CreationType;
    switch (type) {
      case 0: settings.CreationType = 'NewVM'; break;
      case 1: settings.CreationType = 'Template'; break;
      case 2: settings.CreationType = 'ExistingVM'; break;
    }
  }
}

export class VMGuestOptions {
  public static get MaxComputerNameLength() { return 15; }
  //   private Dictionary < string, string > _runOnce = new Dictionary<string, string>();
  public static Prepare(obj: VMGuestOptions) {
    if (obj.ComputerName.length > 15) {
      obj.ComputerName = obj.ComputerName.substr(0, VMGuestOptions.MaxComputerNameLength);
    }
    return obj;
  }
  public ComputerName: string;
  public LocalPassword: string;
  public TimeZoneId: string = "Russian Standard Time";
  public ProductKey: string;
  public MemberType=MemberType.Workgroup;
  public Workgroup = "WORKGROUP";
  public Domain: string;
  public DomainUsername: string;
  public DomainPassword: string;
  public FirstLogonCommands: FirstLogonCommand[] = [];
  public LocalAccounts: LocalAccount[] = [];
  public PersistAllDeviceInstalls = false;
  public InternationalSettings: InternationalSettings = new InternationalSettings();
  public GuestNetworkAdapterConfigurations: GuestNetworkAdapterConfiguration[] = [];
  public RunOnce: { [id: string]: string } = {};

}

export enum MemberType {
  Workgroup,
  Domain
}
export class InternationalSettings {
  public static EnUsLocale = "en-US";
  public InputLocale = InternationalSettings.EnUsLocale;
  public  SystemLocale  =  InternationalSettings.EnUsLocale;
  // ReSharper disable once InconsistentNaming
  public  UILanguage =  InternationalSettings.EnUsLocale;
  public  UserLocale  =  InternationalSettings.EnUsLocale;
}
export enum VMBiosBootDevice {
  Floppy = 0,
  CD,
  IDE,
  LegacyNetworkAdapter,
  NetworkAdapter,
  VHD
}
export class VMFirmware 
{
  public SecureBoot= OnOffState.On;
  public BootOrder: VMBootSource[] = [];
  public BootOrderAsString: string;
 }
export enum VMBootSourceType {
  Unknown = 0,
  Drive,
  Network,
  File
}
export class VMBootSource {
  public tid :string;
  public BootType: VMBootSourceType;
  public Description: string;
  public FirmwarePath: string;
  public Value: string;
  public Type: string;
}
export class VMBios 
{
  public StartupOrder: VMBiosBootDevice[] = [];
  public StartupOrderAsString: string;
}
export class LocalAccount {
  public Name: string;
  public Password: string;
  public DisplayName: string;
  public Group: string;
}


export class FirstLogonCommand {
  public CommandLine: string;
  public Description: string;
  public ScriptFilesPath: string[] = [];}

export enum VmOsInstallation {
  Later,
  FromDvd,
  FromFloppy,
  FromNetwork
}
export enum VmAutoStartAction {
  Unknown,
  Nothing = 2,
  StartIfRunning,
  Start
}
export enum VmAutoStopAction {
  Unknown,
  TurnOff = 2,
  Save,
  ShutDown
}


export class VMProcessor {
  public Id: string;
  public Count: number;
  public CompatibilityForMigrationEnabled: boolean = false;
  public Maximum: number;
  public Reserve: number;
  public RelativeWeight: number;
  public MaximumCountPerNumaSocket: number;
  public MaximumCountPerNumaNode: number;
  public static Default(processorSettingsDefault) {
    let res = new VMProcessor();
    res.Count = processorSettingsDefault.VirtualQuantity;
    res.Reserve = 0;
    res.Maximum = 100;
    res.RelativeWeight = processorSettingsDefault.Weight;
    res.MaximumCountPerNumaSocket = processorSettingsDefault.MaxNumaNodesPerSocket;
    res.MaximumCountPerNumaNode = processorSettingsDefault.MaxProcessorsPerNumaNode;
    return res;
  }
}
export class VMMemory {
  public Id: string;
  public Startup: number;
  public DynamicMemoryEnabled: boolean;
  public Minimum: number;
  public Maximum: number;
  public MaximumPerNumaNode: number;
  public Buffer: number;
  public Priority: number;
  //public long MaximumAmountPerNumaNodeBytes => MaximumPerNumaNode.ConvertMegabytesToBytes();
  public static Default(memorySettingsDefault) {
    let Memory = new VMMemory();
    Memory.Startup = memorySettingsDefault.VirtualQuantity * ValueTypeExtensions.Mega;
    Memory.DynamicMemoryEnabled = memorySettingsDefault.DynamicMemoryEnabled;
    Memory.Minimum = memorySettingsDefault.Reservation * ValueTypeExtensions.Mega;
    Memory.Maximum = memorySettingsDefault.Limit * ValueTypeExtensions.Mega;
    Memory.Buffer = memorySettingsDefault.TargetMemoryBuffer;
    Memory.Priority = 50;
    Memory.MaximumPerNumaNode = memorySettingsDefault.MaxMemoryBlocksPerNumaNode;
    return Memory;
  }
} 
