import { Injectable } from '@angular/core';
import { CanLoad, CanActivate, Route, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpService } from '../services/http.service';
import { HttpClient } from '@angular/common/http';
import { RouteNames } from '../classes';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private authService: HttpService, private router: Router, private httpController: HttpService) {
  }
  //canLoad(route: Route): boolean {
  //  let url: string = route.path;
  //  return true;
  //}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.getCurrentUser().then(res => {
      //console.log("AdminGuardAdminGuard", res);
      if (res["IsGlobalAdmin"] || res["IsTenantAdmin"]) {
        return true;
      } else {
        this.router.navigate([RouteNames.HyperVManagement]);
        // abort current navigation
        return false;
      }
    });
  }
} 
