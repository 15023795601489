 <div class="mw-layout mws-container " [ngClass]="visible? 'mw-show':''">
    <div class="mws-wrapper">
      <div class="mws-content scrollbar1">
        <div class="mw-cwrapper">
          <div class="mw-wrapper">
            <div class="mw-container">
              <div class="mv-content" style="display:block">
                <div class="dialog" *ngIf="dialog.init">
                  <div class="dialog-top">
                    <p class="dialog-title">{{dialog.GetTitle()}}</p>
                    <div class="dialog-close-btn" (click)="Cancel()"></div>
                  </div>
                    <div class="dialog-contwrap" [ngClass]="dialog.contentclass" >
                      <div class="dialog-preloader" *ngIf="dialog.isloading">
                          <img src="assets/images/loader.gif" />
                        </div>
                      <div class="dialog-content">
                        <p *ngIf="dialog.subtitle!=null" class="wizard-title">{{dialog.subtitle}}</p> 
                        <ng-content></ng-content>
                      </div>
                      
                    </div>
                    <div class="dialog-bottom" *ngIf="!dialog.hideButtons" [ngClass]="dialog.isloading? 'disabled-with-opacity':''">
                      <p (click)="Cancel()" class="dialog-button button!dialog.touched-white">Cancel</p>
                      <p (click)="Ok()" class="dialog-button button-blue" [ngClass]="dialog.canFinish()? '':'disabled-with-opacity'">Done</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 </div>
