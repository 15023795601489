import { BehaviorSubject } from 'rxjs';
import { Host, Cluster, InfMenuItem, InfMenuType, RouteNames } from '../classes';
import { Repository } from './repository';
import { map } from 'rxjs/operators';

export class RepositoryClusterHost { 
  private dataSubject: BehaviorSubject<{ [id: string]: InfMenuItem }> = new BehaviorSubject<{ [id: string]: InfMenuItem }>({});

  constructor(private hosts: Repository<Host>, private clusters: Repository<Cluster>) {  }
  private loadData(d: InfMenuItem[]) {
    if (d.length == 0)
      return;
    let newdata = { ...this.dataSubject.value };
    for (var i = 0; i < d.length; i++) {
      var item = d[i];
      newdata[item.id] = item;
    }
    this.dataSubject.next(newdata);
  }
  public GetDataSource(prefix: string) {
    this.hosts.GetDataSource().subscribe(hs => {
      let res = new Array<InfMenuItem>();
      let j = 0;
      for (var i = 0; i < hs.length; i++) {
        let item = hs[i];
        if (item.Parent_Id == null) {
          res[j] = InfMenuItem.NewItem(InfMenuType.host, item.Name, item.Id, null, `${prefix}/${RouteNames.Host}/${item.Id}`);
          j++;
        }
      }
      this.loadData(res);
    });
    this.clusters.GetDataSource().subscribe(hs => {
      let res = new Array<InfMenuItem>();
      for (var i = 0; i < hs.length; i++) {
        let item = hs[i];
        res[i] = InfMenuItem.NewItem(InfMenuType.cluster, item.Name, item.Id, null, `${prefix}/${RouteNames.Cluster}/${item.Id}`);
      }
      this.loadData(res);
    });
    return this.dataSubject.pipe(map(v => Object.keys(v).map(itm => v[itm])));
  }
} 
