export class RouteNames {
  static Login: string = '';
  static VirtualMachines: string = 'vm';
  static HyperVManagement: string = 'hv';
  static CategoryGroup: string = 'categorygroup';
  static Reporting: string = 'reporting';
  static SystemStatus: string = 'systemstatus';
  static VmLifeCycle: string = 'vmlifecycle';
  static ZombieVm: string = 'zombievm';
 /*azure*/
  static Azure: string = 'azure';
  static AzureSubscriptions: string = 'azuresubscriptions';
  static AzureManagement: string = 'azuremanagement';
  static ReplicationManagement: string = 'replication';
  /*azure*/

  /*monitoring*/
  static Monitoring: string = 'monitoring';
  static MonitoringSummary: string = 'summary';
  static MonitoringCPU: string = 'cpu';
  static MonitoringNetwork: string = 'network';
  static MonitoringMemory: string = 'memory';
  static MonitoringDisk: string = 'disk';
  /*monitoring*/
  static VirtualSwitches: string = 'vs';
  static DataStore: string = 'ds';
  static Host: string = 'host';
  static Cluster: string = 'cluster';
  /*Administration*/
  static Administration: string = 'administration';
  static AdministrationUsers: string = 'users';
  static AdministrationTenants: string = 'tenants';
  static AdministrationRoles: string = 'roles';
  static AdministrationRole: string = 'role';
  static AdministrationCategories: string = 'categories';
  static AdministrationGroup: string = 'group';
  static AdministrationTenant: string = 'tenant';
  static AdministrationAdmins: string = 'administrators';
  static AdministrationGlobalAdmins: string = 'globaladministrators';
  /*Administration*/
}
