import { ITreeOptions, IActionMapping } from 'angular-tree-component';
import { BehaviorSubject } from 'rxjs';
export class TreeCheckBoxContext<T> {
  actionMapping: IActionMapping = {
    mouse: {
      click: (tree, node) => this.check(node, !node.data.checked)
    }
  };

  public options: ITreeOptions = {
    actionMapping: this.actionMapping
  };
  private selected: { [id: string]: T } = {};
  public selectedSubject = new BehaviorSubject<T[]>(new Array<T>());

  constructor(private id: string, private tname:string) {
  }
  public setSelected(data: Array<T>) {
    if (data)
      for (var i = 0; i < data.length; i++) {
        let item = data[i];
        this.selected[item[this.id]] = item;
      }
  }
  updateSelected(data: any, f: boolean) {
    if (data.constructor.name === this.tname) {
      if (f) {
        this.selected[data[this.id]] = data;
      }
      else {
        let rm = this.selected[data[this.id]];
        if (rm != null) {
          this.selected[data[this.id]] = null;
        }
      }
    }
  }
 
  public check(node, checked) {
    this.updateChildNodeCheckbox(node, checked);
    this.updateParentNodeCheckbox(node.realParent);
    let sel = Object.values(this.selected).filter(v => { return v != null });
    this.selectedSubject.next(sel);
  }
  public updateChildNodeCheckbox(node, checked) {
    node.data.checked = checked;
    this.updateSelected(node.data, checked);
    if (node.children) {
      node.children.forEach((child) => this.updateChildNodeCheckbox(child, checked));
    }
  }
  public updateParentNodeCheckbox(node) {
    if (!node) {
      return;
    }

    let allChildrenChecked = true;
    let noChildChecked = true;

    for (const child of node.children) {
      if (!child.data.checked || child.data.indeterminate) {
        allChildrenChecked = false;
      }
      if (child.data.checked) {
        noChildChecked = false;
      }
    }

    if (allChildrenChecked) {
      node.data.checked = true;
      this.updateSelected(node.data, true);
      node.data.indeterminate = false;
    } else if (noChildChecked) {
      node.data.checked = false;
      this.updateSelected(node.data, false);
      node.data.indeterminate = false;
    } else {
      node.data.checked = true;
      this.updateSelected(node.data, true);
      node.data.indeterminate = true;
    }
    this.updateParentNodeCheckbox(node.parent);
  }
}
