import { IModalWindowSettings, createEl } from "./interfaces";
import { Guid, Helper } from "../../../classes";
import { EventEmitter } from '@angular/core';
export class scrollBar {
  private sb: any;
  constructor(target: any, pref: string) {
    function setScroll(ssbc) {
      var res = null;
      if (ssbc.hasOwnProperty("data-simple-scrollbar")) {
        res = ssbc['data-simple-scrollbar'];
      }
      else {
        res = new scroll(ssbc);
        Object.defineProperty(ssbc, "data-simple-scrollbar", res);
      }
      return res;
    }
    function e(n, i) {
      function f(n) {
        var t = n.pageY - u;
        u = n.pageY;
        r(function () {
          i.el.scrollTop += t / i.scrollRatio;
        });
      }

      //function e() {
      //  n.classList.remove("ss-grabbed");
      //  document.body.classList.remove("ss-grabbed");
      //  document.removeEventListener("mousemove", f);
      //  document.removeEventListener("mouseup", e)
      //}
      var u;
      //window.addEventListener("mousedown", function (i) {
      //  return u = i.pageY, n.classList.add("ss-grabbed"),
      //    document.body.classList.add("ss-grabbed"),
      //    document.addEventListener("mousemove", f),
      //    document.addEventListener("mouseup", e),
      //    !1
      //});
    }
    function scroll(n) {
      this.target = n;
      this.wrapper = createEl("div", pref + "-wrapper");
      this.el = createEl('div', pref + "-content");
      this.barwrapper = createEl('div', pref + '-scroll-wrapper');
      this.barwrapper.addEventListener('click', function (event) { event.preventDefault(); });
      this.bar = createEl('div', pref + '-scroll');
      this.target.appendChild(this.wrapper);
      this.wrapper.appendChild(this.el);
      this.wrapper.appendChild(this.barwrapper);
      this.barwrapper.appendChild(this.bar);
      this.el.appendChild(this.target.firstChild);
      //e(this.bar, this);
    //  this.moveBar();
   //   this.el.addEventListener("scroll", this.moveBar.bind(this));
  //    this.el.addEventListener("mouseenter", this.moveBar.bind(this));
      this.target.classList.add(pref + "-container")
    }//|| window.setImmediate 
    var r = window.requestAnimationFrame || function (n) {
      return setTimeout(n, 0);
    };
    scroll.prototype = {
      moveBar: function () {
        var t = this.el.scrollHeight,
          i = this.el.clientHeight,
          n = this;
        this.scrollRatio = i / t;
        r(function () {
          var h = i / t * 100;
          if (h < 100) {
            n.wrapper.classList.add(pref + '-show-scroll');
            n.bar.style.cssText = ("height:" + h + "%; top:" + n.el.scrollTop / t * 100 + "%;");
          }
          else
            n.wrapper.classList.remove(pref + '-show-scroll');

        })
      }
    };
    this.sb = setScroll(target);
  }
  public Refresh() {
    this.sb.moveBar();
  }
  public static Init(target: any) {
    return new scrollBar(target, 'ss');
  }
}

export class modalWindow{
  mvid: string = Guid.NewGuid().ToString();
  parrent: any;
  root: any;
  layout: any;
  cwrapper: any;
  container: any;
  closeBtn: any;
  scrollcontentwrap: any;
  scrollY=0;
  containerVisibility: any;
  wrapper: any;
  scroll: any;
  settings: IModalWindowSettings;
  constructor(private target:any, private type: string, settings) {
    this.setSetings(settings);
    this.init();
  }
  private init() {
    let that = this;
    this.parrent = this.target.parentElement;
    this.root = createEl('div', this.type);
    this.parrent.appendChild(this.root);
    this.layout = createEl('div', 'mw-layout');
    this.root.appendChild(this.layout);
    this.cwrapper = createEl('div', 'mw-cwrapper');
    this.layout.appendChild(this.cwrapper);
    this.wrapper = createEl('div', 'mw-wrapper');
    this.cwrapper.appendChild(this.wrapper);
    this.container = createEl('div', 'mw-container');
    this.wrapper.appendChild(this.container);
    this.closeBtn = createEl('div', 'mw-close-btn');
    this.container.appendChild(this.closeBtn);
    this.target.classList.add('mv-content');
    this.container.appendChild(this.target);
    if (!this.settings.removeScroll) {
      this.scroll = new scrollBar(this.layout, 'mws');
      this.scrollcontentwrap = this.layout.querySelector('.mws' + '-content');
      this.scrollcontentwrap.addEventListener('click', function (event) {
        if (that.settings.clickBgClose() && event.target.classList.contains('mws' + '-content'))
          that.closeHandler(event);
      });
    }
    this.cwrapper.addEventListener('click', function (event) {
      if (that.settings.clickBgClose() && event.target.classList.contains('mw-cwrapper'))
        that.closeHandler(event);
    });
    this.wrapper.addEventListener('click', function (event) {
      if (that.settings.clickBgClose() && event.target.classList.contains('mw-wrapper'))
        that.closeHandler(event);
    });
    this.container.addEventListener('click', function (event) {
      if (that.settings.clickBgClose() && event.target.classList.contains('mw-container'))
        that.closeHandler(event);
    });
    this.closeBtn.addEventListener('click', function (event) {
      that.closeHandler(event);
    });
    this.settings.visibleChange.subscribe(v => {
      if (this.visible != v) {
        this.visible = v;
        if (v)
          this.show();
        else
          this.close();
      }
    });
  }
  private visible = false;
  private show() {
    this.settings.onShow();
    this.containerVisibility = this.target.style.display;
    this.target.style.display = 'block';
    this.layout.classList.add('mw-show');
    this.scrollY = window.scrollY;
    document.body.style.top = - this.scrollY + 'px';
    document.body.classList.add('mw-show-marker');
    this.repaintScroll();
  }
  
  private close() {
    this.layout.classList.remove('mw-show');
    document.body.classList.remove('mw-show-marker');
    window.scrollTo(0, this.scrollY);
    this.target.style.display = 'none';
    document.body.style.top = '';
    this.settings.onClose();
  }
  private closeHandler(event = null) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.settings.visibleChange.emit(false);
    console.log(this.mvid + ' close');
  }
  public repaintScroll()
  {
    if (!this.settings.removeScroll) {
      this.scroll.Refresh();
    }
  }
  private setSetings(s: any) {
    this.settings = {
      visibleChange: s.visibleChange,
      onShow: function () { },
      onClose: function () { },
      clickBgClose: function () { return true; },
      removeScroll:false
    };
    if (s) {
      if (s.onShow) {
        this.settings.onShow = s.onShow;
      }
      if (s.onClose) {
        this.settings.onClose = s.onClose;
      }
      if (s.clickBgClose) {
        this.settings.clickBgClose = s.clickBgClose;
      }
      if (s.removeScroll)
        this.settings.removeScroll = s.removeScroll;
    }
  
  }
   
}


