import { Component, OnInit, Injectable, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { IModalWindowSettings } from '..';
import { VisibleComponent } from '../visiblecomponent';
import { Helper } from '../../../../classes';
import { BehaviorSubject, Subscription } from 'rxjs';
export class LoadingState {
  public Loading = false;
  public Subscription: Subscription;
}
export class LoadingStateWatcher {
  private logstate = true;
  constructor() {
    
    let timerId = setInterval(() => { this.notify();}, 5000);
  }
  public LoadingStateChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _state = false;
  public get State(){ return this._state; }
  private data: { [id: string]: LoadingState } = {};
  public AddSubscription(id: string, subscription: Subscription, loading = true) {
    this.data[id] = { Subscription: subscription, Loading: loading };
    this.CalcState();
  }
  

  public SetLoading(id: string, loading = false) {
    let loadingstate = this.data[id];
    if (loadingstate == null) {
      if (loading)
        this.data[id] = { Subscription: null, Loading: loading };
    }
    else
      loadingstate.Loading = loading;
    this.CalcState();
  }
  public Unsubscribe() {
    let data = this.data;
    Object.keys(data).forEach(function (key) {
      let loadingstate = data[key];
      if (loadingstate != null && loadingstate.Subscription != null) {
        loadingstate.Subscription.unsubscribe();
        loadingstate.Subscription = null;
      }
    });
    this.data = {};
  }
  private CalcState() {
    let data = this.data;
    let state = false;
    let that = this;
    Object.keys(data).forEach(function (key) {
      let loadingstate = data[key];
      if (loadingstate != null && loadingstate.Loading) {
        state = true;
        if (that.logstate)
          console.log('proc: ' + key + ' is loading');
      }
      else {
        if (that.logstate)
          console.log('proc: ' + key + ' is loaded');
      }
    });
    this._state = state;
    if (this.logstate)
   // console.log('LoadingStateWatcher state loading is ' + state);
    this.LoadingStateChange.next(this.State);
  }
  notify() {
    let data = this.data;
    let arr: string[] = [];
    Object.keys(data).forEach(function (key) {
      let loadingstate = data[key];
      if (loadingstate != null && loadingstate.Loading) {
        //console.log(key);
      }
    });
  }
}

