import { Component,  OnInit,   ElementRef, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { searchBoxSettings } from '..';
import { searchBox } from '../searchbox';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'search-box',
  template: ``
})

export class SearchBox implements OnInit {
  @Output()
  public valueChange = new EventEmitter<any>();
  @Input()
  set value(val: any) {
    if (this._value != val) {
      this._value = val;
      this.valueChange.emit(this._value);
    }
  }
  get value(): any {
    return this._value;
  }
  _value = false;

  _settings: searchBoxSettings = new searchBoxSettings();
  @Input()
  get settings(): searchBoxSettings {
    return this._settings;
  }
  set settings(val: searchBoxSettings) {
    if (val == null) return;
    let that = this;
    Object.keys(val).forEach(function (key) {
      that._settings[key] = val[key];
    });
  }
  @Input()
  set datasource(val: any[]) {
    this.datasubject.next(val);
  }
  get datasource(): any[] {
    return this.datasubject.value;
  }
  private datasubject=new BehaviorSubject<any[]>([]);
  constructor(private elRef: ElementRef, private changeDetectorRef: ChangeDetectorRef) {

  }
  private searchBox: searchBox;
  ngOnInit(): void {
    let root = this.elRef.nativeElement;
    let onChange = (value) => {
      this.value = value;
      this.changeDetectorRef.detectChanges();
    }
    this.searchBox = new searchBox(root, this.datasubject.asObservable(), onChange,this.settings);
    this.valueChange.subscribe(v => { this.searchBox.setValue(v); });
    if (this.value)
      this.searchBox.setValue(this.value);
  }
}
