import { CategoryGroupLink, ContextObject } from '..';

export class Cluster implements ContextObject{
  Id: string;
  Name: string;
  Description: string;
  ObjectType: string;
  Parent_Id: string;
  Shared: number;
  InternalState: number;
  ClusterId: string;
  Domain: string;
  SharedVolumesRoot: string
  CategoryGroupLinks: CategoryGroupLink[];
}

export class Host implements ContextObject{
  Id: string;
  Name: string;
  Description: string;
  ObjectType: string;
  Parent_Id: string;
  Shared: number;
  InternalState: number;
  MachineUuid: string;
  MachineId: string;
  State: number;;
  LicenseState: number;
  NumberOfCores: number;
  Domain: string;
  LastUpdated: string;
  CpuUsage: number;
  FreeMemmory: number;
  RdgEnabled: boolean;
  RdgExternalAddress: string;
  ClusterNodeState: number;
  OsName: string;
  OsVersion: string;
  AgentVersion: string;
  NeedUpgrade: boolean;
  IsHostOk: boolean;
  IsClusterNodeOk: boolean;
  CategoryGroupLinks: CategoryGroupLink[];
  public static IsHostStateOK(host) {
    return host.State ==2
  }
 // public static IsHostOk(host) { host.InternalState == InternalStates.OK && host.LicenseState == HostLicenseState.OK && host.State == HostState.OK; }
}
