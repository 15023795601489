import { Injectable, Input, EventEmitter, Output } from '@angular/core';
import { AppRoutes } from '../app.routes';
import { Router, Event, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { InfrastructureMenuItemNavigationData, InfMenuItem, MenuSettings, WarningTypes, RouteNames } from '../classes';
import Chart from 'chart.js';
import * as ChartAnnotationPlugin from 'chartjs-plugin-annotation';


@Injectable({ providedIn: 'root' })
export class MonitoringService {
  public data: object = {};
  doughnutLabels: Array<string> = ['Healthy', 'Warning', 'Critical error'];
  doughnutColors: Array<string> = ['#9BC225', '#FFA310', '#D92C23'];
  charts: Array<any> = [];

  constructor(router: Router) {
    window.addEventListener('resize', this.reportWindowSize.bind(this));
  }

  reportWindowSize() {
    if (this.charts && this.charts.length) {
      this.charts.forEach(chart => {
        chart.resize();
      })
    }
  }

  nullifyCharts() {
    this.charts = [];
  }

  destroyCharts(charts = []) {
    for (let i = 0; i < charts.length; i++) {
      charts[i].destroy();
    }
    this.nullifyCharts();
    return [];
  }

  getMonitoringTabsMenuData(type, activeIndex = 0) {
    let tabs;
    if (!type) {
      return [
        {
          name: "Summary",
          link: `/${RouteNames.Monitoring}/${RouteNames.MonitoringSummary}`,
          active: true,
        }
      ]
    }
    tabs = [
      {
        name: "Summary",
        link: `/${RouteNames.Monitoring}/${RouteNames.MonitoringSummary}/${type}/`,
        active: false,
      },
      {
        name: "Disk",
        link: `/${RouteNames.Monitoring}/${RouteNames.MonitoringDisk}/${type}/`,
        active: false,
      },
    ];
    if (type == RouteNames.VirtualMachines || type == RouteNames.Host) {
      tabs.splice(1, 0, ...[
        {
          name: "CPU",
          link: `/${RouteNames.Monitoring}/${RouteNames.MonitoringCPU}/${type}/`,
          active: false,
        },
        {
          name: "Memory",
          link: `/${RouteNames.Monitoring}/${RouteNames.MonitoringMemory}/${type}/`,
          active: false,
        },
        {
          name: "Network",
          link: `/${RouteNames.Monitoring}/${RouteNames.MonitoringNetwork}/${type}/`,
          active: false,
        }
      ])
  
    }

    tabs[activeIndex].active = true;

    return tabs;
    
  }

  getRandomColor() {
    return '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
  }

  generateDoughnutHealthData(data, healthState) {
    let itemsHealthy = 0,
      itemsWarning = 0,
      itemsError = 0;

    for (let i = 0; i < data.length; i++) {
      if (healthState[data[i].Id]) {
        if (healthState[data[i].Id] == WarningTypes.Warning) {
          itemsWarning++;
        } else if (healthState[data[i].Id] == WarningTypes.Error) {
          itemsError++;
        }
      } else {
        itemsHealthy++;
      }
    }
    return [itemsHealthy, itemsWarning, itemsError];
  }

  saveData(data, key) {
    this.data[key] = data;
  }

  getData(key) {
    return this.data[key];
  }

  generateLineChartData(data){
    let arr = [];
    for (var i = 0; i < data.datasets.length; i++) {
      arr.push({
        data: data.datasets[i].data,
        lineTension: 0.05,
        borderColor: data.datasets[i].color,
        borderWidth: 2,
        pointBackgroundColor: data.datasets[i].color,
        label: data.datasets[i].label,
        pointRadius: 0,
        fill: data.datasets[i].fill || false,
        backgroundColor: "rgba(64, 139, 234, 0.3)",
      })
    }
    return arr;
  }

  generateThreshold(content: string  = "", value: number = 0, color: string = "#fff" ) {
    return {
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y-axis-0',
      value,
      borderColor: color,
      borderWidth: 2,
      label: {
        content,
        enabled: true,
        position: "left",
        yAdjust: 10,
        backgroundColor: 'rgba(0,0,0,0)',
        fontColor: color,
      }
    }
  }

  generateAnnotations(thresholds) {
    if (!thresholds) return [];
    let arr = [];

    if (thresholds.WarningValueIfAbove) {
      //arr.push(this.generateThreshold('Warning If Above', thresholds.WarningValueIfAbove, '#ffae42'));
      arr.push(this.generateThreshold('', thresholds.WarningValueIfAbove, '#FFA310'));
    }
    if (thresholds.CriticalValueIfAbove) {
      //arr.push(this.generateThreshold('Error If Above', thresholds.CriticalValueIfAbove, '#cb0303'));
      arr.push(this.generateThreshold('', thresholds.CriticalValueIfAbove, '#D92C23'));
    }
    if (thresholds.WarningValueIfBelow) {
      //arr.push(this.generateThreshold('Warning If Below', thresholds.WarningValueIfBelow, '#ffae42'));
      arr.push(this.generateThreshold('', thresholds.WarningValueIfBelow, '#FFA310'));

    }
    if (thresholds.CriticalValueIfBelow) {
      //arr.push(this.generateThreshold('Error If Below', thresholds.CriticalValueIfBelow, '#cb0303'));
      arr.push(this.generateThreshold('', thresholds.CriticalValueIfBelow, '#D92C23'));
    }
    return arr;
  }

  generateThresholds(thresholds, data) {
    if (!thresholds) return null;
    let allData = data.reduce((accumulator, currentValue) => accumulator.concat(currentValue.data), []),
      values = allData.map(el => parseInt(el.y));
    let isAbove = thresholds["WarningValueIfAbove"];
    if (isAbove) {
      return Math.max(...values) >= thresholds["WarningValueIfAbove"] ? thresholds : null;
    } else {
      return Math.min(...values) <= thresholds["WarningValueIfBelow"] ? thresholds : null;
    }
  }

  drawLineChart(dataOptions) {
    let chartData = {
      datasets: this.generateLineChartData(dataOptions),
    };

    let chartOptions = {
      responsive: true,
      plugins: [ChartAnnotationPlugin],
      maintainAspectRatio: false,
      annotation: {
        annotations: dataOptions.thresholdsMandatory ? this.generateAnnotations(dataOptions.thresholds) : this.generateAnnotations(this.generateThresholds(dataOptions.thresholds, dataOptions.datasets)),
      },
      legend: {
        display: dataOptions.legend,
        align: 'center',
        position: 'bottom',
        fullWidth: true,
        labels: {
          usePointStyle: true,
          fontSize: 14,
          fontColor: '#000',
          boxWidth: 5,
          padding: 10,
        }
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let dataset = dataOptions.datasets[tooltipItem.datasetIndex];
            var res = (dataset.prefix || "") + tooltipItem.yLabel + (dataset.postfix || "");
            return res;
          },
          labelColor: function(tooltipItem, chart) {
            let dataset = dataOptions.datasets[tooltipItem.datasetIndex];
            return {
              borderColor: 'transparent',
              backgroundColor: dataset.color,
              pointStyle: 'circle',
            };
          },
        },
        mode: 'index',
        intersect: false,
        borderWidth: 1,
        borderColor: '#4875B3',
        cornerRadius: 4,
        backgroundColor: '#fff',
        titleFontColor: '#2D3138',
        bodyFontColor: '#2D3138',
        xPadding: 18.5,
        yPadding: 15
      },
      scales: {
        xAxes: [{
          type: 'time',
          time: {
            unit: dataOptions.unit || 'minute',
            tooltipFormat: 'll h:mm A',
            displayFormats: {
              minute: 'h:mm A',
              hour: "h A"
            }
          },
          offset: true,
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            //maxTicksLimit: dataOptions.maxTicksLimit,
            autoSkip: true,
            beginAtZero: true,
            autoSkipPadding: 30,
            maxRotation: 0,
            minRotation: 0,
            fontColor: '#2D3138'
          }
        }],
        yAxes: [{
          gridLines: {
            drawOnChartArea: dataOptions.gridLinesY,
            drawBorder: false,
            padding: 7,
          },
          ticks: {
            max: dataOptions.maxY,
            padding: 7,
            fontColor: '#2D3138',
            beginAtZero: true,
            maxTicksLimit: dataOptions.maxTicksLimit,
            stepSize: dataOptions.stepSize,
            callback: function (value) {
              return value + (dataOptions.YAxisSign || "");
            },
          }
        }]
      }
    };
    let lineChart = new Chart(document.getElementById(dataOptions.id), {
      type: 'line',
      data: chartData,
      options: chartOptions
    });

    this.charts.push(lineChart);
    
    return lineChart;
  }

  drawBarChart(dataOptions) {
    let chartOptions = {
      responsive: false,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: ''
        }
      },
      scales: {
        yAxes: [{

          ticks: {
            max: dataOptions.max,
            padding: 7,
            fontColor: '#2D3138',
            beginAtZero: true,
            ///  maxTicksLimit: dataOptions.maxTicksLimit,
            // stepSize: dataOptions.stepSize,
            //callback: function (value) {
            //  return value + (dataOptions.YAxisSign || "");
            //},
          }
        }]
      }
    };
    let lineChart = new Chart(document.getElementById(dataOptions.id), {
      type: 'bar',
      data: dataOptions.chartData,
      options: chartOptions
    });

    this.charts.push(lineChart);

    return lineChart;
  }

  getISODate() {

  }

  generateClassLabel(el) {
    let arr = el.split(' ');
    return arr && arr.length ? arr[0] : '';
  }

  drawDoughnut(data, healthState, id, customData: any = {}) {
    let chartData = healthState ? this.generateDoughnutHealthData(data, healthState) : data;
    const backgroundColor = customData.backgroundColor || this.doughnutColors;
    const labels = customData.labels || this.doughnutLabels;
    console.log('doughnut data = ', chartData)
    let myDoughnutChart = new Chart(document.getElementById(id), {
      type: 'doughnut',
      data: {
        datasets: [{
          data: chartData,
          backgroundColor,
          borderWidth: 0,
        }],
        labels
      },
      options: {
        cutoutPercentage: 85,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
          animateRotate: true
        },
        tooltips: {
          enabled: false,
        },
        legend: {
          display: false, //chartData.reduce((a, b) => a + b, 0) > 0,
          align: 'center',
          position: 'right',
          labels: {
            usePointStyle: true,
            fontSize: 14,
            fontColor: '#000',
            boxWidth: 5,
            padding: 10,
            generateLabels: function (chart) {
              let data = chart.data;
              if (data.labels.length && data.datasets.length) {
                return data.labels.map(function (label, i) {
                  return {
                    text: label + " - " + data.datasets[0].data[i],
                    fillStyle: data.datasets[0].backgroundColor[i],
                    pointStyle: 'circle',
                    fontSize: 14,
                  };
                });
              }
              return [];
            }
          }
        }
      }
    } );
    return chartData;
  }

}
