import { Component, Input, AfterViewInit, Compiler, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { AppRoutes } from './app.routes';
import { MenuService,  NotificationService, HttpService, DataStore } from './services';
import { Helper, MenuSettings } from './classes';
import { environment } from '../environments/environment.prod';

@Component({
  selector: 'app-root', providers: [HttpService, MenuService, NotificationService, DataStore],
  templateUrl: './app.component.html',
})

export class AppComponent implements AfterViewInit {
  currentApplicationVersion = environment.appVersion;
  private date = new Date();
  public leftMenu = AppRoutes.leftMenu;
  get menuitems() {
    return AppRoutes.leftMenu.filter(r => { return this.menuVisible(r)});
  }
  public menuCollapsed = false;
  public rightMenuCollapsed = false;
  private loginPopoverVisible: boolean;
  public loading: boolean;
  public authorized: boolean = false;
  private isGlobalAdmin: boolean;
  private isTenantAdmin: boolean;
  private isGlobal: boolean;
  private isAuditor: boolean;
  public userName: string;
  private currentUser: object;
  public menuClass: string;
  private MenuSettings = new MenuSettings();

  constructor( public notification: NotificationService, public menu: MenuService, private authService: HttpService,
	  private locator: PlatformLocation) {
    this.userName = "";
    this.currentUser = null;
    this.isGlobalAdmin = false;
    this.isTenantAdmin = false;
    this.isGlobal = false;
  //  console.log('AppComponent create');
    HttpService.authorizedObserver.subscribe(value => {
      if (value != null) {
        this.authorized = value;
        if (value == true) {
          this.userName = HttpService.UserName;
          authService.getCurrentUser().then(user => {
          //  console.log("this.currentUser", user);
            this.isGlobalAdmin = user.IsGlobalAdmin;
            this.isTenantAdmin = user.IsTenantAdmin;
            this.currentUser = user;
          }, error => {
            console.log('app component failed promise', error);
          }).catch(error => {
            console.log('app component getCurrentUser error', error);
          })
        } else {
          this.loginPopoverVisible = value;
        }
      }
    });

    this.loading = false;
    this.notification.getLoadingState().subscribe(value => {
      this.loading = value;
    });
    this.menu.setActiveRoute();
    this.rightMenuCollapsed = false;
    this.locator.onPopState(() => {
      this.ngAfterViewInit();
    });
    menu.Setttings().subscribe(s => {
      this.MenuSettings = s;
      try {
        this.calcmenuClass();
      }
      catch (x) {

      }
    });
  }

  calcmenuClass(rightVisble = true) {
    this.MenuSettings.rightVisible = rightVisble;
    this.menuClass = this.MenuSettings.getMenuState();
    Helper.ESresize();
  }

  ngAfterViewInit() {
   
  }


  toggleLoginPopoverVisible() {
    this.loginPopoverVisible = !this.loginPopoverVisible;
  }

  menuVisible(route: any) {
    return !(
      (route.data.forAdmins && !this.isGlobalAdmin && !this.isTenantAdmin) ||
      (route.data.hideFromTenant && !this.isGlobal) ||
      (route.data.hideFromNonAuditors && !this.isAuditor) ||
      (route.data.hideFromNonAuditorsAndTenants && !this.isGlobal && !this.isAuditor)
    );
  }

}
