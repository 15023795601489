<div class="summary-list gray-scrollbar">
  <div class="summary-info" *ngFor="let d of data">
    <div>
      <p>{{d.Name}}</p>
    </div>
    <div *ngIf="d.type==null">
      <p class="ellipsis" > {{d.Value}}</p>
    </div>
    <div  *ngIf="d.type==1">
      <p class="ellipsis-sum"  *ngFor="let p of d.Value"> {{p}}</p>
    </div>
  </div>
</div>
