import { Observable } from "rxjs";
import { createEl, searchBoxSettings } from ".";

export class searchBox{
  sbwrap: any;
  onChangeValue: (value: any) => void;
  container = createEl("div", "sb-container");
  input = createEl('input', 'sb-title');
  keyExp= '';
  displayExp= '';
  isActive= false;
  isClickInside= false;
  eh = null
  sbvalue = null
  Name = null
  disabled= false;
  inputwrap= createEl('div', 'sb-title-wrap');
  placeholder = createEl('p', 'sb-placeholder');
  btncnt= createEl('div', 'sb-btn-container');
  openclosebtn= createEl('div', 'sb-openclose-btn');
  clearbtn= createEl('div', 'sb-clear-btn');
  datawrap= createEl('div', 'sb-data-wrap');
  datalist = createEl('ul', 'sb-list');
  settings: searchBoxSettings;
  dataSource: any[];
  datasubject: Observable<any[]>;
  scroll: any;
  constructor(target: any, dataSource: Observable<any[]>, onChangeValue: (value: any) => void, s?: searchBoxSettings) {
    this.datasubject=dataSource;
    this.setSetings(s);
    this.sbwrap = target;
    this.onChangeValue = onChangeValue;
    this.init();
  }

  setSetings(s: searchBoxSettings) {
    this.settings = {
      keyExp: 'Id',
      displayExp: 'Name',
      valueAll: false,
      blurtimeout: 50,
      placeholder: '',
      listcount: 5,
      search: s.search,
      value: s.value,
      onFocus: function () { },
      onBlur: function () { },
      lowExp: 'low',
      elExp: 'el'
    };
    let that = this;
    if(s)
    Object.keys(s).forEach(function (key) {
      if (s[key] != null) {
        that.settings[key] = s[key];
      }
    });
    this.keyExp = this.settings.keyExp ;
    this.displayExp = this.settings.displayExp;
  }

  init() {
    let that = this;
    that.sbwrap.appendChild(that.container);
    that.container.appendChild(that.inputwrap);
    that.inputwrap.appendChild(that.placeholder);
    that.placeholder.innerText = that.settings.placeholder;
    that.inputwrap.appendChild(that.input);
    that.datawrap.setAttribute('tabindex', '0');
    that.clearbtn.setAttribute('tabindex', '1');
    if (!that.settings.search) {
      that.input.setAttribute("readOnly", '');
      that.container.classList.add('sb-readonly');
    }
    that.inputwrap.appendChild(that.btncnt);
    that.btncnt.appendChild(that.openclosebtn);
    that.btncnt.appendChild(that.clearbtn);
    that.container.appendChild(that.datawrap);
    that.datawrap.appendChild(that.datalist);
    that.clearbtn.addEventListener('click', function (ev) {
      that.isClickInside = true;
      that.input.focus();
      that.clearInput(ev);
    });

    that.openclosebtn.addEventListener('click', function (ev) {
      if (!that.disabled) {
        that.isClickInside = true;
        that.input.focus();
        that.openClose(ev);
      }
    });
    that.input.addEventListener('input', function (ev) {
      if (that.input.value == null || that.input.value == '') {
        that.ValueChangeHandler(null);
        that.placeholder.style.display = 'block';
      }
      else {
        that.placeholder.style.display = 'none';
        that.showClearHandler();
        that.filterdatalist();
      }
      that.open();
    });
    that.input.addEventListener('focus', function (ev) {
      if (!that.disabled) {
        that.isActive = true;
        that.isClickInside = true;
        that.showClearHandler();
        that.settings.onFocus();
      }
    });
    that.input.addEventListener('click', function (ev) {
      if (!that.disabled) {
        that.filterdatalist();
        that.open();
      }
    });
    that.input.addEventListener('blur', function (event) {
      that.blurHandler();
    });
    that.clearbtn.addEventListener('focus', function (ev) {
      that.isClickInside = true;
    });
    that.datawrap.addEventListener('focus', function () {
      that.isClickInside = true;
    });
    that.datawrap.addEventListener('blur', function () {
      that.blurHandler();
    });
    that.placeholder.style.display = (that.input.value == null || that.input.value == '') ? 'block' : 'none';
    that.scroll = that.initScroll(that.datawrap, that.datalist);
    that.datasubject.subscribe(d => {
      that.setDataSource(d);
    });
  }
  ChangeValue(value) {
    if (this.settings.valueAll)
      this.onChangeValue(value);
    else {
      if (value != null)
        this.onChangeValue(value[this.keyExp]);
      else
        this.onChangeValue(null);
    }
  }
  blurHandler() {
    let that = this;
    that.isClickInside = false;
    setTimeout(function () {
      if (!that.isClickInside && that.isActive) {
        that.close();
        that.container.classList.remove('sb-show-clear-btn');
        if (that.sbvalue == null) {
          if (that.input.value != null && that.input.value.length > 0) {
            var fel = that.findElByName(that.input.value);
            if (!fel) {
              that.ChangeValue(null);
            }
          }
        } else {
          that.input.value = that.Name;
        }
        that.settings.onBlur();
        that.isClickInside = false;
        that.isActive = false;
      }
    }, that.settings.blurtimeout);
  }
  setDisabled(arg) {
    if (arg) {
      this.input.setAttribute('disabled', 'disabled');
      this.container.classList.add('sb-filter-res0');
      this.container.classList.add('sb-disabled');
    }
    else {
      this.input.removeAttribute('disabled');
      this.container.classList.remove('sb-filter-res0');
      this.container.classList.remove('sb-disabled');
    }
    this.disabled = arg == true;
  }
  createListItem() {
    let that = this;
    that.datawrap.style.height = that.dataSource.length < that.settings.listcount + 1 ? 'auto' : that.settings.listcount + 'em';
    if (that.dataSource.length == 1)
      that.container.classList.add('sb-ds-one-item');
    else
      that.container.classList.remove('sb-ds-one-item');
    that.setDisabled(that.dataSource.length == 0);
    if (!that.disabled) {
      for (var i = 0; i < that.dataSource.length; i++) {
        var p = that.dataSource[i],
          el = createEl('li', 'sb-li ellipsis');
        if (that.settings.value == p[that.keyExp]) {
          that.input.value = p[that.displayExp];
          that.Name = p[that.displayExp];
          that.sbvalue = p;
          el.classList.add('sel-item');
        }
        el.setAttribute('data-key', p[that.keyExp]);
        el.innerHTML = p[that.displayExp];
        el.onclick = function (ev) {
          var el = ev.target
          if (that.sbvalue != el.getAttribute('data-key'))
            that.ValueChangeHandler(el);
          setTimeout(function () {
            that.datawrap.blur();
          }, 10);
        };
        that.datalist.appendChild(el);
        p[that.settings.elExp] = el;
        let exp: string = p[that.displayExp]+'';
        p[that.settings.lowExp] = exp.toLowerCase();
      }
      //if (that.dataSource.length > that.settings.listcount)
      //    that.container.classList.add('sb-show-scroll');
    }
  }

  findElByName(value) {
    this.removeSelected();
    var s = false;
    if (value != null && value != '') {
      for (var i = 0; i < this.dataSource.length; i++) {
        var p = this.dataSource[i];
        if (value == p[this.displayExp]) {
          this.input.value = this.Name = p[this.displayExp];
          this.sbvalue = p;
          p[this.settings.elExp].classList.add('sel-item');
          s = true;
          this.ChangeValue(this.sbvalue);
          return s;
        }
      }
    }
    return s;
  }
  removeSelected() {
    if (this.sbvalue != null) {
      var l = this.datalist.querySelector('.sel-item');
      if (l != null)
        l.classList.remove('sel-item');
    }
  }
  
  showClearHandler() {
    if (this.input.value == null || this.input.value == "")
      this.container.classList.remove('sb-show-clear-btn');
    else
      this.container.classList.add('sb-show-clear-btn');
  }
  ValueChangeHandler(el) {
    this.removeSelected();
    if (el == null) {
      this.filterdatalist();
      this.sbvalue = null;
      this.Name = null;
      this.placeholder.style.display = 'block';
    }
    else {
      this.placeholder.style.display = 'none';
      let id = el.getAttribute('data-key');
      let fa = this.dataSource ? this.dataSource.filter(c => c[this.keyExp] == id) : null;
      this.sbvalue = fa != null && fa.length > 0 ? fa[0] : null;
      this.input.value = el.innerText;
      el.classList.add('sel-item');
      this.Name = this.input.value;
    }
    this.showClearHandler();
    this.filterdatalist();
    this.ChangeValue(this.sbvalue);
  }
  lastfilter:string=null;
  filterdatalist(){
    var val = this.input.value;
    if (val == this.lastfilter) return;
    this.lastfilter = val;
    if (val == this.Name)
      val = null;
    var count = 0;
    if (val == null || val == '') {
      for (var i = 0; i < this.dataSource.length; i++) {
        var p = this.dataSource[i];
        p[this.settings.elExp].classList.remove('hide');
      }
      count = this.dataSource.length;
    }
    else {
      val = val.toLowerCase();
      for (var i = 0; i < this.dataSource.length; i++) {
        var p = this.dataSource[i];
        if (p[this.settings.lowExp].indexOf(val) !== -1) {
          p[this.settings.elExp].classList.remove('hide');
          count++;
        } else {
          p[this.settings.elExp].classList.add('hide');
        }
      }
    }
    if (count == 0)
      this.container.classList.add('sb-filter-res0');
    else {
      this.container.classList.remove('sb-filter-res0');
      if (count < this.settings.listcount + 1) {
        this.datawrap.style.height = 'auto';
      }
      else {
        this.datawrap.style.height = this.settings.listcount + 'em';
      }
    }
    this.scroll.moveBar();
  }
  clearInput(ev) {
    this.input.value = null;
    this.ValueChangeHandler(null);
    this.open();

  }
  heightValidation() {
    if (this.datalist.scrollHeight < this.eh)
      this.container.classList.add('sb-filter-res0');
    else {
      this.container.classList.remove('sb-filter-res0');

    }
  }
  open() {
    if (!this.container.classList.contains('sb-list-open')) {
      this.container.classList.add('sb-list-open');
      this.heightValidation();
      this.scroll.moveBar();
    }
  }
  close() {
    if (this.container.classList.contains('sb-list-open')) {
      this.heightValidation();
      this.container.classList.remove('sb-list-open');

    }
  }
  openClose(ev) {
    if (this.container.classList.contains('sb-list-open')) {
      this.heightValidation();
      this.container.classList.remove('sb-list-open');
    }
    else {
      this.container.classList.add('sb-list-open');
      this.heightValidation();
      this.scroll.moveBar();
    }
  }
  setFocus() {
    this.input.focus();
    this.input.selectionStart = this.input.value.length;
  }
  setDataSource(ds:any[]) {
    this.close();
   // this.sbvalue = null;
    //this.Name = null;
   // this.input.value = null;
    this.dataSource = ds;
    this.datalist.innerHTML = '';
    this.createListItem();
    if (this.dataSource.length == 1)
      this.container.classList.add('sb-ds-one-item');
    else
      this.container.classList.remove('sb-ds-one-item');
    this.setDisabled(this.dataSource.length == 0);
  }
  public setValue(value) {
    let key = value ? (this.settings.valueAll ? value[this.settings.keyExp]:value) : null;
    if ((value == null && this.sbvalue == null) || (value != null && this.sbvalue != null && this.sbvalue[this.settings.keyExp] == key))
      return;
    this.settings.value = key;
    this.removeSelected();
    var s = false;
    if (key != null && this.dataSource != null) {
      for (var i = 0; i < this.dataSource.length; i++) {
        var p = this.dataSource[i];
        if (key == p[this.keyExp]) {
          this.input.value = this.Name = p[this.displayExp];
          this.sbvalue = p;
          p[this.settings.elExp].classList.add('sel-item');
          s = true;
        }
      }
    }
    if (!s) {
      this.sbvalue = null;
      this.Name = null;
      this.input.value = null;
    }
  }
 
  initScroll(target, content) {
 
    function setScroll(sb) {
        var res = null;
      if (sb.hasOwnProperty("search-box-scrollbar")) {
            res = sb['search-box-scrollbar'];
        }
        else {
            res = new scroll(sb);
            Object.defineProperty(sb, "search-box-scrollbar", res);
        }
        return res;
    }
    function e(n, i) {
        function f(n) {
            var t = n.pageY - u;
            u = n.pageY;
            r(function () {
                i.el.scrollTop += t / i.scrollRatio
            })
        }

        //function e() {
        //    n.classList.remove("ss-grabbed");
        //    document.body.classList.remove("ss-grabbed");
        //    document.removeEventListener("mousemove", f);
        //    document.removeEventListener("mouseup", e)
        //}
        var u;
        //window.addEventListener("mousedown", function (i) {
        //    return u = i.pageY, n.classList.add("ss-grabbed"),
        //        document.body.classList.add("ss-grabbed"),
        //        document.addEventListener("mousemove", f),
        //        document.addEventListener("mouseup", e),
        //        !1
        //})
    }
    function scroll(n) {
        this.target = n;
        this.wrapper = createEl('div', 'sb-list-wrap');
        this.el = content;
        this.wrapper.appendChild(this.el);
        this.target.appendChild(this.wrapper);
        this.bar = createEl('div', 'sb-scroll');
        this.target.appendChild(this.bar);
        e(this.bar, this);
        this.moveBar();
        this.el.addEventListener("scroll", this.moveBar.bind(this));
        this.el.addEventListener("mouseenter", this.moveBar.bind(this));
    }
    var r = window.requestAnimationFrame || function (n) {
        return setTimeout(n, 0);
    };
    scroll.prototype = {
          moveBar: function () {
            var t = this.el.scrollHeight,
                i = this.el.clientHeight,
                n = this;
            this.scrollRatio = i / t;
            r(function () {
                var h = i / t * 100;
                if (h < 99) {
                    n.bar.classList.add('sb-show-scroll');
                    n.bar.style.cssText = ("height:" + h + "%; top:" + n.el.scrollTop / t * 100 + "%;");
                }
                else
                    n.bar.classList.remove('sb-show-scroll');

            })
        }
    };

    return setScroll(target);
  }
}


