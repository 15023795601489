//inner                                 
import { Injectable } from '@angular/core';
import { Host, Cluster, VirtualNetworkSwitch,
InfMenuItem,
InfMenuType,
RouteNames,
StorageData,
AGroup,
VirtualMachine,
Job,
Helper,
AzureVirtualMachine} from '../classes';
import { Repository, RepositoryClusterHost, RepositoryVm } from '../repository';
import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
import { WebsocketService } from '.';

@Injectable({ providedIn: 'root' })
export class DataStore {
  constructor(private client: HttpService) {
  }

  private static VirtualSwitches: Repository<VirtualNetworkSwitch>;
  private static  AzureSubscriptions: Repository<any>;
  private static AzureVirtualMachines: Repository<AzureVirtualMachine>;
  private static  Hosts: Repository<Host>;
  private static  Clusters: Repository<Cluster>;
  private static  VirtualMachines: Repository<VirtualMachine>;
  private Health: Repository<any>;
  private static  Storeges: Repository<StorageData>;
  private static  CategoryGroups: Repository<AGroup>;
  public getHosts() {
    if (DataStore.Hosts == null) {
      DataStore.Hosts = new Repository<Host>(() => this.client.get<Host[]>('Core/Host', null), { TName: 'Host', addFormWS: true });
      WebsocketService.Instance().subscribe(ws => {
        let deletedObserver = ws.on<string[]>('ContextObject.Deleted');
        deletedObserver.subscribe(h => {
          DataStore.Hosts.Remove(h);
        });
      });
     // this.Hosts.GetDataSource().subscribe(h => { });
    }
     
    return DataStore.Hosts;
  }
  public getAzureVirtualMachines() {
    if (DataStore.AzureVirtualMachines == null) {
      DataStore.AzureVirtualMachines = new Repository<AzureVirtualMachine>(() => this.client.get<AzureVirtualMachine[]>(`Azure/ListVirtualMachines`, null), { TName: 'AzureVirtualMachine', addFormWS: true });
    }
    return DataStore.AzureVirtualMachines;
  }
  public getAzureSubscibtions() {
    if (DataStore.AzureSubscriptions == null) {
      DataStore.AzureSubscriptions = new Repository<any>(() => this.client.get<any>(`Azure/Subscriptions`, null), { TName: 'AzureSubscription', addFormWS: true });
    }
    return DataStore.AzureSubscriptions;
  }
  public getClusters() {
    if (DataStore.Clusters == null) {
      DataStore.Clusters = new Repository<Cluster>(() => this.client.get<Cluster[]>('Core/Cluster', null), { TName: 'Cluster', addFormWS: true });
      WebsocketService.Instance().subscribe(ws => {
        let deletedObserver = ws.on<string[]>('ContextObject.Deleted');
        deletedObserver.subscribe(h => {
          DataStore.Clusters.Remove(h);
        });
      });
     // this.Clusters.GetDataSource().subscribe(h => { });
    }
    return DataStore.Clusters;
  }

  public getVirtualSwitches() {
    if (DataStore.VirtualSwitches == null)
      DataStore.VirtualSwitches = new Repository<VirtualNetworkSwitch>(() => this.client.get<VirtualNetworkSwitch[]>('HyperV/VmSwitch', null), { TName: 'VirtualNetworkSwitch', addFormWS: true });
    return DataStore.VirtualSwitches;
  }
  

  public getVirtualMachines() {
    if (DataStore.VirtualMachines == null)
      DataStore.VirtualMachines = new Repository<VirtualMachine>(() => this.client.get<VirtualMachine[]>('HyperV/Vm', null), { TName: 'VirtualMachine', addFormWS: true });
    return DataStore.VirtualMachines;
  }
  public getStoreges() {
    if (DataStore.Storeges == null) {
      DataStore.Storeges = new Repository<StorageData>(() => this.client.get<StorageData[]>('hyperv/storages', null), { TName: 'Storage', addFormWS: true });
      WebsocketService.Instance().subscribe(ws => {
        let modObserver = ws.on<Job[]>('Job.Modified');
        modObserver.subscribe(jobs => {
          let remstor = jobs.filter(j => { return j.Name.includes('/hyperv/storages/') && j.Status == 'Completed' });
          if (remstor.length > 0)
            DataStore.Storeges.Refresh();
        });
      });
    }
    return DataStore.Storeges;
  }

  public getCategoryGroups() {
    if (DataStore.CategoryGroups == null)
      DataStore.CategoryGroups = new Repository<AGroup>(() => this.client.get<AGroup[]>('core/category/group', null), { TName: 'Storage', addFormWS: true });
    return DataStore.CategoryGroups;
  }
  public getClusterHosts() {
    return new RepositoryClusterHost(this.getHosts(), this.getClusters());
  }
  public getVmRepository(http: HttpService,  id: string) {
    return new RepositoryVm(http,id,this.getHosts(), this.getClusters());
  }
  public getHostsMenuById(clusterid: string, pref: string) {
    return this.getHosts().GetDataSource().pipe(map(vms => {
      let res = new Array<InfMenuItem>();
      let j = 0;
      for (let i = 0; i < vms.length; i++) {
        let item = vms[i];
        if (item.Parent_Id == clusterid) {
          res[j] = InfMenuItem.NewItem(InfMenuType.host, item.Name, item.Id, null, pref + '/' + RouteNames.Host + '/' + item.Id);
          j++;
        }
      }
      return res;
    }));
  }
}
