//inner
import { Component, Injectable, OnInit, ChangeDetectorRef, EventEmitter, Output, Input } from '@angular/core';
//services
import { MenuService, NavTreeFactory, NavNode } from '../../../services';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router, Event, NavigationEnd, ActivationEnd, ActivatedRoute } from '@angular/router';
import { InfMenuItem, Helper } from '../../../classes';

@Component({
  selector: 'InfrastructureMenu',
  templateUrl: './InfrastructureMenu.template.html'
})

export class InfrastructureMenu implements OnInit {
  dataSource$: BehaviorSubject<InfMenuItem[]> = new BehaviorSubject<InfMenuItem[]>(new Array<InfMenuItem>());
  data$: InfMenuItem[];
  private Subscription: Subscription;
  displayedColumns$ = new BehaviorSubject<string[]>(['common']);
  @Input() authorized: boolean = false;
  showInfrastructure: boolean = true;
  showLogicalGroups: boolean = false;
  public refreshsb = false;
  public word: any;
  public get uplink(): string {
    return this.parentnode != null ? this.getnodelink(this.parentnode) : '';
  }
  public get upname(): string {
    return this.parentnode != null ? this.parentnode.name : '';
  }

  public get delta() {
    let p = 0;
    if (this.menu.currentRoute != null && this.menu.currentRoute.data != null && this.menu.currentRoute.data['children'])
      p = this.menu.currentRoute.data['children'].length * 32;
    return (p + 181);
  }
  constructor(public menu: MenuService, private router: Router, private changeDetectorRef: ChangeDetectorRef) {
    this.word = '';
    this.router.events.subscribe((value: Event) => {
      if (value instanceof NavigationEnd) {
        this.word = '';
        this.changeDetectorRef.detectChanges();
      }
    });
  }
  public getimeges(node: NavNode): string {
    return this.menu.imeges[node.type](node);
  }

  private treeroutes: { [nodetype: string]: (node: NavNode) => string; } = {};
  public getnodelink(node: NavNode): string {
    let fun = this.treeroutes[node.type];
    if (fun != null)
      return fun(node);
    return '';
  }

  public selectednode: NavNode = null;
  public parentnode: NavNode = null;
  _nodes: NavNode[] = [];
  public get nodes(): NavNode[] {
    if (this.selectednode != null)
      return this._nodes.sort((a, b) => {
        if (a.lvl > b.lvl) return 1;
        else if (a.lvl == b.lvl) {
          if (a.name > b.name) {
            return 1;
          }
          else if (a.name == b.name) {
            return (a.id > b.id) ? 1 : -1;
          }
          else { return -1; }
        }
        else {
          return -1;
        }
      });
    return [];
  }
  public isadminplugin = false;
  public adminuplink = '';
  public adminupname = '';
  ngOnInit(): void {
    this.menu.TreeNode().subscribe(route => {
      this.isadminplugin = false;
      if (route.treename == null || route.treename.length == 0) {
        this.selectednode = null;
        this.parentnode = null;
      }
      else {
        let tree = this.menu.getTree(route.treename);
        if (tree)
          tree.getnodes().then(nodes => {
           // console.log('inf get tree');
           // console.log(nodes);
            this.selectednode = nodes[route.nodeid];
            this.parentnode = this.selectednode != null ? this.selectednode.parent : null;
            if (route.showchildren === false) {
              this._nodes = [this.selectednode]
            }
            else
              this._nodes = this.selectednode.children;
          });
        let routes = this.menu.routes[route.treename];
        this.treeroutes = routes != null ? routes : {};
      }
    });

    this.menu.Data().subscribe(context => {
      if (this.Subscription) {
        this.Subscription.unsubscribe();
      }
      this.adminuplink = context.up;
      this.adminupname = context.name;
      this.isadminplugin = true;
      if (context.data) {
        this.Subscription = context.data.subscribe(d => {
          this.changedata(d)
        });
      }
      this.changeDetectorRef.detectChanges();
    });
    this.menu.ChildrenData().subscribe(data => {
      if (data) {
        data.hiddenElements && this.menu.handleChildrenVisibility(data.hiddenElements);
        this.menu.handleChildrenLinks(data.suffix);
      }
      this.changeDetectorRef.detectChanges();
    })
  }

  toggleInfrastructureMenu() {
    this.showInfrastructure = !this.showInfrastructure;
    if (this.showInfrastructure) {
      this.showLogicalGroups = false;
    }
  }

  toggleLogicalGroupsMenu() {
    this.showLogicalGroups = !this.showLogicalGroups;
    if (this.showLogicalGroups) {
      this.showInfrastructure = false;
    }
  }

  hideSecondaryMenu() {
    this.menu.toggleSecondaryMenu(false);
  }

  getSubTitle() {
    if (!this.menu || !this.menu.currentRoute) return '';
    if (this.menu.currentRoute.data['titleInsteadOfSubtitle']) {
      return this.menu.currentRoute.data['title'];
    }
    const activeSub = this.menu.currentRoute.data['children'].find(el => el.data.active);
    if (activeSub) {
      return activeSub.data.topTitle || activeSub.data.title;
    }
    return '';
  }

  private changedata(data: InfMenuItem[]) {
    this.data$ = data;
    this.filter();
  }
  private filter() {
    let data: InfMenuItem[] = new Array<InfMenuItem>();
    if (this.data$ != null && this.data$.length > 0) {
      data = this.data$;
      if (this.word && this.word.length > 0) {
        let searchTerm = this.word.toLowerCase();
        let res = data.filter(d => d.name.toString().toLowerCase().includes(searchTerm));
        data = res;
      }
    }
    this.dataSource$.next(data);
    this.changeDetectorRef.detectChanges();
    this.refreshsb = true;
    this.refreshsb = false;
  }

  public search(word) {
    if (word !== null && word !== undefined) {
      this.word = word;
      this.filter();
    }
  }
}
