import { Component, Injectable, Input, Output, OnChanges, OnInit, EventEmitter, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit, ElementRef, TemplateRef } from '@angular/core';
import { HttpService, MenuService } from '../../../../services';
import { HttpParams } from '@angular/common/http';
import { Alarm, WarningTypes } from '../../../../classes';

@Component({
  selector: 'menu-breadcrumb',
  templateUrl: 'MenuBreadcrumb.template.html'
})

export class MenuBreadcrumbComponent implements OnInit {
 
  _breadcrumbs: any[] = [];
  @Input()
  public set breadcrumbs(val) {
    this._breadcrumbs = val;
 //   console.log('breadcrumbs');
  //  console.log(val);
  }
  public get breadcrumbs() { return this._breadcrumbs}

  constructor(private MenuService: MenuService) {
  }

  showSecondaryMenu() {
    this.MenuService.toggleSecondaryMenu(true);
  }

  isSecondaryMenuOpen() {
    return this.MenuService.isSecondaryMenuOpen();
  }

  ngAfterViewInit() {
  }

  ngOnChanges() {
  }

  ngOnInit() {
  }

}

