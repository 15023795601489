import { EventEmitter} from '@angular/core';
export function createEl(tag, classname) {
  var res = document.createElement(tag);
  res.className = classname;
  return res;
}
export interface IModalWindowSettings {
  visibleChange? : EventEmitter<boolean>;
  onShow?: () => void;
  onClose?: () => void;
  clickBgClose?: () => boolean;
  removeScroll?: boolean;
}

export class searchBoxSettings {
  public keyExp?: string;
  public displayExp?: string;
  public valueAll?: boolean;
  public blurtimeout?: number;
  public placeholder?: string;
  public listcount?: number;
  public search?: any;
  public value?: any;
  public onFocus?: () => void;
  public onBlur?: () => void;
  public lowExp?: string;
  public elExp?: string;
};


export class PageMenuItem {
  name: string;
  click = () => { };
  btnclass?: string;
  class?: string;
  Enable?= () => { return true; };
  Visible?= () => { return true; };
  public static NewItem(name: string) {
    let res = new PageMenuItem();
    res.name = name;
    return res;
  }
}

