import { Component, OnInit, OnDestroy, Injectable, ViewChild } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MenuSettings, ComponentState } from '../../../classes';
import { MenuService } from '../../../services';

@Component({ templateUrl: 'GuacamoleBoard.component.html'})


 @Injectable()
export class GuacamoleBoardComponent implements OnInit  {
 
  static Path = "VirtualMachines"
  static Title = "Virtual Machines";
  public token: boolean = false;
  public url: string = "/guacamole/#/auth/";
  public urlSafe: SafeResourceUrl;

  constructor(private httpController: HttpService, public sanitizer: DomSanitizer, private MenuService: MenuService) { }

  ngOnInit() {
    let that = this;
    let s = new MenuSettings();
    s.rightVisible = false;
    this.MenuService.SetSetings(s);
    this.MenuService.toggleSecondaryMenu(false, true);
    HttpService.GetToken().then(Token => {
      var encode = window.btoa(HttpService.baseadr);
      that.urlSafe = that.sanitizer.bypassSecurityTrustResourceUrl(that.url + Token + '/' + encode);
      that.token = true;
    });

  }  
}

