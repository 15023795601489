//inner
import { Component,  OnInit,  ChangeDetectorRef,  EventEmitter, Input, Output } from '@angular/core';
import { IModalWindowSettings } from '..';
import { BehaviorSubject, Subscription } from 'rxjs';
import { VisibleComponent } from '../visiblecomponent';
export interface IDialog {
  GetTitle?: () => string;
  title?: string;
  subtitle?: string;
  contentclass?: string;
  isloading?: boolean;
  hideButtons?: boolean;
  validateOnFinish?: boolean;
  Validate?: () => boolean;
  OnOpen?: () => void;
  OnClose?: () => void;
  Finish?: () => void;
  Cancel?: () => void;
}

export class Dialog {
  GetTitle = () => { return this.title; };
  title: string = '';
  subtitle: string = null;
  contentclass='';
  isloading: boolean = false;
  hideButtons: boolean = false;
  Validate = () => { return true; };
  OnOpen = () => {
    this.touched = !this.validateOnFinish;
    this.Finished = false;
  };
  OnClose = () => {  };
  Finish = () => { };
  Cancel = () => { };
  Finished = false;
  init = false;
  validateOnFinish = true;
  touched = !this.validateOnFinish;
  constructor(d?: IDialog) {
    if (d) {
      let that = this;
      Object.keys(d).forEach(function (key) {
        that[key] = d[key];
      });
      if (d.OnOpen != null) {
        that.OnOpen = () => {
          this.touched = !this.validateOnFinish;
          this.Finished = false;
          d.OnOpen();
        };
      }
    }
  }
  canFinish() {
    if (!this.touched) return true;
    return this.Validate();
  }
}

@Component({
  selector: 'simple-dialog',
  templateUrl: './Dialog.component.html'
})

export class DialogComponent extends VisibleComponent implements OnInit{


@Output()
public isloadingChange = new EventEmitter<boolean>();
@Input()
set isloading(val: boolean) {
  if (this.dialog.isloading != val) {
    this.dialog.isloading = val;
    this.isloadingChange.emit(val);
  }
}
get isloading(): boolean {
  return this.dialog.isloading;
}

  constructor() {
    super();
    this.visibleChange.subscribe(v => {
      if (v) {
        this.dialog.init = true;
        this.dialog.OnOpen();
      }
    });
  }
  ngOnInit(): void {
       
  }
  
  _data: Dialog = new Dialog({});
  @Input()
  get dialog(): Dialog {
    return this._data;
  }
  set dialog(val: Dialog) {
    this._data = val;
  }

  public Open() {
    this.visible = true;
  }

  public Ok() {
    this.dialog.touched = true;
    if (this.dialog.Validate() && !this.dialog.Finished) {
      this.dialog.Finished = true;
      this.dialog.Finish();
      this.visible = false;
    }
  }

  public Cancel() {
    this.visible = false;
    this.dialog.Cancel();
  }


}
